import { Label, Error } from 'components/FormControls';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import StyledEdit from '../../../components/FormControls/FileInput/FileCropperInput/styles';
import Styled from './styles';
import WysisygEditor from './ui';

interface Props {
  key?: string;
  label?: string;
  helper?: string;
  input?: any;
  meta?: any;
  maxValue?: number;
  inModal?: boolean;
  placeholder?: string;
  withPreview?: boolean;
  data?: any;
  useData?: boolean;
  id?: string;
}

export const WysiwigEditorPreview: React.FC<Props> = (props) => {
  const { key, label, helper, input, meta, maxValue, inModal, placeholder, withPreview, data, useData, id } =
    props;
  const [state, setState] = useState({
    isEdit: true,
    initialValue: null,
    showWysiwigEditor: false,
  });

  const toggleEditMode = () => {
    setState((prevState) => ({ ...prevState, isEdit: !state.isEdit, showWysiwigEditor: true }));
  };

  useEffect(() => {
    if (!useData) {
      if (!isEmpty(input?.value)) {
        setState((prevState) => ({
          ...prevState,
          initialValue: input?.value,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          initialValue: input.value,
        }));
      }
    }
    if (useData) {
      if (!isEmpty(data)) {
        setState((prevState) => ({
          ...prevState,
          isEdit: true,
          initialValue: input.value,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isEdit: false,
          initialValue: input.value,
        }));
      }
    }
  }, [input.value]);

  if (
    withPreview &&
    state.isEdit &&
    !state.showWysiwigEditor &&
    meta?.error !== 'Поле обязательно для заполнения'
  ) {
    return (
      <Styled.EditText>
        <Styled.Label>{label && <Label>{label}</Label>}</Styled.Label>
        {state?.initialValue ? (
          <div dangerouslySetInnerHTML={{ __html: state?.initialValue }} />
        ) : (
          <Styled.EditorText>Введите текст</Styled.EditorText>
        )}

        <Styled.EditWrapper>
          <StyledEdit.Edit type="button" onClick={toggleEditMode}>
            Редактировать
          </StyledEdit.Edit>
        </Styled.EditWrapper>
      </Styled.EditText>
    );
  }

  return (
    <WysisygEditor
      key={key}
      id={id}
      label={label}
      helper={helper}
      input={input}
      meta={meta}
      maxValue={maxValue}
      inModal={inModal}
    />
  );
};
