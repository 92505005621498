import { useUnit } from 'effector-react';
import React from 'react';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { $hallId } from '../../model';
import { Hall } from './hall';
import Styled from './styles';

interface HallsProps {
  list: any[];
}

export const Halls: React.FC<HallsProps> = (props) => {
  const { list } = props;
  const { hallId } = useUnit({ hallId: $hallId });

  return (
    <ul>
      {list.map((hall) => {
        const placeInfo = hall.info.find((info: any) => info.language_code === LANGUAGES.RU);

        return (
          <Hall key={hall.id} id={hall.id} isActive={hallId === hall.id}>
            {placeInfo?.title}
            <Styled.Arrow isActive={hallId === hall.id} />
          </Hall>
        );
      })}
    </ul>
  );
};
