import copyToClipboard from 'copy-to-clipboard';
import { head } from 'lodash';
import get from 'lodash/get';
import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import onClickOutside from 'react-onclickoutside';
import { parsersEventsSources } from 'entities/parsers/events';
import { eventsSources } from 'shared/api/events';
import ICONS from 'shared/constants/ICONS';
import { formatDotsDate } from 'shared/helpers/formatters/date';
import { getAdaptedFilterEvent } from 'shared/helpers/formatters/event';
import api from 'shared/services/api';
import LinkIcon from '../../../static/icons/chain.svg';
import Button from '../Button';
import Icon from '../Icon';
import Spinner from '../Spinner';
import TextOverflow from '../TextOverflow';
import Tooltip from '../Tooltip';
import Styled from './styles';

const copyEventId = (_, id) => {
  copyToClipboard(id);
  NotificationManager.success(`ID - ${id}`, 'Успешно');
};

interface InfoProps {
  eventId?: string | number;
  externalId: number;
}

interface InfoState {
  isOpen: boolean;
  data?: any;
}

class ParsersEventInfo extends Component<InfoProps, InfoState> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      data: null,
    };
  }

  handleClickOutside = () => {
    this.setState({
      isOpen: false,
    });
  };

  showTooltip = () => {
    const { externalId, eventId } = this.props;

    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
      });
    } else {
      this.setState(
        {
          isOpen: true,
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        () => {
          if (eventId) {
            return api.get(parsersEventsSources.eventParsers(eventId)).then((action) => {
              this.setState({ data: action.data });
            });
          }

          return api.get(eventsSources.childDetail(externalId)).then((response) => {
            const event = getAdaptedFilterEvent(head(response.data.results)!);
            const date = event.children.find((child) => child.id === Number(externalId));
            this.setState({ data: { ...event, ...date } });
          });
        },
      );
    }
  };

  render() {
    const { data } = this.state;
    const { eventId } = this.props;

    const externalIdData = data && (
      <>
        <Styled.Name>{get(data, 'title')}</Styled.Name>
        <Styled.Info>
          {get(data, 'place.name') && (
            <Styled.Row>
              <Styled.Title>Место проведения</Styled.Title>
              <Styled.Text>{data.place.name}</Styled.Text>
            </Styled.Row>
          )}
          {get(data, 'date_start') && (
            <Styled.Row>
              <Styled.Title>Дата начала</Styled.Title>
              <Styled.Text>{formatDotsDate(data.date_start)}</Styled.Text>
            </Styled.Row>
          )}
          {get(data, 'time_start') && (
            <Styled.Row>
              <Styled.Title>Время начала</Styled.Title>
              <Styled.Text>{data.time_start}</Styled.Text>
            </Styled.Row>
          )}
          {get(data, 'id') && (
            <Styled.Row>
              <Styled.Title>ID</Styled.Title>
              <Styled.Text>
                {data.id}
                <Tooltip
                  text={`Скопировать ID-${data.id} в буфер обмена`}
                  getTooltipContainer={(trigger: any) => trigger.parentNode}
                >
                  <Button
                    type="button"
                    onlyIcon
                    transparent
                    opacity={0.4}
                    onClick={copyEventId}
                    data={data.id}
                  >
                    <LinkIcon />
                  </Button>
                </Tooltip>
              </Styled.Text>
            </Styled.Row>
          )}
        </Styled.Info>
      </>
    );

    const eventIdData =
      data && data.length > 0
        ? data.map((item) => (
            <Tooltip text={item}>
              <TextOverflow>
                <a href={item} target="_blank" rel="noopener noreferrer">
                  {item}
                </a>
              </TextOverflow>
            </Tooltip>
          ))
        : 'Нет подключенных парсеров';

    const renderData = eventId ? eventIdData : externalIdData;

    return (
      <Styled.Container onClick={this.showTooltip}>
        {this.state.isOpen && (
          <Styled.Tooltip>
            {data ? (
              renderData
            ) : (
              <Styled.Spinner>
                <Spinner size={36} />
              </Styled.Spinner>
            )}
          </Styled.Tooltip>
        )}
        <Tooltip text={`Информация об ${eventId ? 'парсерах' : 'мероприятии'}`}>
          <Button onlyIcon transparent type="button">
            <Icon src={ICONS.INFO} opacity={0.4} hoverOpacity={0.8} alt="Информация об мероприятии" />
          </Button>
        </Tooltip>
      </Styled.Container>
    );
  }
}

export default onClickOutside(ParsersEventInfo);
