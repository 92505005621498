import config from 'config/config';
import { createEffect } from 'effector';
import api from '../../services/api';
import { TranslatedInfo } from '../types';

const route = `${config.COMMON_API_URL}/admin/v1/countries`;

export default {
  root: route,
  detail: (id) => `${route}/${id}`,
};

export const fetchCountriesFx = createEffect(async (query = {}) => {
  const response = await api.get(route, {
    params: {
      ...query,
      search_string: query.search,
      limit: 500,
    },
  });

  return response.data;
});

export const fetchCountryFx = createEffect(async (id: string) => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const createCountryFx = createEffect(async ({ title }: { title: string }) => {
  const response = await api.post(route, { code: title });

  return response.data;
});

export const updateCountryFx = createEffect(async ({ id, title }: { id: string; title: string }) => {
  const response = await api.patch(`${route}/${id}`, { code: title });

  return response.data;
});

export const createCountryInfoFx = createEffect(
  async ({ id, translation }: { id: string; translation: TranslatedInfo }) => {
    const response = await api.post(`${route}/${id}/info`, translation);

    return response.data;
  },
);

export const createCountryInfosFx = createEffect(
  async ({ id, translations }: { id: string; translations: TranslatedInfo[] }) => {
    const requests = translations.map(async (translation) => {
      const response = await api.post(`${route}/${id}/info`, translation);

      return response;
    });

    await Promise.all(requests);
  },
);

export const updateCountryInfoFx = createEffect(
  async ({ id, translation }: { id: string; translation: TranslatedInfo }) => {
    const response = await api.patch(`${route}/${id}/info/${translation.language_code}`, translation);

    return response;
  },
);

export const deleteCountryFx = createEffect(async ({ id }: { id: string }) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});
