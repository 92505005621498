import { createEvent, createStore, sample } from 'effector';
import { Router } from 'next/router';
import { fetchHallLayoutsFx, HallLayout } from 'shared/api/reference/hall-layouts';
import { fetchHallsFx, Hall } from 'shared/api/reference/halls';

export const stepOpened = createEvent<Router['query']>();
export const hallSelected = createEvent<number>();
export const hallLayoutLoaded = createEvent<{ id: number; name: string }>();
export const hallLayoutSelected = createEvent<{ id: number; name: string }>();
export const hallLayoutIsSelectedToUpdate = createEvent();

export const $halls = createStore<Hall[]>([]);
export const $hallId = createStore<number | null>(null);
export const $hallLayoutId = createStore<number | null>(null);
export const $selectedHallLayoutId = createStore<number | null>(null);
export const $hallLayouts = createStore<HallLayout[]>([]);
export const $hallLayoutsLoading = createStore(true);
export const $hallLayoutsFailed = createStore(false);
export const $hallLayoutName = createStore<string>('');
export const $isUpdated = createStore(false);

sample({
  clock: stepOpened,
  target: fetchHallsFx,
});

sample({
  clock: fetchHallsFx.done,
  fn: ({ result }) => {
    return result.results;
  },
  target: $halls,
});

sample({
  clock: hallSelected,
  fn: (hallId) => {
    return {
      hall: hallId,
      limit: 2500,
    };
  },
  target: fetchHallLayoutsFx,
});

sample({
  clock: hallSelected,
  fn() {
    return true;
  },
  target: $hallLayoutsLoading,
});

sample({
  clock: hallSelected,
  target: $hallId,
});

sample({
  clock: fetchHallLayoutsFx.done,
  fn({ result }) {
    return result.data.results;
  },
  target: $hallLayouts,
});

sample({
  clock: fetchHallLayoutsFx.done,
  fn() {
    return false;
  },
  target: $hallLayoutsLoading,
});

sample({
  clock: fetchHallLayoutsFx.fail,
  fn() {
    return true;
  },
  target: $hallLayoutsFailed,
});

sample({
  clock: hallLayoutSelected,
  fn(params) {
    return params.id;
  },
  target: $selectedHallLayoutId,
});

sample({
  clock: hallLayoutLoaded,
  fn(params) {
    return params.id;
  },
  target: [$hallLayoutId, $selectedHallLayoutId],
});

sample({
  clock: [hallLayoutSelected, hallLayoutLoaded],
  fn(params) {
    return params.name;
  },
  target: $hallLayoutName,
});

sample({
  clock: hallLayoutIsSelectedToUpdate,
  fn() {
    return true;
  },
  target: $isUpdated,
});

sample({
  clock: hallLayoutIsSelectedToUpdate,
  source: {
    selectedHallLayoutId: $selectedHallLayoutId,
  },
  fn({ selectedHallLayoutId }) {
    return selectedHallLayoutId;
  },
  target: $hallLayoutId,
});
