import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Container: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  Info: styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    & > div {
      margin-bottom: 8px;
    }
  `,

  Tooltip: styled.div`
    z-index: 1071;
    position: absolute;
    top: 100%;
    width: 400px;
    background-color: #fff;
    right: 0;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: ${BREAKPOINTS.MD}) {
      max-width: 400px;
      left: 0;
      right: auto;
      width: 80vw;
    }
  `,

  Row: styled.div`
    display: flex;
  `,

  Name: styled.div`
    margin-bottom: 18px;
    font-size: 20px;
    color: #000;
  `,

  Title: styled.div`
    color: #5c5c5c;
    display: flex;
    align-items: center;
    width: 120px;
    max-width: 120px;
    min-width: 120px;
    font-size: 14px;
  `,

  Text: styled.div`
    display: flex;
    align-items: center;
    margin-left: 16px;
    color: #000;
    font-size: 16px;

    & > button {
      margin-left: 8px;
    }

    & > a {
      word-break: break-all;
    }
  `,

  Spinner: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
