import styled from 'styled-components';

export default {
  Title: styled.div`
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  `,

  Desc: styled.div`
    margin: 8px 0 32px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.64);
  `,

  AddMore: styled.div`
    margin-bottom: 32px;

    button {
      color: #2f80ed;
    }
  `,

  Row: styled.div`
    position: relative;
    max-width: 384px;
    margin-bottom: 32px;
  `,

  Trash: styled.div`
    position: absolute;
    top: 0;
    right: -50px;
  `,

  SaveButtons: styled.div`
    display: flex;
    align-items: center;

    button {
      margin: 0 4px;
    }
  `,
};
