import { Router as RouterTypes, withRouter } from 'next/router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteEvent, loadEvents } from 'entities/events';
import { openModal } from 'entities/modal';
import { EVENTS } from 'shared/constants/EVENTS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Emmiter from 'shared/services/Emmiter';
import { ModalFunctions } from '../../../../../interfaces/modal';
import ChevronLeftIcon from '../../../../../static/icons/chevron-left.svg';
import Button from '../../../Button';
import ConfirmModal from '../../../Modals/ConfirmModal';
import StepModal from '../../../Modals/StepModal';
import EventsFormFirstStep from '../EventsFormFirstStep';
import EventsFormSecondStep from '../EventsFormSecondStep';
import { EventsFormThirdStep } from '../EventsFormThirdStep';
import EventsFormSevenStep from '../EventsFormSevenStep';
import Styled from './styles';
import ProgressBar from '../../../ProgressBar';
import { LangSwitcher } from '../../../FormControls';
import EventsFormEightStep from '../EventsFormEightStep';
import EventsFormNineStep from '../EventsFormNineStep';
import EventsFormFourStep from '../EventsFormFourStep';

const STEPS = {
  ZERO: 0,
  ONE: 1,
  THREE: 2,
  FOUR: 3,
  FIVE: 4,
  SIX: 5,
  SEVEN: 6,
};

const STEP_COMPONENT = {
  [STEPS.ZERO]: EventsFormFirstStep,
  [STEPS.ONE]: EventsFormSecondStep,
  [STEPS.THREE]: EventsFormThirdStep,
  [STEPS.FOUR]: EventsFormFourStep,
  [STEPS.FIVE]: EventsFormNineStep,
  [STEPS.SIX]: EventsFormSevenStep,
  [STEPS.SEVEN]: EventsFormEightStep,
};

const STEPS_WORDS = [
  'Мероприятие',
  'Дата и время',
  'Схема зала',
  'Места',
  'Наценка',
  'Персоналии',
  'Завершение',
];

interface FormProps {
  data: any;
  router: RouterTypes;
  deleteEvent: (id: number) => any;
  openModal: ModalFunctions['openModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  closeModal: ModalFunctions['closeModal'];
  isMobile: boolean;
  isDesktop: boolean;
  loadEvents: (query: any) => any;
  t: (value: string, params?: any) => string;
}

interface FormState {
  step: number;
  isEdit: boolean;
  callback: any;
  eventIdForDelete: any;
  disabledSteps: number[];
  t: (value: string, params?: any) => string;
  childrenId?: any;
}

class EventsForm extends Component<FormProps, FormState> {
  setModalShouldClose: any;

  eventId: number;

  constructor(props: any) {
    super(props);

    this.state = {
      step: 0,
      isEdit: false,
      callback: null,
      eventIdForDelete: null,
      disabledSteps: [],
      t: props.t,
    };

    this.eventId = this.props.data.modalData.eventId || this.props.router.query.event;
  }

  componentDidMount(): void {
    Emmiter.addListener(EVENTS.EVENT_FORM_UPDATE, this.updateProgressBar);
  }

  componentWillUnmount(): void {
    Emmiter.removeListener(EVENTS.EVENT_FORM_UPDATE, this.updateProgressBar);
  }

  updateProgressBar = (value: any) => {
    this.setState(value);
  };

  handleDeleteEvent = () => {
    return this.props.deleteEvent(this.state.eventIdForDelete).then((action: any) => {
      if (!action.error) {
        this.state.callback();
        this.hideDeleteEventModal();
        this.props.loadEvents(this.props.router.query);
      }

      return action;
    });
  };

  showDeleteEventModal = (callback: any, eventIdForDelete = this.eventId) => {
    this.setState({
      eventIdForDelete,
      callback,
    });
  };

  hideDeleteEventModal = () => {
    this.setState({
      eventIdForDelete: null,
      callback: null,
    });
  };

  goBack = () => {
    this.setState(({ step }) => ({
      step: step - 1,
    }));
  };

  goNext = () => {
    this.setState(({ step }) => ({
      step: step + 1,
    }));
  };

  selectStep = (selectedStep: any) => {
    this.setState(() => ({
      step: selectedStep,
    }));
  };

  render() {
    const { isMobile, isDesktop } = this.props;

    const { step, isEdit, eventIdForDelete, childrenId, t, disabledSteps } = this.state;
    const StepComponent = STEP_COMPONENT[step];

    const backButton = (
      <Styled.LeftButton>
        <Button
          transparent
          onlyIcon={isMobile}
          onClick={this.goBack}
          type="button"
          data-selenium="form-event-button-back"
        >
          {isMobile ? <ChevronLeftIcon /> : t('forms:back')}
        </Button>
      </Styled.LeftButton>
    );

    const StepComponentProps = {
      key: `${step}_key`,
      goNext: this.goNext,
      goBack: this.goBack,
      childrenId,
      withNext: step === STEPS.FIVE,
      forceCloseModal: this.props.forceCloseModal,
      setModalShouldClose: this.setModalShouldClose,
      showDeleteEventModal: this.showDeleteEventModal,
      closeModal: this.props.closeModal,
      isMobile,
      isDesktop,
      backButton,
      secondaryFooter: true,
      withSubmit: true,
      modalData: { ...this.props.data.modalData, forceCloseModal: this.props.forceCloseModal },
      isEdit: { isEdit },
    };

    return (
      <>
        {eventIdForDelete && (
          <ConfirmModal
            withPopover
            data={{
              title: t('events:form.delete_title'),
              text: t('events:form.delete_text'),
              onSubmit: this.handleDeleteEvent,
              onReject: this.hideDeleteEventModal,
            }}
            closeModal={this.hideDeleteEventModal}
            padding="24px"
          />
        )}
        <StepModal.Header tablet>
          <StepModal.WithLanguage column>
            <StepModal.Title tablet>
              {isEdit ? t('events:form.edit_title') : t('events:form.create_title')}
            </StepModal.Title>
            {[STEPS.ZERO, STEPS.SEVEN].includes(step) && isDesktop && <LangSwitcher desktopColumn />}
          </StepModal.WithLanguage>

          <Styled.ProgressBar>
            <ProgressBar
              horizontalOn={MEDIA_QUERIES.MOBILE_AND_TABLET}
              mobileHorizontal
              steps={STEPS_WORDS}
              currentStep={step}
              createMode={!isEdit}
              disableStepSelect={disabledSteps}
              onSelect={this.selectStep}
            />
          </Styled.ProgressBar>
        </StepModal.Header>
        <Styled.GlobalStyles />
        <StepComponent {...StepComponentProps} />
      </>
    );
  }
}

const mapDispatchToProps = {
  deleteEvent,
  openModal,
  loadEvents,
};

export default withRouter(withCheckIsMobile(connect(null, mapDispatchToProps)(EventsForm)));
