import slugify from 'slugify';
import { translate } from '../api';

export const generateSlug = async ({ values }: { values: { [key: string]: string | number } }) => {
  const slugSource = values.slug_source as string;

  let cleanedSlugSource = slugSource.replace(/[^a-zA-Zа-яА-ЯёЁ0-9\s_-]/g, '');

  if (values.slug_type === 2) {
    const response = await translate(cleanedSlugSource);
    cleanedSlugSource = response;
  }

  const slug = slugify(cleanedSlugSource).toLowerCase();

  return slug.replace(/'/gi, '');
};
