import React from 'react';
import EventsForm from '../EventsForm';
import LOCALES from 'shared/constants/LOCALES';
import { withTranslation } from '../../../../i18n';
import Modal from '../../../Modal';

const EventsModal = (props) => (
  <Modal.FullSizeContainer>
    <EventsForm {...props} />
  </Modal.FullSizeContainer>
);

export default withTranslation([LOCALES.FORMS, LOCALES.EVENTS])(EventsModal);
