import Spinner from 'components/Spinner';
import { useUnit } from 'effector-react';
import flow from 'lodash/flow';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { closeModal, openModal } from 'entities/modal';
import { FormSubmitData } from 'shared/api/types';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import MODAL from 'shared/constants/MODALS';
import { findTranslation } from 'shared/helpers/translations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import { Modal, ModalClose } from 'shared/ui/modal';
import { CountryForm } from '../../form';
import * as updateModalModel from '../model';
import Styled from './styled';

interface ModalProps {
  modal: {
    id: string;
    name: string;
  };
  isMobile: boolean;
  t: (value: string, params?: any) => string;
}

const CountryModal: React.FC<ModalProps> = (props) => {
  const [lang, setLang] = useState(LANGUAGES.RU);
  const dispatch = useDispatch();
  const { isMobile, modal } = props;
  const { modalOpened, modalClosed, deleteSubmitted, formSubmitted } = useUnit({
    modalOpened: updateModalModel.modalOpened,
    modalClosed: updateModalModel.modalClosed,
    deleteSubmitted: updateModalModel.deleteSubmitted,
    formSubmitted: updateModalModel.formSubmitted,
  });
  const { data, loading, modalId, isOpen } = useUnit({
    data: updateModalModel.$country,
    loading: updateModalModel.$countryLoading,
    modalId: updateModalModel.$modalId,
    isOpen: updateModalModel.toggler.$isOpen,
  });

  useEffect(() => {
    if (isOpen && modalId) {
      modalOpened(modalId);
    }
    return () => {
      dispatch(closeModal());
      modalClosed();
      setLang(LANGUAGES.RU);
    };
  }, [isOpen]);

  const editCountry = (submitData: FormSubmitData) => formSubmitted(submitData);

  const openDeleteForm = () => {
    dispatch(
      openModal(MODAL.CONFIRM_MODAL, {
        onSubmit: () => {
          deleteSubmitted({ id: modalId, title: data!.code });
        },
        title: 'Удалить страну?',
        text: 'Данная страна удалится. Вы уверены, что хотите совершить это действие?',
        modalData: { size: 400 },
      }),
    );
  };

  const formData = data?.info ? findTranslation(data.info, lang) : null;

  return (
    <Modal toggler={updateModalModel.toggler} name="countries" id={modalId}>
      {({ closeModal }) => {
        return (
          <Styled.Body className="modal__body">
            <ModalClose onClick={closeModal} />

            {loading ? (
              <Spinner center />
            ) : (
              <CountryForm
                isEdit
                title="references:country.edit_title"
                onSubmit={editCountry}
                initialValues={formData}
                isMobile={isMobile}
                onChangeLng={setLang}
                closeModal={closeModal}
                openDeleteForm={openDeleteForm}
              />
            )}
          </Styled.Body>
        );
      }}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modal: state.modal.data,
});

const withHoC = flow([withCheckIsMobile, connect(mapStateToProps)]);

export const CountryUpdateModal = withHoC(CountryModal);
