import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import moment from 'moment';
import {
  composeValidators,
  required,
  startTimeBeforeEndTime,
  uniqueStartTime,
} from 'shared/helpers/validations';
import Form from '../../../../Form';
import { DateTimePicker, FormRow, Select } from '../../../../FormControls';
import Styled from './styled';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import Button from '../../../../Button';
import Modal from '../../../../Modal';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';
import preventClick from 'shared/helpers/preventClick';
import ExceptDatepicker from '../ExceptDatepicker/ExceptDatepicker';
import TimeRow from './TimeRow';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';

const REPEAT_OPTIONS = [
  { value: 1, name: 'Каждый день' },
  { value: 2, name: 'Каждый день, кроме' },
];

interface ModalProps {
  isMobile?: boolean;
}

const EventsFormPeriodicalSettings: React.FC<ModalProps> = ({ isMobile }) => {
  const [isHidden, toggleVisibility] = useState(true);

  useEffect(() => {
    const updateVisibility = () => {
      toggleVisibility((value) => !value);
    };
    Emmiter.on(EVENTS.TOGGLE_CREATE_PERIODICAL_DATES_MODAL, updateVisibility);

    return () => {
      Emmiter.off(EVENTS.TOGGLE_CREATE_PERIODICAL_DATES_MODAL, updateVisibility);
    };
  }, []);

  const createDates = (values) => {
    Emmiter.emit(EVENTS.ADD_PERIODICAL_DATES, values);
  };

  const hideModal = () => {
    toggleVisibility(true);
  };

  if (isHidden) {
    return null;
  }

  const startDateBeforeEndDate = (_, values) => {
    const startDate = values.date_start;
    const endDate = values.date_end;
    const isStartAfterEnd = startDate && endDate && moment(startDate).isAfter(moment(endDate));
    return isStartAfterEnd ? 'Дата начала не может быть позже даты окончания' : undefined;
  };

  return (
    <Styled.Overlay onClick={hideModal}>
      <Styled.Modal onClick={preventClick}>
        <Modal.Close className="modal__close" onClick={hideModal} />
        <Modal.Title isSmall className="modal__title">
          Настройте периодические даты
        </Modal.Title>
        <Form
          initialValues={{
            except_dates: [],
            times: [{ time_start: undefined, time_end: undefined }],
          }}
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={createDates}
          render={({
            handleSubmit,
            form,
            form: {
              mutators: { push },
            },
            values,
          }) => {
            const addTime = () => {
              push('times', { time_start: undefined, time_end: undefined });
            };
            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <Field name="date_start" validate={composeValidators(required, startDateBeforeEndDate)}>
                    {({ input, meta }) => (
                      <DateTimePicker
                        label="Дата начала"
                        name="date_start"
                        input={input}
                        meta={meta}
                        handleForm={form}
                        minuteStep={5}
                        mobileClose
                        withClearButton
                        withoutTimePick
                        format={DATE_FORMATS.DATE_PREVIEW}
                      />
                    )}
                  </Field>
                </FormRow>
                <FieldArray
                  name="times"
                  validate={composeValidators(startTimeBeforeEndTime, uniqueStartTime)}
                >
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <TimeRow fields={fields} name={name} index={index} key={name} />
                      ))}
                      <Styled.AddTimeButton onClick={addTime} type="button" transparentBlue>
                        Добавить время
                      </Styled.AddTimeButton>
                    </>
                  )}
                </FieldArray>
                <FormRow>
                  <Field name="date_end" validate={required}>
                    {({ input, meta }) => (
                      <DateTimePicker
                        label="Дата конца"
                        name="date_end"
                        input={input}
                        meta={meta}
                        handleForm={form}
                        minuteStep={5}
                        withClearButton
                        withoutTimePick
                        mobileClose
                        format={DATE_FORMATS.DATE_PREVIEW}
                      />
                    )}
                  </Field>
                </FormRow>
                <FormRow>
                  <Field name="repeat">
                    {({ input, meta }) => <Select options={REPEAT_OPTIONS} {...input} meta={meta} />}
                  </Field>
                </FormRow>
                {Number(values.repeat) === 2 && (
                  <FormRow>
                    <Field name="except_dates" validate={required}>
                      {({ input, meta }) => (
                        <ExceptDatepicker
                          label="Исключить"
                          values={values}
                          name="except_dates"
                          input={input}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </FormRow>
                )}
                <Button type="submit">Добавить</Button>
                {isMobile && (
                  <Button type="button" dangerTransparent onClick={hideModal}>
                    Отмена
                  </Button>
                )}
              </form>
            );
          }}
        />
      </Styled.Modal>
    </Styled.Overlay>
  );
};

export default withCheckIsMobile(EventsFormPeriodicalSettings);
