import React from 'react';
import Select from '../FormControls/Select';
import { queryFilter } from 'shared/lib/queryFilter';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';

const handleChange = ({ target }: any) => {
  const { value } = target;
  queryFilter({ limit: value, page: null });
};

interface LimitSelectProps {
  limits?: number[];
  currentLimit?: string;
  isMobile?: boolean;
}

const LimitSelect: React.FC<LimitSelectProps> = ({ limits, currentLimit, isMobile }) => (
  <Select
    options={limits.map((item, index) => ({
      id: index,
      name: isMobile ? `По ${item}` : `Показывать по ${item}`,
      value: item,
    }))}
    onChange={handleChange}
    value={currentLimit}
    small
    data-selenium="limit-select"
  />
);

export default withCheckIsMobile(LimitSelect);
