import styled, { createGlobalStyle } from 'styled-components';
import { getBorderOnError } from 'shared/helpers/input';
import COLORS from 'shared/constants/COLORS';
import Button from '../../../../Button';

interface ContainerProps {
  highlighted?: boolean;
  meta: any;
}

export default {
  GlobalStyles: createGlobalStyle`
  .except-picker {
    .ant-calendar-input-wrap {
      display: none;
    }
    .ant-calendar-date {
      border-radius: 0;
    }
    .except-calendar-date-selected {
      background-color: #e6f7ff;
    }

    .except-calendar-date-partial-disabled {
      background-color: ${COLORS.YELLOW};
    }

    .except-calendar-date-disabled {
      background-color: #e1e1e1;
    }

    .except-calendar-time-disabled {
      text-decoration: line-through;
    }
  }

  `,

  Container: styled.div<ContainerProps>`
    position: relative;
    width: 100%;

    .ant-input {
      border-color: ${(props) => (props.highlighted ? COLORS.YELLOW : null)};
      ${getBorderOnError};
      width: 100%;
    }
  `,

  Outdated: styled.button`
    width: 100%;
    background: none;
    border: 0;
    color: #1890ff;
    text-align: center;
    cursor: pointer;
    outline: none;
  `,

  Overlay: styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,

  MobileClose: styled.button`
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    opacity: 0.4;
    transition: 0.3s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
    }
  `,

  TimeButton: styled(Button)`
    white-space: nowrap;
  `,

  TimeOverlay: styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
};
