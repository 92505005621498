import Form from 'components/Form';
import { PhoneInput,InputWithSelect } from 'components/FormControls';
import Modal from 'components/Modal';
import RolesDropdown from 'components/RolesDropdown';
import get from 'lodash/get';
import plural from 'plural-ru';
import React from 'react';
import { Field } from 'react-final-form';
import { ModalLayout } from 'widgets/layouts/modal-layout';
import { companySources } from 'entities/company';
import { renderLegalInfo, renderPhonesCity } from 'shared/helpers/optionsRenderers';
import { validPhone, required, composeValidators } from 'shared/helpers/validations';
import Styled from './styled';

interface ModalForm {
  title: string;
  onSubmit?: (data: object) => void;
  onSuccess?: (data: object) => void;
  isEdit: boolean;
  initialValues?: any;
  children: React.ReactNode
}

export const PhoneModalForm: React.FC<ModalForm> = 
({ children, onSubmit, onSuccess, title, isEdit, ...rest }) => {
  const sitesCount = get(rest, 'initialValues.sites.length', 0);
  return (
    <Form
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      {...rest}
      render={({ handleSubmit, form }: {handleSubmit: ()=>void; form: object}) => (
        <form onSubmit={handleSubmit}>
          <Styled.GlobalStyles />
          <Modal.Title>{title}</Modal.Title>
          <div>
            <ModalLayout.Row>
              <ModalLayout.Sidebar>
                <ModalLayout.RowTitle>Метод</ModalLayout.RowTitle>
                <ModalLayout.Description>Основная информация</ModalLayout.Description>
              </ModalLayout.Sidebar>
              <ModalLayout.Content>
                <ModalLayout.FormRow>
                  <ModalLayout.Field>
                    <Field name="phone" validate={composeValidators(validPhone, required)}>
                      {({ input, meta }) => (
                        <PhoneInput label="Телефон" meta={meta} input={input} handleForm={form} />
                      )}
                    </Field>
                  </ModalLayout.Field>
                </ModalLayout.FormRow>
                <ModalLayout.FormRow>
                  <ModalLayout.Field>
                    <Field name="city_name">
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          isCreatable
                          label="Город"
                          route={companySources}
                          routeName="landingPhonesCity"
                          optionsRenderer={renderPhonesCity}
                          meta={meta}
                          {...input}
                          disabled={isEdit}
                        />
                      )}
                    </Field>
                  </ModalLayout.Field>
                </ModalLayout.FormRow>
                <ModalLayout.FormRow>
                  <ModalLayout.Field>
                    <Field name="legal_info_id" validate={required}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="Компания"
                          route={companySources}
                          routeName="legalInfo"
                          optionsRenderer={renderLegalInfo}
                          {...input}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </ModalLayout.Field>
                </ModalLayout.FormRow>
              </ModalLayout.Content>
            </ModalLayout.Row>
            {isEdit && (
              <ModalLayout.Row>
                <ModalLayout.Sidebar>
                  <ModalLayout.RowTitle>Сайты</ModalLayout.RowTitle>
                  <ModalLayout.Description>Список привязанных сайтов</ModalLayout.Description>
                </ModalLayout.Sidebar>
                <ModalLayout.Content>
                  <ModalLayout.FormRow>
                    <ModalLayout.Sites>
                      <RolesDropdown
                        label="Сайты"
                        roles={rest.initialValues.sites}
                        counter={`${sitesCount} ${plural(sitesCount, 'сайт', 'сайта', 'сайтов')}`}
                      />
                    </ModalLayout.Sites>
                  </ModalLayout.FormRow>
                </ModalLayout.Content>
              </ModalLayout.Row>
            )}
          </div>
          <Modal.Footer>{children}</Modal.Footer>
        </form>
      )}
    />
  );
};

