import React from 'react';
import StatePreview from '../StatePreview';
import Styled from './styles';

interface DataPreviewProps {
  state: {
    isLoading?: boolean;
    isFail?: boolean;
    loading?: boolean;
  };
  length?: number;
  centered?: boolean;
  isEmpty?: boolean;
  children: any;
}

const DataPreview: React.FC<DataPreviewProps> = ({ state, length, children, centered, isEmpty }) => {
  if (state.isLoading || state.isFail || state.loading) return <StatePreview state={state} />;

  if (length === 0 || isEmpty) {
    return (
      <Styled.Message centered={centered} className="data-preview__message">
        Ничего не найдено
      </Styled.Message>
    );
  }

  return children;
};

export default DataPreview;
