import styled, { css } from 'styled-components';
import { scrollbar } from '../../app/styles/scrollbar';
import Button from '../Button';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface HeaderProps {
  withShadow?: boolean;
}

interface ListItemProps {
  flex?: boolean;
}

export default {
  Container: styled.div``,

  Popup: styled.div`
    position: fixed;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 8px 8px 0 0;
    padding-bottom: 8px;
    box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    overflow: hidden;
    z-index: 200;
  `,

  Header: styled.div<HeaderProps>`
    position: relative;
    padding: 20px 16px 12px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #585858;
    transition: 0.2s ease-in-out;

    ${({ withShadow }) =>
      withShadow &&
      css`
        border-bottom: 1px solid #e1e1e1;
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
      `}
  `,

  Close: styled.button`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    opacity: 0.4;
    transition: 0.2s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      justify-content: flex-end !important;
      padding-right: 8px;
    }
  `,

  Inner: styled.div`
    padding: 0 16px;
    overflow-y: auto;
    ${scrollbar}
  `,

  ListItem: styled.div<ListItemProps>`
    display: ${({ flex }) => flex && 'flex'};
    align-items: ${({ flex }) => flex && 'center'};
    padding: 10px 0;
    font-size: 16px;
    line-height: 20px;
  `,

  Backdrop: styled.div`
    position: fixed;
    z-index: 199;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.24);
  `,

  Toggler: styled(Button)`
    height: 32px;
    padding: 6px 8px;
  `,

  Icon: styled.div`
    display: flex;
    margin-left: 10px;
    opacity: 0.4;
  `,
};
