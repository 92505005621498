import { createEvent, createStore, sample } from 'effector';
import sortBy from 'lodash/sortBy';
import { Router } from 'next/router';
import * as countriesApi from 'shared/api/reference/country';
import { Countries } from 'shared/api/types';
import { groupAlphabetically } from 'shared/helpers/array';

export const pageMounted = createEvent<any>();

export const $countriesQuery = createStore<Router['query']>({});
export const $loading = createStore(true);
export const $countries = createStore<Countries>({
  results: [],
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});

sample({
  clock: pageMounted,
  target: $countriesQuery,
});

sample({
  clock: countriesApi.fetchCountriesFx.done,
  fn: () => false,
  target: $loading,
});

sample({
  source: { countriesQuery: $countriesQuery },
  fn: ({ countriesQuery }) => countriesQuery,
  target: countriesApi.fetchCountriesFx,
});

sample({
  clock: countriesApi.fetchCountriesFx.done,
  fn: ({ result }) => {
    const { results, ...meta } = result;
    return {
      ...meta,
      results: groupAlphabetically(sortBy(results, 'code')),
    };
  },
  target: $countries,
});
