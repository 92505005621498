import config from 'config/config';
import { createEffect } from 'effector';
import { Router } from 'next/router';
import { i18n } from 'next-i18next';
import api from '../../../services/api';
import { CreatePlaceData, Place } from './types';

export const route = `${config.COMMON_API_URL}/admin/v1/places`;

export const referencePlaceSources = {
  root: route,
  placeType: `${config.COMMON_API_URL}/admin/v1/place_types`,
  detail: (id: number) => `${route}/${id}`,
};

export const fetchPlacesFx = createEffect(async (query: Router['query'] = {}) => {
  const { location, ...rest } = query;
  const response = await api.get(route, {
    params: {
      ...rest,
      ...(query && { language_code: i18n?.language }),
      ...(location && { location_id_in: location }),
      language_code: i18n?.language,
    },
  });

  return response.data;
});

export const fetchPlaceFx = createEffect(async ({ id }: { id: number; type: string }): Promise<Place> => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const createPlaceFx = createEffect(async ({ title, type_id, location_id }: CreatePlaceData) => {
  const response = await api.post(route, {
    title,
    type_id,
    location_id,
  });

  return response.data;
});

export const updatePlaceFx = createEffect(async ({ title, type_id, location_id, id }: CreatePlaceData) => {
  const response = await api.patch(`${route}/${id}`, {
    title,
    type_id,
    location_id,
  });

  return response.data;
});

export const deletePlaceFx = createEffect(async ({ id }: { id: number; title: string }) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});
