export default [
  { id: 5, value: 5, name: '0+' },
  { id: 7, value: 7, name: '3+' },
  { id: 1, value: 1, name: '6+' },
  { id: 2, value: 2, name: '12+' },
  { id: 8, value: 8, name: '14+' },
  { id: 6, value: 6, name: '16+' },
  { id: 3, value: 3, name: '18+' },
  { id: 4, value: 4, name: '21+' },
];
