import React from 'react';
import { Field } from 'react-final-form';
import {
  Input,
  InputWithSelect,
  FormDescription,
  SmallFormDescription,
  FormControl,
  Checkbox,
} from 'components/FormControls';
import { required, composeValidators, maxValue } from 'shared/helpers/validations';
import { LangForm } from 'components/Form';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Styled from './styles';
import landingsTagsSources from 'shared/sources/landings/landingsTags';
import MODALS from 'shared/constants/MODALS';
import { companySources } from 'entities/company';
import {
  renderContentGroups,
  renderLandingsTemplates,
  renderLegalInfo,
  renderMembers,
} from 'shared/helpers/optionsRenderers';
import LinkIcon from '../../../../../static/icons/external-link.svg';
import { landingUrl } from 'shared/helpers/url';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { ModalFunctions } from '../../../../../interfaces/modal';
import landingsTemplateSets from 'shared/sources/landings/landingsTemplateSets';

interface FormProps {
  title: string;
  children: any;
  modalData?: any;
  isEdit?: boolean;
  forceCloseModal?: ModalFunctions['forceCloseModal'];
  openModal?: ModalFunctions['openModal'];
  openRouterModal: ModalFunctions['openRouterModal'];
  onChangeLng: (lng: string) => any;
  onSubmit: (data: any) => any;
  onSuccess: (data: any) => any;
  initialValues?: any;
}

const SiteForm: React.FC<FormProps> = ({
  children,
  title,
  openRouterModal,
  modalData,
  isEdit,
  onChangeLng,
  ...rest
}) => {
  const openConstructorModal = () => {
    openRouterModal({ modalType: MODALS.CONSTRUCTOR_MODAL, data: modalData });
  };

  return (
    <LangForm
      onChangeLng={onChangeLng}
      stretch
      {...rest}
      render={({ handleSubmit, i18n, switcher }) => (
        <form onSubmit={handleSubmit}>
          <Styled.GlobalStyles isEdit={isEdit} />
          <Styled.TitleWrapper isEdit={isEdit}>
            <Modal.Title isSmall>{title}</Modal.Title>
          </Styled.TitleWrapper>
          {isEdit && modalData.tech_name && (
            <Styled.Link href={landingUrl(modalData.tech_name)} target="_blank" rel="noopener noreferrer">
              {landingUrl(modalData.tech_name)}
              <LinkIcon />
            </Styled.Link>
          )}
          <Styled.Sections>
            <Styled.Section>{switcher}</Styled.Section>
            <Styled.Section>
              <FormDescription width={128}>Название сайта</FormDescription>
              <Styled.Rows>
                <Field type="hidden" name="manual" component="input" value="false" />
                <Styled.Row>
                  <Field
                    name={`${i18n.language}.site_name`}
                    validate={composeValidators(required, maxValue(255))}
                  >
                    {({ input, meta }) => <Input autoFocus {...input} meta={meta} />}
                  </Field>
                </Styled.Row>
              </Styled.Rows>
            </Styled.Section>
            {i18n.language === LANGUAGES.RU && (
              <>
                <Styled.Section>
                  <FormDescription width={128}>Домен</FormDescription>
                  <Styled.Row>
                    <Field name={`${i18n.language}.domain`}>
                      {({ input, meta }) => <Input {...input} meta={meta} />}
                    </Field>
                  </Styled.Row>
                </Styled.Section>
                <Styled.Section>
                  <FormDescription width={128}>
                    Теги
                    <SmallFormDescription>Добавление тегов</SmallFormDescription>
                  </FormDescription>
                  <Styled.Row>
                    <Field name={`${i18n.language}.content_groups`} validate={required}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          isMulti
                          isCreatable
                          label="Теги"
                          searchQueryName="search_string"
                          optionsRenderer={renderContentGroups}
                          route={landingsTagsSources}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                    <Styled.Description>
                      Контент из раздела «Лендинги» с одинаковыми тегами будет использоваться на сайте
                    </Styled.Description>
                  </Styled.Row>
                </Styled.Section>

                {isEdit && (
                  <Styled.Section>
                    <FormDescription width={128}>Реселлер футер</FormDescription>
                    <FormControl>
                      <Field name={`${i18n.language}.ui_config_show_reseller_info_in_footer`} type="checkbox">
                        {({ input, meta }) => <Checkbox variant="round" meta={meta} {...input} />}
                      </Field>
                    </FormControl>
                  </Styled.Section>
                )}
              </>
            )}

            {isEdit && (
              <Styled.Section>
                <FormDescription width={128}>
                  Конструктор сайта
                  <SmallFormDescription>Создание сайта</SmallFormDescription>
                </FormDescription>
                <FormControl>
                  <Button type="button" onClick={openConstructorModal}>
                    Перейти в конструктор
                  </Button>
                </FormControl>
              </Styled.Section>
            )}

            {i18n.language === LANGUAGES.RU && (
              <Styled.Section>
                <FormDescription width={128}>
                  Доп инфо
                  <SmallFormDescription>Выбор юр инфы и менеджера</SmallFormDescription>
                </FormDescription>
                <Styled.Rows>
                  <Styled.Row>
                    <Field name={`${i18n.language}.legal_information`}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="Юридическая информация"
                          route={companySources}
                          routeName="legalInfo"
                          optionsRenderer={renderLegalInfo}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </Styled.Row>
                  <Styled.Row>
                    <Field name={`${i18n.language}.template_set`}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="Набор шаблонов"
                          route={landingsTemplateSets}
                          optionsRenderer={renderLandingsTemplates}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </Styled.Row>
                  <Styled.Row>
                    <Field name={`${i18n.language}.manager`}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="Менеджер"
                          route={companySources}
                          routeName="managersAssign"
                          optionsRenderer={renderMembers}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </Styled.Row>
                </Styled.Rows>
              </Styled.Section>
            )}
          </Styled.Sections>
          <Modal.Footer contentWidth="880px">{children}</Modal.Footer>
        </form>
      )}
    />
  );
};

export default SiteForm;
