import React, { Component } from 'react';
import isArray from 'lodash/isArray';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/async';
import Creatable from 'react-select/creatable';
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import debounce from 'debounce-promise';
import Styled from './styles';
import Error from '../Error';
import Label from '../LabelNew';
import { loadOptions } from 'shared/helpers/input';
import InputWithSelectOption from './InputWithSelectOption';
import InputWithSelectDropdownIndicator from './InputWithSelectDropdownIndicator';
import InputWithSelectLoadingMessage from './InputWithSelectLoadingMessage';
import InputWithSelectMultiValueRemove from './InputWithSelectMultiValueRemove';
import InputWithSelectClearIndicator from './InputWithSelectClearIndicator';
import ValueContainerInputWithSelect from './ValueContainerInputWithSelect';
import CustomInputWithSelect from './CustomInputWithSelect';
import api from 'shared/services/api';
import { InputWithSelectProps, InputWithSelectState } from './InputWithSelect.types';

const getAddressByYandex = async (inputValue) => {
  const result = await window.ymaps.suggest(inputValue);

  const options = result.map((item) => ({
    value: item.value,
    label: item.value,
  }));

  return options;
};

const getFormatCreateLabel = (inputValue) => `Добавить ${inputValue}`;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const getSelectComponent = ({ isAsync, isCreatable, withoutPagination }) => {
  let ReactSelectComponent;

  if (!isAsync) {
    ReactSelectComponent = ReactSelect;
  }

  if (isAsync) {
    if (withoutPagination) {
      ReactSelectComponent = ReactSelectAsync;
    } else {
      ReactSelectComponent = AsyncPaginate;
    }
  }

  if (isCreatable) {
    ReactSelectComponent = Creatable;
  }

  if (isAsync && isCreatable) {
    ReactSelectComponent = CreatableAsyncPaginate;
  }

  return ReactSelectComponent;
};

class InputWithSelect extends Component<InputWithSelectProps, InputWithSelectState> {
  debouncedLoadOptions: any;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    label: '',
    query: {},
    isAsync: false,
    isMulti: false,
    isSmall: false,
    withoutError: false,
    withCounter: false,
    fixedError: false,
    components: {},
    disabled: false,
    routeName: 'root',
    searchQueryName: 'search',
    defaultValue: '',
    isClearable: true,
    isCreatable: false,
    maxMenuHeight: 312,
    defaultOptions: true,
    menuPlacement: 'auto',
    withoutPagination: false,
    clearValueIfEqualToDefault: false,
    language: 'ru',
    name: '',
    placeholder: '',
  };

  constructor(props) {
    super(props);

    this.debouncedLoadOptions = debounce(this.getDataByRoute, 1000);
    this.state = {
      options: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { value, defaultValue, onChange, onSelect, clearValueIfEqualToDefault } = this.props;
    const isValueChanged = !isEqual(prevProps.value, value);
    const isValueEqualToDefaultValue = isEqual(value, defaultValue);

    if (clearValueIfEqualToDefault && isValueChanged && isValueEqualToDefaultValue) {
      onChange(null);
      if (onSelect) {
        onSelect(null);
      }
    }
  }

  getDataByRoute = async (value, _, meta) => {
    const { route, routeName, optionsRenderer, withoutPagination, query, language, searchQueryName } =
      this.props;

    const data = await loadOptions({
      query,
      value,
      route,
      routeName,
      optionsRenderer,
      withoutPagination,
      searchQueryName,
      currentPage: meta && meta.page,
      language,
    });

    if (data.options) {
      this.setState({ options: data.options });
    }

    return data;
  };

  getValue = () => {
    const { value, defaultValue, route, routeName } = this.props;
    let currentValue = value || defaultValue;
    currentValue = !isEmpty(currentValue) ? currentValue : null;

    if (this.state.options.length && isNumber(value)) {
      const selectedOption = this.state.options.find((item) => item.value === value);

      if (!selectedOption) {
        const detailRoute = `${route[routeName]}/${value}`;
        api.get(detailRoute).then((res) => {
          const response = res.data;
          this.setState((prevState) => ({
            options: [...prevState.options, { label: response.name || response.title, value: response.id }],
          }));
        });

        return null;
      }

      return {
        label: selectedOption.label,
        value: selectedOption.value,
      };
    }

    return currentValue;
  };

  onChange = (value, params) => {
    const { onChange, onChangeMapper, onSelect } = this.props;

    /* eslint-disable */
    const filteredValue = Array.isArray(value)
      ? value.map((i) => (i.__isNew__ ? { label: i.label } : i))
      : value;
    /* eslint-enable */

    if (onChangeMapper) {
      onChange(...onChangeMapper(filteredValue, params));
    } else {
      onChange(filteredValue, params);
      if (onSelect) {
        onSelect(filteredValue, params);
      }
    }
  };

  render() {
    const {
      meta,
      label,
      route,
      name,
      value,
      isMulti,
      isAsync,
      isSmall,
      disabled,
      components,
      fixedError,
      isClearable,
      isCreatable,
      withCounter,
      placeholder,
      defaultValue,
      withoutError,
      maxMenuHeight,
      errorPosition,
      defaultOptions,
      menuPortal,
      withoutPagination,
      ...rest
    } = this.props;
    const SelectComponent = getSelectComponent({ isAsync, isCreatable, withoutPagination });
    const currentValue = this.getValue();
    const menuPlacement = this.props.menuPlacement ? this.props.menuPlacement : 'auto';
    const asyncProps = {
      loadOptions: route ? this.debouncedLoadOptions : getAddressByYandex,
      defaultOptions,
      ...(!withoutPagination && {
        additional: {
          page: 1,
        },
      }),
      ...(isCreatable && {
        SelectComponent: Creatable,
        cacheUniqs: [currentValue],
      }),
    };

    return (
      <Styled.Container>
        <Styled.FieldContainer isSmall={isSmall} meta={meta} isMulti={isMulti}>
          {label && (
            <Label value={currentValue} meta={meta} disabled={disabled}>
              {label}
            </Label>
          )}
          <SelectComponent
            components={{
              Option: InputWithSelectOption,
              LoadingMessage: InputWithSelectLoadingMessage,
              MultiValueRemove: InputWithSelectMultiValueRemove,
              DropdownIndicator: InputWithSelectDropdownIndicator,
              ClearIndicator: InputWithSelectClearIndicator,
              ValueContainer: ValueContainerInputWithSelect,
              Input: CustomInputWithSelect,
              ...components,
            }}
            noOptionsMessage={() => 'Пусто  ¯\\_(ツ)_/¯'}
            isClearable={isClearable}
            value={currentValue}
            maxMenuHeight={maxMenuHeight}
            isMulti={isMulti}
            tabSelectsValue={false}
            isDisabled={disabled}
            classNamePrefix="react-select"
            menuPlacement={menuPlacement}
            backspaceRemovesValue={false}
            blurInputOnSelect={false}
            placeholder={
              withCounter && !placeholder.length
                ? isArray(currentValue) && currentValue.length > 0 && `Выбрано ${currentValue.length}`
                : placeholder
            }
            {...(isAsync && asyncProps)}
            {...rest}
            onChange={this.onChange}
            formatCreateLabel={getFormatCreateLabel}
            name={name}
          />
        </Styled.FieldContainer>
        {!withoutError && <Error meta={meta} fixedError={fixedError} position={errorPosition} />}
      </Styled.Container>
    );
  }
}

export default InputWithSelect;
