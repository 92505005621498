import { createEvent, sample } from 'effector';
import { $countriesQuery } from 'pages/reference/countries/model';
import * as countriesApi from 'shared/api/reference/country';
import { FormSubmitData } from 'shared/api/types';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { showedSuccesNotification } from 'shared/helpers/notification';
import { createToggler } from 'shared/lib/toggler';

const createFormSubmitted = createEvent<FormSubmitData>();
const toggler = createToggler();

sample({
  clock: createFormSubmitted,
  fn: (data) => {
    return {
      title: data[LANGUAGES.RU].title,
      data,
    };
  },
  target: countriesApi.createCountryFx,
});

sample({
  clock: countriesApi.createCountryFx.done,
  fn: ({ params, result }) => {
    const translations = Object.keys(params.data).reduce((acc: any, lang: string) => {
      if (LANGUAGES[lang.toUpperCase()] && params.data[lang]) {
        acc.push({
          language_code: lang,
          title: params.data[lang].title,
        });

        return acc;
      }
      return acc;
    }, []);

    return { id: result.id, translations };
  },
  target: countriesApi.createCountryInfosFx,
});

sample({
  clock: countriesApi.createCountryInfoFx.done,
  target: showedSuccesNotification,
});

export const countryCreateModal = { createFormSubmitted, toggler };

sample({
  source: { countriesQuery: $countriesQuery },
  clock: countriesApi.createCountryInfoFx.done,
  fn: ({ countriesQuery }) => countriesQuery,
  target: countriesApi.fetchCountriesFx,
});
