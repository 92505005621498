import Button from 'components/Button';
import ConfirmModal from 'components/Modals/ConfirmModal';
import Spinner from 'components/Spinner';
import omit from 'lodash/omit';
import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import {
  loadCompanyPhones,
  loadCompanyPhone,
  editCompanyPhone,
  removeCompanyPhone,
  createCompanyPhone,
} from 'entities/company/phones';
import { ModalFunctions } from '../../../../../../../../interfaces/modal';
import {PhoneModalForm} from './form';


const initialState = {
  data: {},
  isActive: true,
  isLoading: true,
};

interface ModalProps {
  data?: { id:number } | undefined;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  loadCompanyPhones: () => void;
  createCompanyPhone: (data: object) => void;
  loadCompanyPhone: (id: number) => void;
  editCompanyPhone: (id: number, data: object) => void;
  removeCompanyPhone: (id: number) => void;
}

interface IState {
  data: any;
  isActive: boolean;
  isLoading: boolean;
}

const PhoneModal: React.FC<ModalProps> = ({
  data,
  loadCompanyPhones,
  createCompanyPhone,
  loadCompanyPhone,
  editCompanyPhone,
  removeCompanyPhone,
  closeModal,
  forceCloseModal,
}) => {
  const [state, setState] = useState<IState>(initialState);
  const [deletePhone, setDeletePhone] = useState(false);
  const isEdit = data.id !== undefined;

  useEffect(() => {
    if (isEdit) {
      const { id } = data;

      loadCompanyPhone(id).then((response) => {
        const { data: newData } = response.payload;
        setState({
          isLoading: false,
          data: newData,
          isActive: newData.is_active,
        });
      });
    } else {
      setState({ ...initialState, isLoading: false });
    }
  }, []);

  const createPhone = (values : 
    {city_name:{value: string},
    legal_info_id: {value: number},
    sites: []}) =>
    createCompanyPhone({
      ...omit(values, ['phone_dial_code']),
      city_name: values.city_name?.value,
      legal_info_id: values.legal_info_id?.value,
      sites: values.sites || [],
    });

  const editPhone = (values) =>
    editCompanyPhone(state.data.id, {
      ...values,
      city_name: values.city_name?.value,
      legal_info_id: values.legal_info_id?.value,
      sites: values.sites || [],
    }).then(() => {
      /* loadCompanyPhone(state.data.id); */
      NotificationManager.success('Успешно');
      forceCloseModal();
    });

  const handleDeletePhone = () =>
    removeCompanyPhone(state.data.id).then(() => {
      NotificationManager.success('Успешно');
      forceCloseModal();
    });

  const { data: phoneData, isLoading } = state;

  return isLoading ? (
    <Spinner center />
  ) : (
    <>
      <PhoneModalForm
        title={`${isEdit ? 'Редактирование' : 'Добавление'} телефона`}
        onSubmit={isEdit ? editPhone : createPhone}
        onSuccess={() => loadCompanyPhones().then(() => forceCloseModal())}
        initialValues={{
          ...phoneData,
          city_name: phoneData.city_name && { label: phoneData.city_name, value: phoneData.city_name },
          legal_info_id: phoneData.legal_info && {
            label: phoneData.legal_info.company_name,
            value: phoneData.legal_info.id,
          },
          sites: phoneData.sites?.map((site) => ({ name: site.site_name, id: site.id })),
        }}
        isEdit={isEdit}
      >
        {isEdit && (
          <Button type="button" onClick={() => setDeletePhone(true)} dangerTransparent>
            Удалить
          </Button>
        )}
        <Button type="button" transparent onClick={closeModal}>
          Отмена
        </Button>
        <Button type="submit">Сохранить</Button>
      </PhoneModalForm>
      {deletePhone && (
        <ConfirmModal
          withPopover
          data={{
            title: 'Удалить телефон?',
            text: 'Данный телефон удалится. Вы уверены, что хотите совершить это действие?',
            onSubmit: handleDeletePhone,
          }}
          closeModal={() => setDeletePhone(false)}
          padding="24px"
        />
      )}
    </>
  );
};







const mapDispatchToProps = {
  loadCompanyPhones,
  createCompanyPhone,
  loadCompanyPhone,
  editCompanyPhone,
  removeCompanyPhone,
};

export const CompanyPhoneModal = connect(null, mapDispatchToProps)(PhoneModal);
