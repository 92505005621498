import OptionWithPlaceInfo from 'components/FormControls/InputWithSelect/OptionWithPlaceInfo';
import arrayMutators from 'final-form-arrays';
import {
  get,
  find,
  memoize,
  isEmpty,
  concat,
  map,
  omit,
  compact,
  findIndex,
  isEqual,
  pick,
  differenceWith,
} from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { List } from 'react-virtualized';
import { deleteEvent, getPermission } from 'entities/events';
import { referencePlaceSources } from 'shared/api/reference/places';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import { EVENTS } from 'shared/constants/EVENTS';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { generateDatesArray } from 'shared/helpers/date';
import { sortChildrenEvents } from 'shared/helpers/events';
import { renderReferencePlaces } from 'shared/helpers/optionsRenderers';
import {
  required,
  eventPlaceValidation as placeValidation,
  uniqueStartDates,
  composeValidators,
} from 'shared/helpers/validations';
import useEventData from 'shared/lib/useEventData';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Emmiter from 'shared/services/Emmiter';
import { ModalFunctions } from '../../../../../interfaces/modal';
import Box from '../../../Box';
import Button from '../../../Button';
import Form from '../../../Form';
import { InputWithSelect, Checkbox } from '../../../FormControls';
import Modal from '../../../Modal';
import StepModal from '../../../Modals/StepModal';
import Spinner from '../../../Spinner';
import StyledEventsForm from '../EventsForm/styles';
import EventsLayout from '../EventsLayout';
import EventDateRow from './EventDateRow';
import EventsFormPeriodicalSettings from './EventsFormPeriodicalSettings';
import Styled from './styles';

const convertDatetime = (datetime) => moment(datetime, DATE_FORMATS.DATE_TIME);

const hasPastDates = (values) => {
  if (!values) return false;

  return !!find(values, (item) => {
    if (!item.date_finish) return false;
    const time = convertDatetime(`${item.date_finish} ${item.time_finish}`);

    return !!time && time.isBefore(moment(), 'minute');
  });
};

let newFields = [];
let addChildren = null;
interface SecondStepProps {
  childrenId?: number;
  goNext: () => any;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  showDeleteEventModal: (callback: () => any, id?: number) => void;
  // eslint-disable-next-line no-undef
  backButton: JSX.Element;
  modalData: any;
  isMobile?: boolean;
}

const checkIsDisabled = (val) =>
  ((val.date_time_finish || val.date_finish) &&
    moment(convertDatetime(val.date_time_finish || `${val.date_finish} ${val.time_finish}`)).isBefore(
      moment().subtract(1, 'minutes'),
    )) ||
  false;

const EventsFormSecondStep: React.FC<SecondStepProps> = (props) => {
  const listRef = useRef();
  const [displayPastEvents, setDisplayPastEvents] = useState(false);
  const { modalData, showDeleteEventModal, childrenId, backButton } = props;
  const { isEdit, state, update, loadData } = useEventData({
    modalData,
  });
  const { isLoading, data, updateAllowed, isSubmitting } = state;

  useEffect(() => {
    const createPeriodicalDates = (newValues) => {
      const { date_start: dateStart, date_end: dateEnd, times, except_dates: exceptDates } = newValues;

      const dates = generateDatesArray(dateStart, dateEnd, times).filter(({ startDate }) => {
        const isExcluded = exceptDates.filter((date) => moment(startDate).isSame(date, 'hour')).length > 0;
        return !isExcluded;
      });

      dates.forEach(({ startDate, endDate }: any) => {
        const newValue = {
          is_premiere: false,
          is_full_house: false,
          is_star_cast: false,
          top: false,
          is_rejected: false,
          is_rescheduled: false,
          has_open_date: false,
          date_time_start: startDate,
          date_time_finish: endDate,
        };

        addChildren('children', newValue);
      });

      Emmiter.emit(EVENTS.TOGGLE_CREATE_PERIODICAL_DATES_MODAL);
    };

    Emmiter.on(EVENTS.ADD_PERIODICAL_DATES, createPeriodicalDates);

    return () => {
      newFields = [];
      addChildren = null;
      Emmiter.off(EVENTS.ADD_PERIODICAL_DATES, createPeriodicalDates);
    };
  }, []);

  if (isLoading) {
    return (
      <Styled.SpinnerContainer>
        <Spinner />
      </Styled.SpinnerContainer>
    );
  }

  const childrenData =
    data.children && !displayPastEvents
      ? compact(
          data.children.map((val) => {
            if (!displayPastEvents && checkIsDisabled(val)) {
              return null;
            }

            return val;
          }),
        )
      : data.children;

  const eventPlaceValidation = memoize(placeValidation, (values: any) => {
    return values.place ? `${values.place.id}_${values.place.value}` : '_';
  });

  const uniqueStartDatesValidation = memoize(uniqueStartDates, (values) =>
    values
      .map((item) => {
        if (!item) return '_';
        return item.date_time_start && item.date_time_start.date_time_start
          ? item.date_time_start.date_time_start
          : item.date_time_start;
      })
      .join('_'),
  );

  const updatePeriodicalCategories = async (parentData) => {
    await Promise.all(
      parentData.children.map((child) => {
        return update(
          {
            page4: {
              widget: {
                event: child.id,
                event_id: child.id,
              },
            },
          },
          child.id,
        );
      }),
    );

    return parentData;
  };

  const updateCategories = async (events, parentEvent) => {
    const updatedData = parentEvent;

    await Promise.all(
      events.map((schedule) => {
        const index = findIndex(parentEvent.children, (item: any) => {
          const properties = [
            'time_start',
            'time_finish',
            'is_top',
            'is_hidden',
            'date_start',
            'date_finish',
            'is_premiere',
            'is_full_house',
            'is_star_cast',
            'top',
            'is_rejected',
            'is_rescheduled',
            'has_open_date',
          ];
          return schedule.id
            ? Number(item.id) === Number(schedule.id)
            : isEqual(pick(schedule, properties), pick(item, properties));
        });

        let eventId;
        if (index >= 0) {
          eventId = updatedData.children[index].id;
          updatedData.children[index].widget_is_category = schedule.is_category;
        } else {
          eventId = updatedData.id;
        }

        return update(
          {
            page4: {
              widget: {
                event: eventId,
                event_id: eventId,
              },
            },
          },
          eventId,
        );
      }),
    );

    return updatedData;
  };

  const handleFormSubmit = async (formData: any) => {
    newFields = [];

    const {
      id,
      children,
      place,
      is_top: isTop,
      is_hidden: isHidden,
      is_premiere: isPremiere,
      is_full_house: isFullHouse,
      is_star_cast: isStarCast,
      top,
      is_rejected: isRejected,
      is_rescheduled: isRescheduled,
      has_open_date: hasOpenDate,
    } = formData;

    const hasChildren = formData.children && formData.children.length;

    const page2 = {
      place: place?.data?.place?.id || place.id,
      is_top: isTop,
      is_hidden: isHidden,
      is_premiere: Boolean(isPremiere),
      is_full_house: Boolean(isFullHouse),
      is_star_cast: Boolean(isStarCast),
      is_rejected: Boolean(isRejected),
      is_rescheduled: Boolean(isRescheduled),
      has_open_date: Boolean(hasOpenDate),
      top: hasChildren ? formData.children.some((child) => Boolean(child.top)) : Boolean(top),
      schedules: map(children, (item) => ({
        id: item.id,
        date_id: item.id,
        is_top: Boolean(item.is_top),
        is_hidden: Boolean(item.is_hidden),
        is_premiere: Boolean(item.is_premiere),
        is_full_house: Boolean(item.is_full_house),
        is_star_cast: Boolean(item.is_star_cast),
        top: Boolean(item.top),
        is_rejected: Boolean(item.is_rejected),
        is_rescheduled: Boolean(item.is_rescheduled),
        has_open_date: Boolean(item.has_open_date),
        date_start: item.date_time_start?.format(DATE_FORMATS.DATE),
        time_start: item.date_time_start?.format(DATE_FORMATS.TIME),
        date_finish: item.date_time_finish?.format(DATE_FORMATS.DATE),
        time_finish: item.date_time_finish?.format(DATE_FORMATS.TIME),
      })),
    };
    const childrens = childrenData
      ? childrenData.map((i) => omit(i, ['is_global', 'widget', 'parent_id']))
      : [];
    const eventsForUpdate = differenceWith(
      sortChildrenEvents(page2.schedules),
      sortChildrenEvents(childrens),
      isEqual,
    );

    await update({ page2 }).then(async (response) => {
      if (response.error) {
        return response;
      }

      const newData = omit(response.payload.data, ['cover', 'preview']);
      const isPreviouslySingle = !childrenData;

      if (isPreviouslySingle && newData.children) {
        await updatePeriodicalCategories(newData);
      } else {
        await updateCategories(eventsForUpdate, newData);
      }

      return props.goNext();
    });
  };

  const childrenMapped = concat(
    sortChildrenEvents(childrenData).map((item: any) => ({
      id: item.id,
      is_premiere: item.is_premiere,
      is_full_house: item.is_full_house,
      is_star_cast: item.is_star_cast,
      is_rejected: item.is_rejected,
      is_rescheduled: item.is_rescheduled,
      has_open_date: item.has_open_date,
      top: item.top,
      date_time_start: !item.date_time_start
        ? convertDatetime(`${item.date_start} ${item.time_start}`)
        : item.date_time_start,
      date_time_finish: !item.date_time_finish
        ? convertDatetime(`${item.date_finish} ${item.time_finish}`)
        : item.date_time_finish,
    })),
    newFields,
  );

  let children = childrenMapped;

  if (!data.children) {
    children = [
      {
        date_time_start:
          data.date_start && data.time_start
            ? convertDatetime(`${data.date_start} ${data.time_start}`)
            : undefined,
        date_time_finish:
          data.date_finish && data.time_finish
            ? convertDatetime(`${data.date_finish} ${data.time_finish}`)
            : undefined,
        initial: true,
        is_premiere: data.is_premiere,
        is_full_house: data.is_full_house,
        is_star_cast: data.is_star_cast,
        top: data.top,
        is_rejected: data.is_rejected,
        is_rescheduled: data.is_rescheduled,
        has_open_date: data.has_open_date,
        ...(data.date_start && !props.modalData.parserData && { id: data.id }),
      },
    ];
  }

  const initialValues = {
    ...data,
    add_hours: true,
    ...(data.place && {
      place: {
        ...data.place,
        label: data.place.label || data.place.name,
      },
    }),
    children,
  };

  let formValues = initialValues;

  const canDelete = (dateTimeStart, eventId) => {
    const isRelevant = !moment(dateTimeStart).isBefore(moment().subtract(1, 'minutes'));

    if (!isRelevant) return false;

    if (formValues.children.length === 1) return false;

    if (data.id === eventId) return false;

    return true;
  };

  const openCreateDatesModal = () => {
    Emmiter.emit(EVENTS.TOGGLE_CREATE_PERIODICAL_DATES_MODAL);
  };

  const datesValidation = (dates) => {
    const errors = dates.map((date) => {
      let err = {};

      if (!date.date_time_start) {
        err = { ...err, date_time_start: 'Необходимо выбрать дату и время' };
      }

      if (!date.date_time_finish) {
        err = { ...err, date_time_finish: 'Необходимо выбрать дату и время' };
      }

      return isEmpty(err) ? undefined : err;
    });

    return errors;
  };

  return (
    <EventsLayout.Wrapper>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validate={eventPlaceValidation}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          form,
          form: {
            change: formChange,
            mutators: { push },
          },
          values,
        }) => {
          addChildren = push;

          const handleSubmitForm = (formData: any) => {
            const hasDublicates = compact(uniqueStartDatesValidation(formValues.children)).length;

            if (hasDublicates) {
              NotificationManager.error('Имеются дубли дат', 'Ошибка');
            }

            return handleSubmit(formData);
          };
          return (
            <form>
              <Modal.Container>
                <StyledEventsForm.RowWithLabel>
                  <StyledEventsForm.RowLabel>
                    Площадка
                    <StyledEventsForm.RowDesc>Основная информация</StyledEventsForm.RowDesc>
                  </StyledEventsForm.RowLabel>
                  <StyledEventsForm.RowField data-selenium="form-event-place">
                    <Field
                      name="place"
                      validate={required}
                      subscription={{
                        value: true,
                        touched: true,
                        error: true,
                        submitError: true,
                        active: true,
                      }}
                    >
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="Площадка"
                          route={referencePlaceSources}
                          query={{ language_code: LANGUAGES.RU }}
                          optionsRenderer={renderReferencePlaces}
                          searchQueryName="search_string"
                          components={{ Option: OptionWithPlaceInfo }}
                          disabled={!updateAllowed}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </StyledEventsForm.RowField>
                </StyledEventsForm.RowWithLabel>

                <StyledEventsForm.RowWithLabel>
                  <StyledEventsForm.RowLabel>
                    Дата и время
                    <StyledEventsForm.RowDesc>Дата начала и конца мероприятия</StyledEventsForm.RowDesc>
                  </StyledEventsForm.RowLabel>

                  <div>
                    <StyledEventsForm.Row
                      spaceBottom
                      flexContainer
                      align="center"
                      margin="0"
                      mobileMargin="0"
                    >
                      <StyledEventsForm.Column data-selenium="form-event-add-hours">
                        <Field name="add_hours" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox mobileNormal variant="round" meta={meta} {...input}>
                              Автоматически выставлять дату конца
                            </Checkbox>
                          )}
                        </Field>
                      </StyledEventsForm.Column>
                    </StyledEventsForm.Row>
                    <StyledEventsForm.Row flexContainer spaceBottom>
                      <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
                        <Styled.CheckboxWrapper>
                          <Field name="is_top" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox
                                rtl
                                variant="round"
                                meta={meta}
                                {...input}
                                onChange={(event) => {
                                  const { checked } = event.target;

                                  formChange('is_top', checked);
                                }}
                              >
                                На главную:
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.CheckboxWrapper>
                      </StyledEventsForm.Column>
                      <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
                        <Styled.CheckboxWrapper>
                          <Field name="is_hidden" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox
                                rtl
                                variant="round"
                                meta={meta}
                                {...input}
                                onChange={(event) => {
                                  const { checked } = event.target;

                                  formChange('is_hidden', checked);
                                }}
                              >
                                Скрыто:
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.CheckboxWrapper>
                      </StyledEventsForm.Column>
                    </StyledEventsForm.Row>
                    <Styled.RowField>
                      <FieldArray
                        name="children"
                        validate={composeValidators(datesValidation, uniqueStartDatesValidation)}
                      >
                        {({ fields }) => {
                          const getListHeight = () => {
                            const defaultHeight = 700;
                            const rowHeight = props.isMobile ? 660 : 200;
                            const count = fields.value.length;

                            if (count * rowHeight < defaultHeight) {
                              return count * rowHeight;
                            }

                            return defaultHeight;
                          };
                          return (
                            <List
                              ref={listRef}
                              overscanRowCount={3}
                              width={props.isMobile ? window.innerWidth - 32 : 640}
                              height={getListHeight()}
                              rowRenderer={(rowProps) => {
                                const params = {
                                  ...rowProps,
                                  canDelete,
                                  showDeleteEventModal,
                                  childrenId,
                                  isEdit,
                                  formValues: values,
                                  initialValues,
                                  form,
                                  fields,
                                  formChange,
                                  loadData,
                                };

                                return <EventDateRow {...params} />;
                              }}
                              rowCount={fields.length}
                              rowHeight={props.isMobile ? 550 : 170}
                            />
                          );
                        }}
                      </FieldArray>
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => {
                          formValues = values;
                          newFields = values.children.filter((child) => !get(child, 'id'));

                          return null;
                        }}
                      </FormSpy>
                      {isEmpty(data.parent) && (
                        <StepModal.Row>
                          <Button
                            type="button"
                            plain
                            onClick={() => {
                              push('children', {
                                is_top: false,
                                is_hidden: false,
                                is_premiere: false,
                                is_full_house: false,
                                is_star_cast: false,
                                top: false,
                                is_rejected: false,
                                is_rescheduled: false,
                                has_open_date: false,
                              });

                              if (listRef && listRef.current) {
                                setTimeout(() => {
                                  // @ts-ignore
                                  listRef.current.scrollToRow(formValues.children.length);
                                }, 500);
                              }
                            }}
                            data-selenium="form-event-add-date"
                          >
                            <Box color="#2F80ED">Добавить дату</Box>
                          </Button>
                          <Button
                            type="button"
                            plain
                            onClick={openCreateDatesModal}
                            data-selenium="form-event-add-dates"
                          >
                            <Box color="#2F80ED">Добавить даты</Box>
                          </Button>

                          {hasPastDates(data.children) && (
                            <Button
                              type="button"
                              plain
                              onClick={() => setDisplayPastEvents(!displayPastEvents)}
                            >
                              <Box color="#2F80ED">{displayPastEvents ? 'Скрыть' : 'Показать'} прошедшие</Box>
                            </Button>
                          )}
                        </StepModal.Row>
                      )}
                    </Styled.RowField>
                  </div>
                </StyledEventsForm.RowWithLabel>
              </Modal.Container>
              <Modal.Footer fullSize contentWidth="800px">
                {backButton}
                <Button
                  type="button"
                  onClick={handleSubmitForm}
                  disabled={isSubmitting}
                  data-selenium="create-event-button-continue"
                >
                  Продолжить
                </Button>
              </Modal.Footer>
            </form>
          );
        }}
      />
      <EventsFormPeriodicalSettings />
    </EventsLayout.Wrapper>
  );
};

export default withCheckIsMobile(connect(null, { deleteEvent, getPermission })(EventsFormSecondStep));
