import { createEvent, createStore, sample } from 'effector';
import { Router } from 'next/router';
import { fetchPersonsFx, IPaginatedPersonList } from 'shared/api/reference/persons';

export const pageMounted = createEvent<Router['query']>();

export const $query = createStore<Router['query']>({});
export const $persons = createStore<IPaginatedPersonList>({
  results: [],
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});
export const $loading = createStore(true);

sample({
  clock: pageMounted,
  target: $query,
});

sample({
  source: $query,
  fn: () => true,
  target: $loading,
});

sample({
  source: {
    query: $query,
  },
  fn: ({ query }) => query,
  target: fetchPersonsFx,
});

sample({
  clock: fetchPersonsFx.done,
  fn: ({ result }) => {
    return result;
  },
  target: $persons,
});

sample({
  clock: fetchPersonsFx.done,
  fn: () => false,
  target: $loading,
});
