import React from 'react';
import useEventData from 'shared/lib/useEventData';
import Spinner from '../../../Spinner';
import EventPrices from '../../../EventPrices';

interface StepProps {
  modalData: any;
  goBack: () => void;
  goNext: () => void;
}

const EventsFormNineStep: React.FC<StepProps> = ({ modalData, goNext, goBack }) => {
  const dateFinish = true;
  const {
    state: { data, isLoading },
  } = useEventData({ modalData }, dateFinish);

  if (isLoading) {
    return <Spinner />;
  }

  const eventDates = data.eventDates?.filter((d) => !d.outdated);

  return (
    <EventPrices
      eventSourceId={data.event_source_id}
      eventDates={eventDates}
      goBack={goBack}
      goNext={goNext}
      rubCurrency
    />
  );
};

export default EventsFormNineStep;
