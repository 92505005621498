import { createEvent, createStore, sample } from 'effector';
import sortBy from 'lodash/sortBy';
import { Router } from 'next/router';
import * as locationsApi from 'shared/api/reference/locations';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

export const pageMounted = createEvent();

export const $locationsQuery = createStore<Router['query']>({});

export const $data = createStore({
  results: [],
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});

const groupAlphabetically = (data: Item[]) =>
  data.reduce((acc, curr) => {
    const filteredInfo = curr.info.find((info) => info.language_code === LANGUAGES.RU);
    const title: string = filteredInfo?.title[0];
    acc[title] = acc[title] || [];

    acc[title].push(curr);

    return acc;
  }, {});

export const $loading = createStore(true);

sample({
  clock: pageMounted,
  target: $locationsQuery,
});

sample({
  source: { locationsQuery: $locationsQuery },
  fn: ({ locationsQuery }) => locationsQuery,
  target: locationsApi.fetchLocationsFx,
});

sample({
  clock: locationsApi.fetchLocationsFx.done,
  fn: ({ result }) => {
    const { results, ...meta } = result;

    return {
      ...meta,
      results: groupAlphabetically(sortBy(results, 'code')),
    };
  },
  target: $data,
});

sample({
  clock: locationsApi.fetchLocationsFx.done,
  fn: () => false,
  target: $loading,
});
