import Button from 'components/Button';
import flow from 'lodash/flow';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'entities/modal';
import LOCALES from 'shared/constants/LOCALES';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import MODALS from 'shared/constants/MODALS';
import TAB from 'shared/constants/REFERENCE_TABS';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import PlusIcon from '../../../../static/icons/plus.svg';
import { withTranslation } from '../../../i18n';
import Styled from './styles';
import { useDeviceType } from 'shared/lib/useDeviceType';

interface ButtonProps {
  referenceName: string;
  t: (value: string, params?: any) => string;
  onClick?: () => void;
}

export const ReferenceCreateButton: React.FC<ButtonProps> = (props) => {
  const { t, referenceName, onClick } = props;
  const { isMobile } = useDeviceType();
  const dispatch = useDispatch();

  const BUTTON_CONTENT = {
    [TAB.PLACES]: t('buttons.places'),
    [TAB.HALLS]: t('buttons.halls'),
    [TAB.EVENT_CATEGORIES]: t('buttons.event_categories'),
    [TAB.EXPANSIONS]: t('buttons.expansions'),
    [TAB.COUNTRIES]: t('buttons.countries'),
    [TAB.LOCATIONS]: t('buttons.locations'),
    [TAB.PERSONS]: t('buttons.persons'),
    [TAB.CURRENCY]: t('buttons.currency'),
    [TAB.ACTUAL]: t('buttons.actual'),
    [TAB.QnA]: t('buttons.qna'),
    [TAB.QnA]: t('buttons.qna'),
    [TAB.SUBDOMAINS]: t('buttons.subdomains'),
  };

  const handleClick = () => {
    const MODAL = {
      [TAB.PLACES]: MODALS.REFERENCE_PLACE,
      [TAB.HALLS]: MODALS.REFERENCE_HALL,
      [TAB.EVENT_CATEGORIES]: MODALS.REFERENCE_EVENT_CATEGORY,
      [TAB.EXPANSIONS]: MODALS.REFERENCE_EXPANSION,
      [TAB.PERSONS]: MODALS.REFERENCE_PERSON,
      [TAB.CURRENCY]: MODALS.REFERENCE_CURRENCY,
      [TAB.ACTUAL]: MODALS.REFERENCE_ACTUAL,
      [TAB.QnA]: MODALS.REFERENCE_QnA,
    };

    let modalDataObj = {
      fullSize: isMobile,
      mobilePadding: '0px',
    };

    if (MODAL[referenceName] === MODALS.REFERENCE_QnA) {
      modalDataObj = {
        fullSize: true,
        mobilePadding: '0px',
      };
    }

    if (MODAL[referenceName] === MODALS.REFERENCE_MARKUP) {
      modalDataObj = {
        fullSize: true,
        mobilePadding: '0px',
      };
    }

    dispatch(
      openModal(
        MODAL[referenceName],
        {
          modalData: modalDataObj,
          CloseButton: isMobile && (
            <Styled.Close>
              <img src="/static/icons/close-red.svg" alt="Отмена" />
            </Styled.Close>
          ),
        },
        MODAL_STATES.CREATE,
      ),
    );
  };

  return (
    <Button onlyIcon={isMobile} onClick={onClick || handleClick}>
      {isMobile ? <PlusIcon opacity={0.8} /> : BUTTON_CONTENT[referenceName]}
    </Button>
  );
};

const withHoC = flow([withCheckIsMobile, withTranslation([LOCALES.REFERENCES])]);

export default withHoC(ReferenceCreateButton);
