import { useRouter, withRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import LOCALES from 'shared/constants/LOCALES';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import qnaSources from 'shared/sources/reference/qna';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { withTranslation } from '../../../../i18n';
import withLandingData from '../../../landings/helpers/withLandingData';
import QnAListCreate from './create-modal';
import QnAListUpdate from './update-modal';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  lng: string;
  data: any;
  modalState: string;
  onChangeLng: any;
  updateList: (data: any) => any;
  onUpdate: (data: any) => any;
}

const QnAListModal: React.FC<ModalProps> = (props) => {
  const { data, onChangeLng, updateList, modalState, onUpdate, lng, closeModal } = props;
  const [qnaItem, setQnaItem] = useState({ data });
  const router = useRouter();

  useEffect(() => {
    if (router.query.modalId) {
      qnaSources.qnaItem(router.query.modalId).then((response) => {
        setQnaItem((prevState) => ({
          ...prevState,
          data: response,
        }));
      });
    }
  }, [router.query.modalId]);

  if (modalState === MODAL_STATES.CREATE) {
    return <QnAListCreate onSuccess={updateList} closeModal={closeModal} onChangeLng={onChangeLng} />;
  }

  return (
    <QnAListUpdate
      data={qnaItem}
      lng={lng}
      onChangeLng={onChangeLng}
      closeModal={closeModal}
      onSuccess={onUpdate}
    />
  );
};

export default // @ts-ignore
withLandingData(withRouter(withTranslation([LOCALES.FORMS, LOCALES.LANDINGS])(QnAListModal)), {
  translation: 'playground',
  sources: qnaSources,
  emitUpdateTableAfterSave: true,
});
