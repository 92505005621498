import { createEvent, createStore, sample } from 'effector';
import { Router } from 'next/router';
import { fetchHallsFx, PaginatedHalls } from 'shared/api/reference/halls';

export const pageMounted = createEvent<Router['query']>();

export const $query = createStore<Router['query']>({});
export const $loading = createStore(true);

export const $data = createStore<PaginatedHalls>({
  results: [],
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});

sample({
  clock: pageMounted,
  target: $query,
});

// load
sample({
  source: $query,
  target: fetchHallsFx,
});

sample({
  source: $query,
  fn: () => true,
  target: $loading,
});

sample({
  clock: fetchHallsFx.done,
  fn: ({ result }) => result,
  target: $data,
});

sample({
  clock: fetchHallsFx.done,
  fn: () => false,
  target: $loading,
});
