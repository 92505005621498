import styled from 'styled-components';

export default {
  Dropdown: styled.div`
    margin-left: -8px;
    min-width: 40px;

    .react-select__menu {
      width: 160px;
      min-height: 275px;
      z-index: 2 !important;
      background-color: #fff;
    }

    .react-select__control {
      height: 32px;
      min-height: 0;
      border: none;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #e1e1e1;
      }
    }

    .react-select__control--is-focused {
      border: none !important;

      .react-select__indicators > div {
        padding: 0 0 0 4px;
        & svg {
          fill: #000 !important;
        }
      }
    }

    .react-select__indicators > div {
      padding: 2px 4px 0 0;
    }

    .react-select__value-container {
      padding: 0;
      cursor: pointer;
    }

    .react-select__placeholder {
      margin-left: 0;
      padding-left: 4px;
      color: #000;
      cursor: pointer;
    }
  `,
};
