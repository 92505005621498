import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import Styled from './styles';
import ArrowIcon from '../../../../static/icons/arrow.svg';

interface Props {
  isFocused: boolean;
  // eslint-disable-next-line no-undef
  children: JSX.Element;
  innerProps: any;
  data: any;
}

const OptionWithEventCategory: React.FC<Props> = (props) => {
  const { children, innerProps, isFocused, data } = props;

  // const path = get(data, 'data.path');
  // debugger

  return (
    <Styled.Option {...innerProps} isFocused={isFocused}>
      <Styled.EventCategoryInfo>
        {children}
        {/* <Styled.EventCategoryInfoPath>
          {map(path, (item, index) => (
            <div key={`${item.name}_${index}`}>
              <ArrowIcon />
              {item.name}
            </div>
          ))}
        </Styled.EventCategoryInfoPath> */}
      </Styled.EventCategoryInfo>
    </Styled.Option>
  );
};

export default OptionWithEventCategory;
