import get from 'lodash/get';
import queryString from 'query-string';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { eventsSources, createEvent, editEvent, getPermission, loadEvent } from 'entities/events';
import { openModal } from 'entities/modal';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import MODALS from 'shared/constants/MODALS';
import { getPeriodicalChildrenData } from 'shared/helpers/events';
import { saveTranslationData } from 'shared/helpers/form';
import api from 'shared/services/api';
import { parsersEventsSources } from 'entities/parsers/events';
import ERRORS from 'shared/constants/ERRORS';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import { formatDashDate, formatTime } from 'shared/helpers/formatters/date';
import { i18n } from '../../i18n';

interface Props {
  modalData: any;
}

interface IState {
  isLoading: boolean;
  isSubmitting: boolean;
  updateAllowed: boolean;
  data: any;
}

export const mapSubmitData = (newData: any) => ({
  title: newData.title,
  is_global: newData.is_global,
  tags:
    newData.tags &&
    newData.tags.map((item) => ({ id: item.value || item.id, name: item.label || item.name })),
  restriction: newData.restriction || '',
  ...(newData.parent && {
    parent_id: newData.parent.value || newData.parent.id,
  }),
  preview: newData.preview,
  cover: newData.cover,
  hint_text: newData.hint_text || '',
  is_fan_id: newData.is_fan_id,
  is_pushkin: newData.is_pushkin,
  is_show_hint: newData.is_show_hint,
  annotation: newData.annotation || '',
  description: newData.description || '',
  short_reference: newData.short_reference || '',
  ticket_cover: newData.ticket_cover || '',
  manager_id: newData.manager_id && newData.manager_id.value,
  seo_event_name_with_preposition: newData.seo_event_name_with_preposition || undefined,
  seo_short_name_with_preposition: newData.seo_short_name_with_preposition || undefined,
  seo_place_accusative: newData.seo_place_accusative || undefined,
  seo_place_prepositional: newData.seo_place_prepositional || undefined,
  seo_base_names: newData.seo_base_names || undefined,
  seo_dates: newData.seo_dates || undefined,
  seo_categories_with_preposition: newData.seo_categories_with_preposition || undefined,
  seo_subtitle: newData.seo_subtitle || undefined,
  seo_duration: newData.seo_duration || undefined,
  seo_place: newData.seo_place || undefined,
  fill_by_template: newData.fill_by_template,
  ...(newData.event_category && {
    event_category: {
      ...newData.event_category,
      name: newData.event_category.label,
    },
    event_category_id: newData.event_category.value || newData.event_category.id,
  }),
});

const useEventData = ({ modalData }: Props, dateFinish = false) => {
  const { t } = useTranslation();
  const router = useRouter();
  const event = router.query.event || modalData.eventId;
  const isEdit = Boolean(event);
  const dispatch: any = useDispatch();
  const [state, setState] = useState<IState>({
    isLoading: true,
    isSubmitting: false,
    updateAllowed: true,
    data: {},
  });

  const changeRoute = (id?: any) =>
    new Promise((resolve, reject) => {
      const query = {
        ...router.query,
        event: id,
      };
      const href = `${router.pathname}?${queryString.stringify(query)}`;

      router
        .push(
          {
            pathname: router.pathname,
            query,
          },
          href,
          { shallow: true },
        )
        .then(resolve)
        .catch((err) => reject(new Error(err)));
    });

  const closeEventModal = (data = state.data) => {
    if (modalData.parserData && (data.ru?.title || data.title)) {
      const promises = [api.get(parsersEventsSources.detail(modalData.parserData.id)), changeRoute()];
      return Promise.all(promises).then(([response]: any) => {
        dispatch(
          openModal(MODALS.LINK_EVENT_MODAL, {
            ...response.data,
            inputValue: data.ru?.title || data.title,
          }),
        );
      });
    }

    NotificationManager.success(t('forms:success'));
    return changeRoute().then(() => {
      modalData.forceCloseModal();
      window.location.reload();
    });
  };

  const create = (data) => dispatch(createEvent(data));
  const update = (data, eventId = event) =>
    dispatch(editEvent(eventId, data)).then((response) => {
      if (response.error) {
        if (response.error.response.data.error.event) {
          NotificationManager.error(t('events:form.error_personality'), t('forms:error'));
          return response;
        }

        if (response.error.response.data.error.page2.schedules) {
          NotificationManager.error('Проверьте правильность указанных дат', t('forms:error'));
          return response;
        }

        NotificationManager.error(ERRORS.DEFAULT, t('forms:error'));
        return response;
      }

      return response;
    });

  const updateTranslations = (data) =>
    saveTranslationData({
      data,
      create: eventsSources.root,
      update: eventsSources.detail,
    });

  const predefinedDate = (data) => {
    const { parserData } = modalData;

    if (!parserData || data.date_start) return {};
    const { when } = parserData;
    const whenFinish = moment(when, DATE_FORMATS.DATE_TIME).add(2, 'hour');

    return {
      date_start: formatDashDate(when),
      time_start: formatTime(when),
      date_finish: formatDashDate(whenFinish),
      time_finish: formatTime(whenFinish),
    };
  };

  const loadData = (lng = LANGUAGES.RU) => {
    dispatch(loadEvent({ event, outdated: true }, lng)).then(async (action) => {
      if (action.error) {
        return NotificationManager.error('Ошибка');
      }

      const parentId = get(action, 'payload.data.parent.id');
      const isPeriodical = get(action, 'payload.data.is_periodical');

      const updateAllowed = await dispatch(getPermission(parentId || event)).then((response) =>
        get(response, 'payload.data.update_allowed'),
      );

      if (parentId && isPeriodical) {
        return dispatch(loadEvent({ event: parentId, outdated: true }, lng)).then((parentAction) => {
          const { data: parentData } = parentAction.payload;
          Emmiter.emit(EVENTS.EVENT_FORM_UPDATE, {
            disabledSteps: !get(parentData, 'place') ? [2] : [],
            isEdit,
          });
          return setState({
            data: {
              ...getPeriodicalChildrenData(parentData, dateFinish),
              ...parentData,
            },
            updateAllowed,
            isLoading: false,
            isSubmitting: false,
          });
        });
      }

      const { data } = action.payload;

      Emmiter.emit(EVENTS.EVENT_FORM_UPDATE, {
        disabledSteps: !get(data, 'place') ? [2, 3, 4, 5] : [],
        isEdit,
      });

      if (get(data, 'place')) {
        Emmiter.emit(EVENTS.EVENT_FORM_UPDATE, {
          disabledSteps: !get(data, 'hall_layout') ? [3, 4, 5] : [],
          isEdit,
        });
      }

      let eventDataV2;
      if (data && data?.event_source_id) {
        eventDataV2 = await api
          .get(eventsSources.detailV2(data.event_source_id))
          .then((response) => response.data)
          .catch((err) => {
            console.error(err);

            return {
              qna: [],
            };
          });
      }

      const bundles =
        eventDataV2?.bundles?.map((bundle) => {
          const info = bundle.info.find((i) => i.language_code === i18n?.language);
          return {
            label: info?.title,
            value: bundle.id,
          };
        }) || [];

      return setState({
        data: {
          ...data,
          qna: eventDataV2?.qna || [],
          bundles,
          ...getPeriodicalChildrenData(data, dateFinish),
          id: event,
          ...predefinedDate(data),
        },
        updateAllowed,
        isLoading: false,
        isSubmitting: false,
      });
    });
  };

  useEffect(() => {
    Emmiter.emit(EVENTS.CHANGE_LANGUAGE, { target: { value: i18n.language } });
    if (event && !router.query.event) {
      changeRoute(event).catch((e) => new Error(e));
    }

    if (event) {
      loadData();
    } else {
      Emmiter.emit(EVENTS.EVENT_FORM_UPDATE, { disabledSteps: [2], isEdit: false });
      setState({
        isLoading: false,
        isSubmitting: false,
        updateAllowed: true,
        data: {},
      });
    }
  }, []);

  const changeLanguage = (lng: string) => loadData(lng);

  return {
    event,
    isEdit,
    state,
    t,
    loadData,
    changeLanguage,
    create,
    update,
    updateTranslations,
    changeRoute,
    closeEventModal,
  };
};

export default useEventData;
