import Box from 'components/Box';
import { ButtonGroup } from 'components/Button';
import { PlainTable } from 'components/Table';
import Dropdown from 'containers/Pages/Landings/Dropdown';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { openRouterModal } from 'entities/modal';
import { TABLE_EVENTS } from 'shared/constants/EVENTS';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import MODALS from 'shared/constants/MODALS';
import { formatFullDateAndTime } from 'shared/helpers/formatters/date';
import useLandings from 'shared/lib/useLandings';
import Emmiter from 'shared/services/Emmiter';
import qnaSources from 'shared/sources/reference/qna';

interface ItemProps {
  data: any;
}
const renderButtons = (item: any, handleDelete: (item: any) => void, handleCopy: (item: any) => void) => {
  return (
    <Dropdown
      isLarge
      deleteMethod={handleDelete}
      modalType={MODALS.REFERENCE_QnA}
      copyMethod={handleCopy}
      options={[
        { value: MODAL_STATES.EDIT, label: 'Редактировать' },
        { value: MODAL_STATES.COPY, label: 'Клонировать' },
        { value: MODAL_STATES.DELETE, label: 'Удалить', danger: true },
      ]}
      data={item}
      dataSelenium="news-actions"
    />
  );
};
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
const CLONING_SLEEP_TIME = 500;
export const QnAListItem: React.FC<ItemProps> = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { remove } = useLandings({ source: qnaSources });
  const handleDelete = async (item: any) => {
    remove(item);
    await sleep(CLONING_SLEEP_TIME);
    return Emmiter.emit(TABLE_EVENTS.UPDATE_TABLE);
  };
  const handleView = (item: any) => {
    dispatch(openRouterModal({ modalType: MODALS.REFERENCE_QnA, data: item, modalState: MODAL_STATES.VIEW }));
  };
  let createQnA: any;
  const copyQnA = async ({ id }: { id: string }) => {
    try {
      const response = await qnaSources.qnaItem(id);
      createQnA = await qnaSources.qnaItemCreate(response);

      const promises = response.info.map((qna: any) => {
        return qnaSources.qnaItemCreateTranslation(createQnA.data.id, qna, qna.language_code);
      });

      await Promise.all(promises);
      Emmiter.emit(TABLE_EVENTS.UPDATE_TABLE);

      return dispatch(
        openRouterModal({
          modalType: MODALS.REFERENCE_QnA,
          data: { ...data, id: createQnA.data.id },
          modalState: MODAL_STATES.EDIT,
        }),
      );
    } catch (error) {
      NotificationManager.error('Oшибка при копировании, проверьте корректность данных.');
      remove(createQnA.data);
      await sleep(CLONING_SLEEP_TIME);
      return Emmiter.emit(TABLE_EVENTS.UPDATE_TABLE);
    }
  };
  return (
    <PlainTable.Tr onClick={() => handleView(data)}>
      <PlainTable.Td verticalAlign="middle" mobileWidth="50%">
        {data.title}
      </PlainTable.Td>
      <PlainTable.Td verticalAlign="middle" mobileWidth="50%">
        {formatFullDateAndTime(data.updated_at)}
      </PlainTable.Td>
      <PlainTable.Td
        verticalAlign="middle"
        mobileWidth="50%"
        mobilePadding="10px 8px 10px 24px"
        mobildeAlignItems="center"
        mobileDisplay="flex"
      >
        <Box justifyContent="flex-end">
          <ButtonGroup>{renderButtons(data, handleDelete, copyQnA)}</ButtonGroup>
        </Box>
      </PlainTable.Td>
    </PlainTable.Tr>
  );
};
