import styled, { css, createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

interface RowProps {
  spaceBottom?: boolean;
  mobileOrder?: string | number;
  flexContainer?: boolean;
  width?: string;
  margin?: string;
  align?: string;
  mobileRow?: boolean;
  mobileWidth?: string;
  mobileMargin?: string;
}

interface ColumnProps {
  width?: string;
  padding?: string;
  mobileWidth?: string;
  mobilePaddingTop?: string;
  withMobileMargin?: boolean;
  mobileAlign?: string;
  paddingBottom?: boolean;
}

interface FieldProps {
  width?: string;
  padding?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  margin?: string;
  withMobileMargin?: boolean;
}

interface FooterProps {
  padding?: string;
  mobileOrder?: string | number;
}

export default {
  GlobalStyles: createGlobalStyle`
    .modal__wrapper {
      box-sizing: border-box;
    }

    .modal__footer {
      margin: auto 0 0 0;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__body {
        padding: 0;
      }

      .modal__footer {
        padding: 0 8px;
      }
  }
  `,

  Container: styled.div`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > div {
      flex-grow: 1;
    }

    & form {
      flex-grow: 1;
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      box-sizing: border-box;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding: 32px 16px;
    }
  `,

  Wrapper: styled.div`
    padding: 0 28px 28px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,

  Row: styled.div<RowProps>`
    margin-bottom: ${({ spaceBottom }) => (spaceBottom ? '48px' : '32px')};

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-bottom: ${({ spaceBottom }) => (spaceBottom ? '48px' : '24px')};
      order: ${({ mobileOrder }) => mobileOrder};
    }

    ${({ flexContainer, width, margin, align, mobileWidth, mobileRow, mobileMargin }) =>
      flexContainer &&
      css`
        display: flex;
        box-sizing: border-box;
        width: ${width || null};
        margin: ${margin || '0 -16px'};
        align-items: ${align || null};

        @media (max-width: ${BREAKPOINTS.MD}) {
          flex-direction: ${mobileRow ? null : 'column'};
          width: ${mobileWidth || null};
          margin: ${mobileMargin || '0 -16px'};
        }
      `}
  `,

  Column: styled.div<ColumnProps>`
    display: flex;
    box-sizing: border-box;
    width: ${({ width }) => width || '100%'};
    padding: ${({ padding }) => padding || null};
    padding-bottom: ${({ paddingBottom }) => (paddingBottom ? '16px' : '32px')};

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: relative;
      flex-direction: column;
      width: ${({ mobileWidth }) => mobileWidth || '100%'};
      padding-top: ${({ mobilePaddingTop }) => mobilePaddingTop};
      padding-bottom: 24px;
      margin-bottom: ${({ withMobileMargin }) => (withMobileMargin ? '32px' : null)};
      align-items: ${({ mobileAlign }) => mobileAlign || null};
    }

    @media (max-width: ${BREAKPOINTS.XS}) {
      width: 100%;
    }
  `,

  Field: styled.div<FieldProps>`
    box-sizing: border-box;
    width: ${({ width }) => width || '100%'};
    padding: ${({ padding }) => padding || null};
    margin-top: ${({ marginTop }) => marginTop || null};
    margin-left: ${({ marginLeft }) => marginLeft || null};
    margin-right: ${({ marginRight }) => marginRight || null};
    margin-bottom: ${({ marginBottom }) => marginBottom || null};
    margin: ${({ margin }) => margin || null};

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      margin-bottom: ${({ withMobileMargin }) => (withMobileMargin ? '32px' : null)};
    }
  `,

  Footer: styled.div<FooterProps>`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: ${({ padding }) => padding};
    margin: auto -4px 0;

    @media (max-width: ${BREAKPOINTS.MD}) {
      order: ${({ mobileOrder }) => mobileOrder};
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      flex-direction: column;
    }
  `,

  FooterButton: styled.div`
    box-sizing: border-box;
    display: flex;
    padding: 0 4px;

    @media (max-width: ${BREAKPOINTS.SM}) {
      &:not(:last-child) {
        padding-bottom: 12px;
      }

      & > button {
        width: 100%;
      }
    }
  `,

  ParsersInfo: styled.div`
    & button {
      height: 40px;
      width: 40px;
    }
  `,

  Header: styled.div`
    height: 72px;
    position: relative;
    padding: 16px 48px 8px 48px;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid #e1e1e1;
  `,

  HeaderTitle: styled.div`
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    pointer-events: none;

    & h1 {
      text-align: center;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      padding-right: 24px;
    }
  `,

  HeaderNav: styled.div``,

  HeaderButtons: styled.div`
    display: flex;

    & button {
      font-weight: bold;
      font-size: 14px;
    }
  `,

  MobileContainer: styled.div`
    display: none;

    @media ${MEDIA_QUERIES.MOBILE} {
      display: block;
    }
  `,

  TabletAndDesktopContainer: styled.div`
    @media ${MEDIA_QUERIES.MOBILE} {
      display: none;
    }
  `,

  RowWithLabel: styled.div`
    display: flex;
    align-items: flex-start;

    &:not(:last-of-type) {
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid #f5f5f5;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
    }
  `,
  RowLabel: styled.div`
    width: 176px;
    margin-right: 32px;
    font-size: 16px;
    line-height: 21px;
    color: #000;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      margin: 0 0 24px;
    }
  `,
  RowDesc: styled.div`
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.64);
  `,
  RowField: styled.div<{ width?: string }>`
    position: relative;
    width: ${({ width }) => width || '385px'};

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
    }
  `,

  ProgressBar: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 735px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      position: relative;
      top: 0;
      left: 0;
      align-self: flex-start;
      transform: none;
      max-width: 100%;
      overflow-x: auto;
      margin: 10px -16px -8px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100vw;
    }
  `,

  LeftButton: styled.div`
    margin-right: auto;
  `,
};
