import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Styled from './styles';
import Tooltip from '../../../../Tooltip';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';

interface TimeSelectorProps {
  children: ReactElement;
  excludedTimes: Array<{ startDate: moment.Moment; endDate: moment.Moment }>;
  dateTimes: Array<{ startDate: moment.Moment; endDate: moment.Moment }>;
  handleExcludeTime: (date: moment.Moment) => void;
}

const TimeSelector: React.FC<TimeSelectorProps> = ({
  children,
  dateTimes,
  excludedTimes,
  handleExcludeTime,
}) => {
  const [tooltipVisible, toggleTooltip] = useState(false);

  if (dateTimes.length === 1) return children;

  const times =
    dateTimes.length > 1 &&
    dateTimes.map((item) => {
      const date = item.startDate;
      const isTimeExcluded =
        excludedTimes.filter((ex) => moment(ex.startDate).isSame(item.startDate, 'hour')).length > 0;
      return (
        <Styled.TimeButton
          className={classNames({ 'except-calendar-time-disabled': isTimeExcluded })}
          key={date.toISOString()}
          transparentBlue
          onClick={() => handleExcludeTime(date)}
        >
          {moment(date).format(DATE_FORMATS.DATE_TIME_PREVIEW)}
        </Styled.TimeButton>
      );
    });
  return (
    <div>
      {tooltipVisible && <Styled.TimeOverlay onClick={() => toggleTooltip(false)} />}
      <Tooltip
        visible={tooltipVisible}
        trigger=""
        getTooltipContainer={(trigger: any) => trigger.parentNode}
        text={times}
        isWhite
      >
        {React.cloneElement(children, {
          onClick: () => toggleTooltip(!tooltipVisible),
        })}
      </Tooltip>
    </div>
  );
};

export default TimeSelector;
