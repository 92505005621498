import React, { Component } from 'react';
import FakeProgress from 'fake-progress';
import Button from '../../Button';
import Styled from './styles';

let p;

interface ModalProps {
  total?: number;
  current?: number;
  useFakeProgress?: boolean;
  title: string;
  isError?: boolean;
  hideProgress: () => void;
  errors?: any[];
  longTime?: boolean;
}

interface ModalState {
  fakeProgress: number;
}

if (typeof window !== 'undefined') {
  p = new FakeProgress({
    timeConstant: 20000,
    autoStart: true,
  });
}

class ProgressModal extends Component<ModalProps, ModalState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    errors: [],
    total: false,
    current: false,
    useFakeProgress: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      fakeProgress: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { isError: prevIsError } = prevProps;
    const { isError } = this.props;

    if (prevIsError !== isError) {
      if (typeof window !== 'undefined') {
        p.stop();
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      p.end();
    }
  }

  setFakeProgress(value) {
    this.setState({
      fakeProgress: value,
    });
  }

  render() {
    const { fakeProgress } = this.state;
    const { title, current, total, isError, errors, hideProgress, useFakeProgress, longTime } = this.props;

    const computedValue = ((100 / total) * current).toFixed();

    if (useFakeProgress) {
      setInterval(
        () => {
          this.setFakeProgress((p.progress * 100).toFixed());
        },
        longTime ? 17000 : 1000,
      );
    } else if (typeof window !== 'undefined') {
      p.end();
    }

    return (
      <Styled.Container>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Bar>
          <Styled.Indicator size={useFakeProgress ? fakeProgress : computedValue} isError={isError}>
            <Styled.IndicatorDetail>
              {`${useFakeProgress ? fakeProgress : computedValue}%`}
            </Styled.IndicatorDetail>
          </Styled.Indicator>
        </Styled.Bar>

        <Styled.Error>
          {errors.map((error) => {
            let modifiedError = error;
            if (error.includes('This tickets is already exists')) {
              modifiedError = error.replace('This tickets is already exists', 'Этот билет уже существует');
            }

            return <div dangerouslySetInnerHTML={{ __html: modifiedError }} />;
          })}
        </Styled.Error>

        {errors.length > 0 && (
          <Styled.Return>
            <Button onClick={hideProgress}>Вернуться</Button>
          </Styled.Return>
        )}
      </Styled.Container>
    );
  }
}

export default ProgressModal;
