import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  SpinnerContainer: styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  CheckboxWrapper: styled.div`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  FieldSideButtons: styled.div`
    position: absolute;
    right: 8px;
    transform: translateX(100%);
    display: flex;
    z-index: 3;

    & > button:not(:last-child) {
      margin-right: 8px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: relative;
      padding-top: 24px;
      bottom: 0;
      right: 0;
      transform: none;
    }
  `,

  Row: styled.div`
    position: relative;
    display: flex;
    width: 455px;
    margin: 0 -16px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      flex-wrap: wrap;
    }
  `,

  RowField: styled.div`
    width: 100%;
  `,
};
