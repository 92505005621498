import { useUnit } from 'effector-react';
import React, { useEffect, useState } from 'react';
import routes from 'shared/helpers/routes';
import useEventData from 'shared/lib/useEventData';
import PenIcon from '../../../../../../static/icons/pen.svg';
import Button from '../../../../Button';
import Link from '../../../../Link';
import Modal from '../../../../Modal';
import Spinner from '../../../../Spinner';
import TextOverflow from '../../../../TextOverflow';
import Tooltip from '../../../../Tooltip';
import {
  $hallId,
  $hallLayoutId,
  $hallLayoutName,
  $halls,
  $isUpdated,
  $selectedHallLayoutId,
  hallLayoutIsSelectedToUpdate,
  hallLayoutLoaded,
  hallSelected,
  stepOpened,
} from '../model';
import { Halls } from './halls';
import Styled from './styles';

interface StepProps {
  loadHalls: (data: any) => any;
  goNext: () => void;
  halls: any[];
  hallLayouts: any[];
  error?: string;
  isDesktop: boolean;
  // eslint-disable-next-line no-undef
  backButton: JSX.Element;
  modalData: any;
}

interface StepState {
  accodrionExpanded: boolean;
}

export const EventsFormThirdStep: React.FC<StepProps> = (props) => {
  const { modalData, goNext, isDesktop, backButton } = props;
  const [stepState, setStepState] = useState<StepState>({
    accodrionExpanded: false,
  });
  const { state, update } = useEventData({ modalData });
  const {
    stepOpened: onStepOpened,
    halls,
    hallLayoutName,
    hallLayoutId,
    isUpdated,
    selectedHallLayoutId,
    hallLayoutIsSelectedToUpdate: onHallLayoutIsSelectedToUpdate,
    hallSelected: onHallSelected,
    hallLayoutLoaded: onHallLayoutLoaded,
  } = useUnit({
    stepOpened,
    hallSelected,
    hallLayoutLoaded,
    hallLayoutIsSelectedToUpdate,
    hallId: $hallId,
    hallLayoutName: $hallLayoutName,
    hallLayoutId: $hallLayoutId,
    selectedHallLayoutId: $selectedHallLayoutId,
    halls: $halls,
    isUpdated: $isUpdated,
  });

  useEffect(() => {
    if (state.isLoading) return;
    const { place, hall_layout: hallLayout } = state.data;

    onStepOpened({
      place: place.id,
      limit: '150',
    });

    if (hallLayout) {
      onHallSelected(hallLayout.hall_id);
      onHallLayoutLoaded({ id: hallLayout.id, name: hallLayout.name });
    }
  }, [state.isLoading]);

  const editEvent = async () => {
    if (state.updateAllowed && isUpdated && selectedHallLayoutId) {
      const response = await update({
        page3: {
          hall_layout_id: selectedHallLayoutId,
        },
      });

      if (!response.error) goNext();
    } else {
      goNext();
    }
  };

  const toggleAccordionHeader = () => {
    setStepState((prevState) => ({
      ...prevState,
      accodrionExpanded: !prevState.accodrionExpanded,
    }));
  };

  const { accodrionExpanded } = stepState;

  if (state.isLoading || !halls) {
    return (
      <Styled.SpinnerContainer>
        <Spinner />
      </Styled.SpinnerContainer>
    );
  }

  return (
    <>
      <Styled.Wrapper>
        <Styled.Scheme>
          {selectedHallLayoutId && <Styled.Demo src={`/widget/${selectedHallLayoutId}?previewMode=true`} />}

          <Styled.Buttons>
            {selectedHallLayoutId && (
              <Link
                href={routes.hallLayout(selectedHallLayoutId)}
                simple
                target="_blank"
                disabled={!selectedHallLayoutId}
              >
                <Tooltip text="Редактировать схему">
                  <Button
                    type="button"
                    secondary
                    iconOnly
                    disabled={!selectedHallLayoutId}
                    data-selenium="form-event-edit-scheme"
                  >
                    <PenIcon />
                  </Button>
                </Tooltip>
              </Link>
            )}
          </Styled.Buttons>
          <Tooltip text={!state.updateAllowed && 'Нельзя изменить схему!'}>
            <Styled.SaveChanges>
              <Button
                type="button"
                onClick={onHallLayoutIsSelectedToUpdate}
                data-selenium="form-event-select-hall"
                disabled={!state.updateAllowed}
              >
                {`Выбрать схему${!isDesktop ? '' : ' зала'}`}
              </Button>
            </Styled.SaveChanges>
          </Tooltip>
        </Styled.Scheme>
        <Styled.Sidebar isActive={accodrionExpanded}>
          <Styled.SidebarTitle onClick={toggleAccordionHeader} isActive={accodrionExpanded}>
            <TextOverflow>{hallLayoutName || 'Схемы залов'}</TextOverflow>
          </Styled.SidebarTitle>
          <Styled.AccordionWrapper>
            <Halls list={halls} />
          </Styled.AccordionWrapper>
        </Styled.Sidebar>
      </Styled.Wrapper>
      <Modal.Footer fullSize>
        {backButton}
        {isUpdated ? (
          <Button
            type="button"
            onClick={editEvent}
            disabled={state.isSubmitting}
            data-selenium="form-event-scheme-save"
          >
            Сохранить изменения
          </Button>
        ) : (
          <Button
            type="button"
            disabled={!hallLayoutId}
            onClick={editEvent}
            data-selenium="form-event-button-continue"
          >
            Продолжить
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};
