import { createEvent, createStore, sample } from 'effector';
import { $countriesQuery } from 'pages/reference/countries/model';
import * as countriesApi from 'shared/api/reference/country';
import { Country, FormSubmitData } from 'shared/api/types';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import PAGES from 'shared/constants/PAGES';
import { showedErrorNotification, showedSuccesNotification } from 'shared/helpers/notification';
import { findTranslation } from 'shared/helpers/translations';
import { createToggler } from 'shared/lib/toggler';

export const toggler = createToggler();

export const modalOpened = createEvent<string>();
export const modalClosed = createEvent();
export const formSubmitted = createEvent<FormSubmitData>();
export const deleteSubmitted = createEvent<{ id: string; title: string }>();
export const errorsModalClosed = createEvent();

export const $country = createStore<Country | null>(null);
export const $countryLoading = createStore(true);
export const $modalId = createStore<string | null>(null);
export const $modalFormSubmitting = createStore(false);
export const $deleteErrors = createStore(null);

//load
sample({
  clock: modalOpened,
  target: countriesApi.fetchCountryFx,
});

sample({
  clock: toggler.open,
  target: $modalId,
});

sample({
  clock: modalClosed,
  fn: () => true,
  target: $countryLoading,
});

sample({
  clock: modalClosed,
  fn: () => null,
  target: $country,
});

sample({
  clock: countriesApi.fetchCountryFx.done,
  fn: ({ result }) => {
    return result;
  },
  target: $country,
});

sample({
  clock: countriesApi.fetchCountryFx.done,
  fn: () => false,
  target: $countryLoading,
});

// update

sample({
  source: {
    country: $country,
  },
  clock: formSubmitted,
  filter: ({ country }, { lng }) => {
    if (!country) return false;

    const translation = findTranslation(country.info, lng);

    return !translation;
  },
  fn: ({ country }, { lng, ...info }: FormSubmitData) => ({
    id: country!.id,
    translation: {
      ...info[lng],
      language_code: lng,
    },
  }),
  target: countriesApi.createCountryInfoFx,
});

sample({
  source: {
    country: $country,
  },
  clock: formSubmitted,
  filter: ({ country }, { lng }) => {
    if (!country) return false;

    const translation = findTranslation(country.info, lng);

    return !!translation;
  },
  fn: ({ country }, { lng, ...info }: FormSubmitData) => ({
    id: country!.id,
    translation: {
      ...info[lng],
      language_code: lng,
    },
  }),
  target: countriesApi.updateCountryInfoFx,
});

sample({
  clock: countriesApi.updateCountryInfoFx.done,
  filter: ({ params }) => {
    return params.translation.language_code === LANGUAGES.RU;
  },
  fn: ({ params, result }) => {
    return {
      id: params.id,
      title: result.data.title,
    };
  },
  target: countriesApi.updateCountryFx,
});

sample({
  clock: [countriesApi.createCountryInfosFx.done, countriesApi.updateCountryInfoFx.done],
  target: [toggler.close, showedSuccesNotification],
});

sample({
  source: { countriesQuery: $countriesQuery },
  clock: [countriesApi.createCountryInfosFx.done, countriesApi.updateCountryInfoFx.done],
  fn: ({ countriesQuery }) => countriesQuery,
  target: countriesApi.fetchCountriesFx,
});

sample({
  clock: countriesApi.createCountryInfoFx.done,
  target: toggler.close,
});

sample({
  clock: countriesApi.createCountryInfoFx.done,
  fn: ({ params }) => {
    return params.id;
  },
  target: countriesApi.fetchCountryFx,
});

sample({
  clock: countriesApi.updateCountryInfoFx.done,
  fn: ({ params }) => {
    return params.id;
  },
  target: countriesApi.fetchCountryFx,
});

//delete

sample({
  clock: deleteSubmitted,
  target: countriesApi.deleteCountryFx,
});

sample({
  clock: countriesApi.deleteCountryFx.fail,
  fn: ({ error, params }) => {
    return {
      errors: error.message,
      name: params.title,
      page: PAGES.REFERENCE_COUNTRIES,
    };
  },
  target: $deleteErrors,
});

sample({
  source: { countriesQuery: $countriesQuery },
  clock: countriesApi.deleteCountryFx.done,
  fn: ({ countriesQuery }) => countriesQuery,
  target: countriesApi.fetchCountriesFx,
});

sample({
  clock: countriesApi.deleteCountryFx.done,
  target: [toggler.close, showedSuccesNotification],
});

sample({
  clock: countriesApi.deleteCountryFx.fail,
  target: showedErrorNotification,
});

sample({
  clock: errorsModalClosed,
  fn: () => null,
  target: $deleteErrors,
});
