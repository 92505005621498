import { createEffect, createEvent, createStore, sample } from 'effector';
import { isEmpty } from 'lodash';
import { createToggler } from 'shared/lib/toggler';

const toggler = createToggler();

const insertedImage = createEvent<any>();

const $data = createStore({});

export const editorImageModal = { toggler, insertedImage };

const insertedImageFx = createEffect((data) => {
  data.current.editor.clipboard.dangerouslyPasteHTML(data.range, data.imgElement.outerHTML);
});

sample({
  clock: toggler.open,
  fn: ({ current }) => {
    let range = 0;
    const selection = current.unprivilegedEditor?.getSelection();

    if (!isEmpty(selection)) {
      range = selection?.index;
    }

    return { range, current };
  },
  target: $data,
});

sample({
  source: {
    data: $data,
  },
  clock: insertedImage,
  fn: ({ data }, values) => {
    const imgElement = document.createElement('img');
    imgElement.src = values.selectImage;
    imgElement.width = values.imageWidth;
    imgElement.height = values.imageHeight;
    imgElement.alt = values.alt;

    return {
      imgElement,
      ...data,
    };
  },
  target: insertedImageFx,
});

sample({
  clock: insertedImageFx.done,
  target: toggler.close,
});
