import { createEffect, createEvent, createStore, sample } from 'effector';
import isObject from 'lodash/isObject';
import { NotificationManager } from 'react-notifications';
import {
  createHallFx,
  createHallInfosFx,
  fetchHallsFx,
  FormSubmitData,
  HallTranslation,
} from 'shared/api/reference/halls';
import { Language } from 'shared/api/types';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { createToggler } from 'shared/lib/toggler';
import { $query } from '../../../model';

const toggler = createToggler();
const formSubmitted = createEvent<FormSubmitData>();

const successNotificationFx = createEffect(() => {
  NotificationManager.success('Успешно');
});

const failNotificationFx = createEffect(() => {
  NotificationManager.error('Ошибка');
});

const $isSubmitting = createStore(false);
$isSubmitting.on(formSubmitted, () => true).on(toggler.close, () => false);

export const createHallModal = { toggler, formSubmitted, $isSubmitting };

sample({
  clock: formSubmitted,
  fn: (formData) => {
    return {
      place_id: formData.ru.place_id.value,
      formData,
    };
  },
  target: createHallFx,
});

sample({
  clock: createHallFx.done,
  fn: ({ params, result }) => {
    const translations = Object.keys(params.formData).reduce((acc: HallTranslation[], lang: string) => {
      const dataByLang = params.formData[lang as Language];

      if (LANGUAGES[lang.toUpperCase()] && dataByLang && isObject(dataByLang)) {
        acc.push({
          title: dataByLang.title,
          language_code: lang as Language,
          description: dataByLang.description,
        });

        return acc;
      }
      return acc;
    }, []);

    return { id: result.id, translations };
  },
  target: createHallInfosFx,
});

sample({
  source: {
    query: $query,
  },
  clock: createHallInfosFx.done,
  fn: ({ query }) => query,
  target: [successNotificationFx, fetchHallsFx, toggler.close],
});

sample({
  clock: [createHallFx.fail, createHallInfosFx.fail],
  target: failNotificationFx,
});
