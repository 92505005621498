import config from 'config/config';
import { createEffect } from 'effector';
import { Router } from 'next/router';
import { i18n } from 'next-i18next';
import api from '../../../services/api';
import { FormSubmitData, HallTranslation, PaginatedHalls } from './types';

const route = `${config.COMMON_API_URL}/admin/v1/halls`;

export default {
  root: `${config.COMMON_API_URL}/admin/v1/halls`,
};

export const fetchHallsFx = createEffect(async (query: Router['query']): Promise<PaginatedHalls> => {
  const { place, ...rest } = query;
  const response = await api.get(route, {
    params: {
      ...rest,
      ...(query && { language_code: i18n?.language }),
      ...(place && { place_id_in: place }),
    },
  });

  return response.data;
});

export const createHallFx = createEffect(
  async ({ place_id }: { place_id: string | number; formData: FormSubmitData }) => {
    const response = await api.post(route, { place_id });

    return response.data;
  },
);

export const createHallInfosFx = createEffect(
  async ({ id, translations }: { id: string; translations: HallTranslation[] }) => {
    const requests = translations.map(async (translation) => {
      const response = await api.post(`${route}/${id}/info`, translation);

      return response;
    });

    await Promise.all(requests);
  },
);

export const fetchHallFx = createEffect(async ({ id }: { id: string; type: string }) => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const updateHallFx = createEffect(
  async ({ id, place_id }: { id: string | number; place_id: string | number }) => {
    const response = await api.patch(`${route}/${id}`, { place_id });

    return response.data;
  },
);

export const createHallInfoFx = createEffect(
  async ({ id, translation }: { id: string | number; translation: HallTranslation }) => {
    const response = await api.post(`${route}/${id}/info`, translation);

    return response.data;
  },
);

export const updateHallInfoFx = createEffect(
  async ({ id, translation }: { id: string | number; translation: HallTranslation }) => {
    const response = await api.patch(`${route}/${id}/info/${translation.language_code}`, translation);

    return response.data;
  },
);

export const deleteHallFx = createEffect(async ({ id }: { id: string | number; title: string }) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});
