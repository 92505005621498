import styled, { css } from 'styled-components';
import COLORS from 'shared/constants/COLORS';

interface IsActiveProps {
  isActive?: boolean;
}

interface ButtonStylesProps {
  active?: boolean;
}

interface InnerButtonProps {
  activeInnerItem?: boolean;
  active?: boolean;
}

const ButtonStyles = css<ButtonStylesProps>`
  position: relative;
  width: 100%;
  border: 0;
  background: transparent;
  border-bottom: 1px solid ${COLORS.GRAYE1};
  text-align: left;
  outline: none;
  cursor: pointer;
  color: ${({ active }) => (active ? COLORS.BLUE : null)};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  box-sizing: border-box;

  &:hover,
  &:focus {
    color: ${COLORS.BLUE};
  }
`;

export default {
  Button: styled.button`
    ${ButtonStyles};
    padding: 16px 48px 16px 16px;
    white-space: normal;
    word-break: break-all;
  `,

  Arrow: styled.div<IsActiveProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    height: 100%;
    width: 12px;
    margin: 0 auto;
    background-image: url('/static/icons/dropdown-arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    transform: ${({ isActive }) => (isActive ? 'none' : 'rotate(180deg)')};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
    transition: all 0.2s ease-in-out;
  `,

  InnerList: styled.ul<IsActiveProps>`
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    width: 100%;
    border-bottom: 1px solid #e1e1e1;

    span {
      display: block;
      padding: 20px;
    }

    & > li:last-child > button {
      border: 0;
    }
  `,

  InnerItem: styled.li``,

  InnerButton: styled.button<InnerButtonProps>`
    ${ButtonStyles};
    padding: 16px 24px;
    white-space: normal;
    word-break: break-all;

    ${({ activeInnerItem }) =>
      activeInnerItem &&
      css`
        background-image: url('/static/icons/check-blue.svg');
        background-repeat: no-repeat;
        background-position: right 16px center;
      `}
  `,
};
