import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { pick, first, last, sortBy, omit } from 'lodash';
import { NotificationManager } from 'react-notifications';
import {
  filteredMaxValue,
  maxValue,
  required,
  landingDescriptionValidation,
} from 'shared/helpers/validations';
import { LangForm } from 'components/Form';
import {
  Checkbox,
  FormControl,
  FormDescription,
  Input,
  InputWithSelect,
  SmallFormDescription,
  Textarea,
} from 'components/FormControls';
import Button from 'components/Button';
import Modal from 'components/Modal';
import REGEXP from 'shared/constants/REGULAR_EXPRESSIONS';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { formatDashDate, formatRawDateTime } from 'shared/helpers/formatters/date';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import useEventData, { mapSubmitData } from 'shared/lib/useEventData';
import documentSources from 'shared/sources/document';
import api from 'shared/services/api';
import { WysiwigEditorPreview } from 'entities/wysiwyg-editor';
import { renderContentGroups, renderDocuments } from 'shared/helpers/optionsRenderers';
import { eventsSources } from 'entities/events';
import qna from 'shared/sources/reference/qna';
import localesSources from 'shared/sources/locales';
import Spinner from 'components/Spinner';
import StyledEventsForm from '../EventsForm/styles';
import EventsLayout from '../EventsLayout';

interface StepProps {
  // eslint-disable-next-line no-undef
  backButton: JSX.Element;
  isDesktop?: boolean;
  modalData: any;
}

const requiredFields = [
  'seo_event_name_with_preposition',
  'seo_short_name_with_preposition',
  'seo_place_accusative',
  'seo_place_prepositional',
  'seo_dates',
  'seo_categories_with_preposition',
];

const getEventDates = (data) => {
  if (data.children && data.children.length) {
    const childrens = sortBy(data.children, 'date_start');
    const firstEvent = first(childrens);
    const lastEvent = last(childrens);

    return `${formatRawDateTime(firstEvent.date_start, DATE_FORMATS.FULL_DATE)} - ${formatRawDateTime(
      lastEvent.date_finish,
      DATE_FORMATS.FULL_DATE,
    )}`;
  }

  if (data.date_start) {
    const isSameDay = formatDashDate(data.date_start) === formatDashDate(data.date_finish);

    if (isSameDay) {
      return formatRawDateTime(data.date_start, DATE_FORMATS.FULL_DATE);
    }
    return `${formatRawDateTime(data.date_start, DATE_FORMATS.FULL_DATE)} - ${formatRawDateTime(
      data.date_finish,
      DATE_FORMATS.FULL_DATE,
    )}`;
  }

  if (data.date) {
    return formatRawDateTime(data.date, DATE_FORMATS.FULL_DATE);
  }

  return undefined;
};

const FourthStep: React.FC<StepProps> = ({ backButton, isDesktop, modalData, isEdit }) => {
  const {
    event,
    state: { data, isSubmitting, isLoading },
    t,
    changeLanguage,
    updateTranslations,
    closeEventModal,
  } = useEventData({ modalData });

  const [document, setDocument] = useState({ documents: [], eventInfo: {} });
  const documentID = document.documents[0]?.id;
  const [locales, setLocales] = useState();

  useEffect(() => {
    api.get(localesSources.available).then((response) => {
      setLocales(response.data);
    });

    if (modalData.eventId) {
      const getEventDocument = async () => {
        try {
          const response = await api.get(documentSources.getDocument(modalData.eventId));

          const { documents, ...eventInfo } = response.data;

          setDocument((prevState) => ({
            ...prevState,
            documents,
            eventInfo,
          }));
        } catch (error) {
          NotificationManager.error('При загрузке документов произошла ошибка');
        }
      };

      getEventDocument();
    }
  }, [modalData.eventId]);

  const initialValues = {
    ...data,
    qna:
      data.qna?.map((item) => ({
        value: item.id,
        label: item.title || item.question,
      })) || [],
    description: data.description?.length ? data.description : undefined,
    seo_event_name_with_preposition: data.seo_event_name_with_preposition || `на ${data.title}`,
    seo_dates: data.seo_dates || getEventDates(data),
    seo_categories_with_preposition:
      data.seo_categories_with_preposition ||
      (data.event_category?.name && `на ${data.event_category.name}`) ||
      undefined,
    seo_base_names:
      data.seo_base_names ||
      data.persons?.map((person) => `${person.name || person.id.label} - ${person.position}`).join(', ') ||
      undefined,
    seo_place: data.seo_place || data.place?.name || undefined,

    event_document: document?.documents?.map((doc) => {
      return { id: doc.id, label: doc.title };
    }),
  };

  const handleFormSubmit = async (formData: any) => {
    if (formData.ru) {
      const newQnAIds = formData.ru.qna?.map((i) => i.value);
      const initialQnAIds = initialValues.qna?.map((i) => i.value);
      const initialQnA = new Set(initialQnAIds);
      const newQnA = new Set(newQnAIds);
      const qnaforDelete = initialQnAIds?.filter((x) => !newQnA.has(x));
      const qnaforUpdate = newQnAIds?.filter((x) => !initialQnA.has(x));

      if (qnaforUpdate?.length) {
        eventsSources.createQnA(data.event_source_id, qnaforUpdate);
      }
      if (qnaforDelete?.length) {
        eventsSources.deleteQnA(data.event_source_id, qnaforDelete);
      }

      const hasDocument = document.documents.length;
      const formDocument = formData.ru.event_document;

      if (hasDocument && formDocument?.value && documentID !== formDocument.value) {
        api.delete(documentSources.deleteDocument(formData.ru.id, documentID));

        if (formDocument.value) {
          documentSources.createDocument(formData.ru.id, formDocument.value);
        }
      }

      if (hasDocument && !formDocument) {
        api.delete(documentSources.deleteDocument(formData.ru.id, documentID));
      }

      if (!hasDocument && formDocument.value) {
        documentSources.createDocument(formData.ru.id, formDocument.value);
      }
    }

    // const localeFormData = locales?.find((localeData) => localeData?.code === formData?.lng);

    // const lngFormData = {
    //   [localeFormData?.code]: {
    //     ...formData?.[localeFormData.code],
    //     annotation: formData?.[localeFormData.code]?.annotation
    //       ? formData?.[localeFormData.code]?.annotation
    //       : null,
    //   },
    // };
    // await updateTranslations({
    //   ...(formData.ru && { ru: { id: event, page1: mapSubmitData(formData.ru) } }),
    //   ...omit(lngFormData, ['ru', 'lng', 'submit_action']),
    // }).then(closeEventModal);
  };

  if (isLoading) {
    return <Spinner center />;
  }

  return (
    <EventsLayout.Wrapper>
      <LangForm
        onSubmit={handleFormSubmit}
        onChangeLng={changeLanguage}
        initialValues={initialValues}
        render={({ handleSubmit, form, i18n, values, switcher }) => {
          const fieldAvailable = i18n.language === LANGUAGES.RU;

          const handleGenerate = () => {
            const pickedRequired = pick(values[i18n.language], requiredFields);
            const picked = pick(values[i18n.language], [
              ...requiredFields,
              'seo_subtitle',
              'seo_place',
              'event_category',
              'seo_base_names',
              'seo_duration',
            ]);

            Object.keys(pickedRequired).forEach((key) => {
              if (typeof pickedRequired[key] !== 'string') {
                delete pickedRequired[key];
              }
            });

            if (!pickedRequired || Object.keys(pickedRequired).length < requiredFields.length) {
              return form.submit();
            }

            const annotation = `${picked.seo_subtitle ? `<h3>${picked.seo_subtitle}</h3>\n` : ''}${
              picked.seo_place ? `<p>${picked.seo_place}</p>\n` : ''
            }${picked.seo_base_names ? `<p>${picked.seo_base_names}</p>\n` : ''}${
              picked.seo_duration ? `<p>${picked.seo_duration}</p>` : ''
            }
            `;

            const description = `
              <p>
                Мероприятие относится к категории
                «${picked.event_category.name.toLowerCase()}» и состоится {dates}
                ${picked.seo_place_prepositional}.
                На этой странице представлена афиша мероприятия.
                Продажа билетов онлайн на нашем официальном сайте осуществляется без посредников.
                Зачастую это единственная возможность достать билет ${picked.seo_categories_with_preposition}.
              </p>
              ${picked.event_category.seo_template_text || ''}
              <h2>Билеты ${picked.seo_event_name_with_preposition}</h2>
              <p>
                Portalbilet – удобный и надежный сервис для покупки и продажи билетов на
                мероприятия разного формата. Среднее время на покупку билета здесь начиная с выбора
                места завершая оформлением его в зрительном зале на ваше имя занимает не более двух минут.
                Билеты ${picked.seo_short_name_with_preposition} пользуются большой
                популярностью у зрителей.
                Спешите купить их, пока они есть в наличии.
              </p>
              <h2>Полезные ссылки</h2>
              <p>Подробнее о том, как вернуть, сдать или продать билет читайте в разделах:</p>
              <ul>
                <li><a href="/sell" target="_blank">Продать билет</a></li>
                <li><a href="/faq/broker" target="_blank">Брокерам</a></li>
                <li><a href="/faq/realizaciya-biletov" target="_blank">Организаторам</a></li>
              </ul>
            `;

            return form.batch(() => {
              form.change(`${i18n.language}.annotation`, annotation.replace(/—|–/gi, '&mdash;').trim());
              form.change(`${i18n.language}.description`, description);
            });
          };

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Container>
                {!isDesktop && <StyledEventsForm.Row>{switcher}</StyledEventsForm.Row>}
                {fieldAvailable && (
                  <>
                    <Modal.Section>
                      <EventsLayout.Row grid>
                        <FormDescription width={208}>
                          Завершение
                          <SmallFormDescription>
                            Ваше мероприятие создано и проходит проверку.
                            <br /> Добавьте подробное описание для более быстрого поиска вашего мероприятия
                          </SmallFormDescription>
                        </FormDescription>
                        <EventsLayout.Column>
                          <FormControl data-selenium="form-event-templates">
                            <Field name={`${i18n.language}.fill_by_template`} type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  {t('events:form.first_step.template.title')}
                                </Checkbox>
                              )}
                            </Field>
                          </FormControl>

                          <FormControl>
                            <Modal.Info>
                              <Modal.InfoItem>Название мероприятия: {data.title}</Modal.InfoItem>
                            </Modal.Info>
                          </FormControl>
                        </EventsLayout.Column>
                      </EventsLayout.Row>
                    </Modal.Section>

                    <Modal.Section>
                      <EventsLayout.Row grid>
                        <FormDescription width={208}>{t('events:form.eight_step.documents')}</FormDescription>
                        <EventsLayout.Column>
                          <>
                            <FormControl data-selenium="form-event-document">
                              <Field name={`${i18n.language}.event_document`}>
                                {({ input, meta }) => (
                                  <InputWithSelect
                                    isAsync
                                    label={t('events:form.eight_step.documents')}
                                    route={documentSources}
                                    optionsRenderer={renderDocuments}
                                    {...input}
                                    meta={meta}
                                  />
                                )}
                              </Field>
                            </FormControl>
                          </>
                          <FormControl>
                            <Modal.Info>
                              <Modal.InfoItem>Название мероприятия: {data.title}</Modal.InfoItem>
                            </Modal.Info>
                          </FormControl>
                        </EventsLayout.Column>
                      </EventsLayout.Row>
                    </Modal.Section>

                    <Modal.Section>
                      <EventsLayout.Row grid>
                        <FormDescription width={208}>{t('events:form.eight_step.qna')}</FormDescription>
                        <EventsLayout.Column>
                          <FormControl data-selenium="form-event-document">
                            <Field name={`${i18n.language}.qna`}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  isMulti
                                  searchQueryName="search_string"
                                  label="QnA"
                                  optionsRenderer={renderContentGroups}
                                  route={qna}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </EventsLayout.Column>
                      </EventsLayout.Row>
                    </Modal.Section>

                    {values[i18n.language]?.fill_by_template && (
                      <>
                        <Modal.Section>
                          <EventsLayout.Row grid>
                            <FormDescription width={208}>Аннотация</FormDescription>
                            <EventsLayout.Column>
                              <FormControl column data-selenium="form-event-templates-subtitle">
                                <Field name={`${i18n.language}.seo_subtitle`} validate={maxValue(90)}>
                                  {({ input, meta }) => (
                                    <Textarea
                                      label={t('events:form.first_step.template.subtitle')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <SmallFormDescription>
                                  Пример: Новогоднее шоу на льду с участием звезд фигурного катания
                                </SmallFormDescription>
                              </FormControl>
                              <FormControl column data-selenium="form-event-templates-seo_duration">
                                <Field name={`${i18n.language}.seo_duration`}>
                                  {({ input, meta }) => (
                                    <Input
                                      label={t('events:form.first_step.template.seo_duration')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <SmallFormDescription>
                                  Пример: Продолжительность – 2 часа, 1 антракт
                                </SmallFormDescription>
                              </FormControl>
                              <FormControl column data-selenium="form-event-templates-place_1">
                                <Field name={`${i18n.language}.seo_place`}>
                                  {({ input, meta }) => (
                                    <Input
                                      label={t('events:form.first_step.template.place_1')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <SmallFormDescription>
                                  Пояснения: <br />
                                  1. Нужно прописывать полным названием, но без конкретного адреса. <br />
                                  2. Если есть данные, нужно указать точное место проведения (малая / основная
                                  / большая сцена, название зала, номер павильона и т.д.), как во 2-м примере.
                                  <br />
                                  Примеры: <br />
                                  1. Ледовый дворец «Мегаспорт» <br />
                                  2. Театр «Ленком», основная сцена
                                </SmallFormDescription>
                              </FormControl>
                              <FormControl data-selenium="form-event-templates-base-names">
                                <Field name={`${i18n.language}.seo_base_names`} validate={maxValue(255)}>
                                  {({ input, meta }) => (
                                    <Textarea
                                      label={t('events:form.first_step.template.persons_names')}
                                      {...input}
                                      meta={meta}
                                      maxValue={255}
                                    />
                                  )}
                                </Field>
                              </FormControl>
                            </EventsLayout.Column>
                          </EventsLayout.Row>
                        </Modal.Section>
                        <Modal.Section>
                          <EventsLayout.Row grid>
                            <FormDescription width={208}>Описание</FormDescription>
                            <EventsLayout.Column>
                              <FormControl column data-selenium="form-event-templates-event-title">
                                <Field
                                  name={`${i18n.language}.seo_event_name_with_preposition`}
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <Input
                                      label={t('events:form.first_step.template.event_title')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <SmallFormDescription>
                                  С маленькой буквы в винительном падеже (ответ на вопрос куда?)
                                  <br />
                                  Пример: на балет на льду Татьяны Навки «Лебединое озеро»
                                </SmallFormDescription>
                              </FormControl>
                              <FormControl column data-selenium="form-event-templates-short-event-title">
                                <Field
                                  name={`${i18n.language}.seo_short_name_with_preposition`}
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <Input
                                      label={t('events:form.first_step.template.short_event_title')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <SmallFormDescription>
                                  3-4 слова в винительном падеже (ответ на вопрос куда?)
                                  <br />
                                  Пример: на «Лебединое озеро»
                                </SmallFormDescription>
                              </FormControl>
                              <FormControl column data-selenium="form-event-templates-place_2">
                                <Field name={`${i18n.language}.seo_place_accusative`} validate={required}>
                                  {({ input, meta }) => (
                                    <Input
                                      label={t('events:form.first_step.template.place_2')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <SmallFormDescription>
                                  С маленькой буквы в винительном падеже (ответ на вопрос куда?), БЕЗ указания
                                  точного места проведения
                                  <br />
                                  Пример: в ледовый дворец «Мегаспорт»
                                </SmallFormDescription>
                              </FormControl>
                              <FormControl column data-selenium="form-event-templates-place_3">
                                <Field name={`${i18n.language}.seo_place_prepositional`} validate={required}>
                                  {({ input, meta }) => (
                                    <Input
                                      label={t('events:form.first_step.template.place_3')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <SmallFormDescription>
                                  С маленькой буквы в предложном падеже (ответ на вопрос где?), БЕЗ указания
                                  точного места проведения
                                  <br />
                                  Пример: в ледовом дворце «Мегаспорт»
                                </SmallFormDescription>
                              </FormControl>
                              <FormControl data-selenium="form-event-templates-event_dates">
                                <Field name={`${i18n.language}.seo_dates`} validate={required}>
                                  {({ input, meta }) => (
                                    <Input
                                      label={t('events:form.first_step.template.event_dates')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                              </FormControl>
                              <FormControl column data-selenium="form-event-templates-category">
                                <Field
                                  name={`${i18n.language}.seo_categories_with_preposition`}
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <Input
                                      label={t('events:form.first_step.template.category')}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <SmallFormDescription>
                                  С маленькой буквы в винительном падеже (ответ на вопрос куда?)
                                  <br />
                                  Пример: на ледовое шоу
                                </SmallFormDescription>
                              </FormControl>
                              <FormControl data-selenium="form-event-templates-generate">
                                <Button type="button" onClick={handleGenerate}>
                                  {t('events:form.first_step.template.generate')}
                                </Button>
                              </FormControl>
                            </EventsLayout.Column>
                          </EventsLayout.Row>
                        </Modal.Section>
                      </>
                    )}
                  </>
                )}
                <StyledEventsForm.Row mobileOrder="3" data-selenium="form-event-annotation">
                  <Field
                    name={`${i18n.language}.annotation`}
                    validate={filteredMaxValue(500, REGEXP.REMOVE_HTML_TAGS)}
                  >
                    {({ input, meta }) => (
                      <WysiwigEditorPreview
                        id="event_1"
                        key={data.annotation || i18n.language}
                        label={t('events:form.first_step.annotation')}
                        helper={t('events:form.first_step.annotation_helper')}
                        input={input}
                        meta={meta}
                        maxValue={500}
                        withPreview={isEdit}
                      />
                    )}
                  </Field>
                </StyledEventsForm.Row>
                <StyledEventsForm.Row mobileOrder="3" data-selenium="form-event-description">
                  <Field name={`${i18n.language}.description`} validate={landingDescriptionValidation}>
                    {({ input, meta }) => (
                      <WysiwigEditorPreview
                        id="event_2"
                        key={data.description || i18n.language}
                        label={t('events:form.first_step.description')}
                        input={input}
                        meta={meta}
                        withPreview={isEdit}
                        useData
                        data={data?.description}
                      />
                    )}
                  </Field>
                </StyledEventsForm.Row>
              </Modal.Container>
              <Modal.Footer fullSize contentWidth="800px">
                {backButton}
                <Button type="submit" disabled={isSubmitting} data-selenium="form-event-button-done">
                  Готово
                </Button>
              </Modal.Footer>
            </form>
          );
        }}
      />
    </EventsLayout.Wrapper>
  );
};

export default FourthStep;
