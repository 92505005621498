import styled from 'styled-components';
import COLORS from 'shared/constants/COLORS';
import Button from '../../../../Button';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Overlay: styled.div`
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  `,

  Modal: styled.div`
    box-sizing: border-box;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    background: #fff;
    padding: 32px 16px 16px;
    border-radius: 8px 8px 0 0;
    overflow-y: auto;

    @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
      top: 50%;
      bottom: auto;
      max-width: 520px;
      padding: 32px 80px;
      border-radius: 8px;
      transform: translateY(-50%);
    }
  `,

  List: styled.ul`
    max-height: 150px;
    margin: 0 -40px;
    padding: 8px 40px;
    overflow: scroll;
    border-top: 1px solid ${COLORS.GRAYE1};
    border-bottom: 1px solid ${COLORS.GRAYE1};
  `,

  Item: styled.li`
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  `,

  Times: styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
      flex-wrap: nowrap;
    }
  `,

  Time: styled.div`
    flex-grow: 1;

    &:first-child {
      width: 100%;
      margin-bottom: 8px;

      @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
        width: 50%;
        margin: 0;
      }
    }

    @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
      width: 50%;
    }
  `,

  TimesButtons: styled.div`
    @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
      position: absolute;
      left: 100%;
    }
  `,

  Delimiter: styled.div`
    position: absolute;
    top: 16px;
    left: -9px;
    width: 8px;
    height: 52px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-right-color: transparent;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
      position: relative;
      top: auto;
      left: auto;
      border: none;
      border-top: 1px solid ${COLORS.GRAYE1};
      border-radius: 0;
      height: 0;
      margin: 19px 4px;
    }
  `,

  AddTimeButton: styled(Button)`
    margin-bottom: 8px;
  `,
};
