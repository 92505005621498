import { head } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { Field } from 'react-final-form';
import ANNOUNCEMENT_STEPS from 'shared/constants/ANNOUNCEMENT_STEPS';
import { composeValidators, required, eventWithHallAnnouncement } from 'shared/helpers/validations';
import Button from '../../../Button';
import Form from '../../../Form';
import { FormRow, EventSelector } from '../../../FormControls';
import Modal from '../../../Modal';
import Styled from './styles';

interface FirstStepProps {
  changeStep: (data: any) => void;
  selectedEventId?: number | string;
  announcementData?: any;
  event?: any;
}

const AnnouncementsFirstStep: React.FC<FirstStepProps> = ({
  changeStep,
  announcementData,
  event: selectedEvent,
  selectedEventId,
}) => {
  const handleFormSubmit = ({ event }) => {
    changeStep({ event, step: ANNOUNCEMENT_STEPS.SECOND });
  };
  const initialValues: any = {};

  if (announcementData) {
    const { event } = announcementData;
    const eventData = head(event?.results);
    initialValues.event = {
      data: eventData,
      value: eventData?.id,
      name: eventData?.title,
    };
  }
  0;

  if (selectedEvent) {
    initialValues.event = {
      data: selectedEvent,
      value: selectedEventId || selectedEvent.id,
      name: selectedEvent.title,
    };
  }

  const router = useRouter();

  return (
    <Styled.Form>
      <Form
        fluid
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormRow>
                <Field name="event" validate={composeValidators(required, eventWithHallAnnouncement)}>
                  {({ input, meta }) => <EventSelector query={router.query} input={input} meta={meta} />}
                </Field>
              </FormRow>
            </form>
          );
        }}
      />
    </Styled.Form>
  );
};

export default AnnouncementsFirstStep;
