import React from 'react';
import map from 'lodash/map';
import includes from 'lodash/includes';
import MobilePopup from '../MobilePopup';
import Dropdown from '../Dropdown';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Styled from './styles';
import { InputWithSelect, Checkbox } from '../FormControls';
import { OptionWithCheckbox } from '../FormControls/InputWithSelect';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

interface DropdownProps {
  roles: any[];
  label?: string;
  isMobile?: boolean;
  withSelect?: boolean;
  onChange?: Function;
  rolesDetail?: any[];
  counter?: string;
  emptyLabel?: string;
}

const RolesDropdown: React.FC<DropdownProps> = ({
  roles,
  label,
  isMobile,
  withSelect,
  onChange,
  rolesDetail,
  counter,
  emptyLabel,
  infoData,
}) => {
  const value = rolesDetail.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const title = `${label}: ${withSelect ? value.length : roles.length}`;
  let options;

  if (infoData) {
    options = roles.map((role) => {
      const ruLabel = role.info.find((info) => info.language_code === LANGUAGES.RU);
      return {
        value: role.id,
        label: ruLabel?.title,
      };
    });
  } else {
    options = roles.map((role) => ({
      value: role.id,
      label: role.name || role.title,
      ...(role.permissions && {
        subMenu: map(role.permissions, (permission) => ({
          value: permission.id,
          label: permission.name,
        })),
      }),
    }));
  }

  const renderPopupList = () => (
    <>
      {roles.map((role) => (
        <MobilePopup.ListItem>
          {withSelect && <Checkbox checked />}
          {role.name || role.title || 'ss'}
        </MobilePopup.ListItem>
      ))}
    </>
  );

  const handleChange = (currentValue, checked) => {
    const newData = value.filter((item) => item.value !== currentValue.value);

    if (!checked) {
      newData.push(currentValue);
    }

    onChange(newData);
  };

  const renderListWithSelect = () => {
    const list = options.map((option) => ({
      value: option.value,
      label: option.label,
      checked: includes(
        rolesDetail.map((item) => item.id),
        option.value,
      ),
    }));

    return list.map((item) => (
      <MobilePopup.ListItem
        flex
        onClick={(event) => {
          event.preventDefault();

          handleChange(item, item.checked);
        }}
        key={item.value}
      >
        <Checkbox checked={item.checked} />
        {item.label}
      </MobilePopup.ListItem>
    ));
  };

  if (!roles.length) return <>{emptyLabel}</>;

  return isMobile ? (
    <MobilePopup title={title} data={withSelect ? renderListWithSelect() : renderPopupList()} />
  ) : (
    <Styled.Dropdown>
      {withSelect ? (
        <InputWithSelect
          isMulti
          placeholder={`${value.length}`}
          value={value}
          isClearable={false}
          closeMenuOnSelect={false}
          isSearchable={false}
          options={options}
          hideSelectedOptions={false}
          withoutError
          onChange={onChange}
          controlShouldRenderValue={false}
          components={{ Option: OptionWithCheckbox }}
        />
      ) : (
        <Dropdown
          maxHeight="180px"
          withIndicator
          readonlyLabel=""
          withNested
          readonly={!counter}
          options={options}
        >
          {counter}
        </Dropdown>
      )}
    </Styled.Dropdown>
  );
};

RolesDropdown.defaultProps = {
  label: 'Роли',
  onChange: () => {},
  rolesDetail: [],
  emptyLabel: 'Отсутствует',
};

export default withCheckIsMobile(RolesDropdown);
