import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import React, { useState, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import { EVENTS } from 'shared/constants/EVENTS';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import LOCALES from 'shared/constants/LOCALES';
import api from 'shared/services/api';
import Emmiter from 'shared/services/Emmiter';
import localesSource from 'shared/sources/locales';
import { i18n, withTranslation } from '../../i18n';
import { Select } from '../FormControls';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import Styled from './styles';

interface LangFormProps {
  className?: string;
  onSuccess?: (value?: any) => any;
  onSubmit?: (data: any) => any;
  fluid?: boolean;
  withoutSpinner?: boolean;
  withoutSubmit?: boolean;
  inRow?: boolean;
  stretch?: boolean;
  ns?: string | string[];
  render: (value: any) => any;
  t: (translation: string) => string;
  onChangeLng?: (lng: string) => any;
  initialValues?: any;
  stashLanguages?: boolean;
  i18n: any;
  mutators?: any;
  withoutNotification?: boolean;
}

const LangForm: React.FC<LangFormProps> = (props) => {
  let mounted = true;
  const {
    t,
    className = null,
    onSuccess = null,
    fluid = false,
    withoutSpinner = false,
    withoutSubmit = false,
    onSubmit = () => {},
    inRow = false,
    stretch = false,
    onChangeLng = () => {},
    initialValues = {},
    stashLanguages = false,
    mutators = null,
    ...rest
  } = props;

  const [isLoading, toggleLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [lng, setLng] = useState(i18n.language);
  const [locales, setLocales] = useState([]);
  const [accumulatedData, setAccumulatedData] = useState({
    [lng]: initialValues,
  });

  useEffect(() => {
    if (mounted) {
      if (!isEmpty(initialValues)) {
        setAccumulatedData({
          [lng]: initialValues,
        });
      }
    }
  }, [initialValues]);

  const handleChange = ({ target: { value: newLng } }) => {
    setLng(newLng);
    onChangeLng(newLng);
  };

  // @ts-ignore
  useEffect(() => {
    Emmiter.on(EVENTS.CHANGE_LANGUAGE, handleChange);
    return () => Emmiter.removeListener(EVENTS.CHANGE_LANGUAGE, handleChange);
  }, []);

  useEffect(
    () => () => {
      mounted = false;
    },
    [],
  );

  useEffect(() => {
    api
      .get(localesSource.available)
      .then((response) => {
        const supportedLocales = response.data.map((item) => ({
          value: item.code,
          name: item.label,
        }));
        setLocales([...locales, ...supportedLocales]);
      })
      .catch((err) => new Error(err));
  }, []);

  const handleSubmit = async (data) => {
    try {
      const { stashLanguages } = props;
      if (stashLanguages && isEmpty(data.ru)) {
        throw new Error(t('forms:language_error'));
      }

      if (withoutSubmit) return null;

      if (mounted) toggleLoading(true);

      const results = await onSubmit({ ...data, lng });

      if (mounted) toggleLoading(false);

      if (props.withoutNotification) return null;

      if (results && results.error && results.error.response && results.error.response.data) {
        const { error } = results.error.response.data;

        if (!Array.isArray(error)) {
          const e = reduce(
            error,
            (acc, errors, field) => {
              acc[field] = errors;

              return acc;
            },
            {},
          );

          if (stashLanguages && i18n.language !== LANGUAGES.RU) {
            setLng(LANGUAGES.RU);
          }

          return e;
        }

        if (error) {
          NotificationManager.error(error.join(' '), t('forms:error'));
        }
      }

      if (onSuccess && results && !results.error) {
        onSuccess(results);
      }

      if (results && !results.error) {
        NotificationManager.success(t('forms:success'));
      }
    } catch (error) {
      if (withoutSubmit) return null;

      const errorMessage = get(error, 'request.response.error', error.message);

      if (errorMessage) {
        NotificationManager.error(
          Array.isArray(errorMessage) ? errorMessage.join(' ') : errorMessage,
          t('forms:error'),
        );
      }

      toggleLoading(false);

      return {};
    }

    return {};
  };
  const switcher = (
    <Styled.Switcher>
      {props.t('language_switcher')}
      <Tooltip
        text={stashLanguages && !valid && lng === LANGUAGES.RU && props.t('language_switcher_disabled')}
      >
        <Styled.SwitcherSelect>
          <Select
            options={locales}
            value={lng}
            onChange={(e) => {
              handleChange(e);
              Emmiter.emit(EVENTS.CHANGE_LANGUAGE, e);
            }}
            disabled={stashLanguages && lng === LANGUAGES.RU && !valid}
          />
        </Styled.SwitcherSelect>
      </Tooltip>
    </Styled.Switcher>
  );

  const renderForm = (args) => (
    <>
      {stashLanguages && (
        <FormSpy
          subscription={{ values: true, valid: true }}
          onChange={(formProps: any) => {
            if (stashLanguages) {
              Emmiter.emit(EVENTS.SWITCHER_LANGUAGE_ENABLE, lng === LANGUAGES.RU ? formProps.valid : true);
            }
            setValid(formProps.valid);
            setAccumulatedData({
              ...accumulatedData,
              ...formProps.value,
            });
          }}
        />
      )}
      {props.render({
        ...args,
        switcher,
        i18n: {
          language: lng,
        },
      })}
    </>
  );

  if (!stashLanguages) {
    Emmiter.emit(EVENTS.SWITCHER_LANGUAGE_ENABLE, true);
  }

  return (
    <Styled.FormWrapper inRow={inRow} fluid={fluid} className={className} stretch={stretch}>
      {isLoading && !withoutSpinner && (
        <Styled.SpinnerContainer>
          <Spinner />
        </Styled.SpinnerContainer>
      )}
      <Form
        {...rest}
        mutators={mutators}
        onSubmit={handleSubmit}
        render={renderForm}
        keepDirtyOnReinitialize
        initialValues={accumulatedData}
      />
    </Styled.FormWrapper>
  );
};

export default withTranslation(LOCALES.FORMS)(LangForm);
