import React from 'react';
import { Field } from 'react-final-form';
import { WysiwigEditorPreview } from 'entities/wysiwyg-editor';
import Form from '../../../Form';
import PageConstructorFooter from './PageConstructorFooter';
import Styled from './styles';

let formValues;

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  step: number;
  data: any;
  blockData: any;
  steps: string[];
}

const EditorStep: React.FC<StepProps> = ({ onSubmit, updateData, data, step, goBack, steps, blockData }) => {
  const handleOnSubmit = (newData) => {
    const requestData = data.map((item) => {
      if (item.uuid === steps[step]) {
        return {
          ...item,
          html: newData.html,
        };
      }

      return item;
    });
    return onSubmit({ blocks: requestData, submit_action: newData.submit_action });
  };

  const handleUpdateData = () => {
    const requestData = data.map((item) => {
      if (item.uuid === steps[step]) {
        return {
          ...item,
          html: formValues.html,
        };
      }

      return item;
    });

    return updateData(requestData);
  };

  return (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Настройка блока Визивик</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Form
          onSubmit={handleOnSubmit}
          initialValues={blockData}
          render={({ handleSubmit, form, values }) => {
            formValues = values;
            return (
              <form onSubmit={handleSubmit}>
                <Field name="html">
                  {({ input, meta }) => (
                    <WysiwigEditorPreview id="editor_step_1" input={input} meta={meta} withPreview />
                  )}
                </Field>

                <PageConstructorFooter
                  goBack={goBack}
                  form={form}
                  updateData={handleUpdateData}
                  withoutNextAction={steps.length - 1 === step}
                />
              </form>
            );
          }}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default EditorStep;
