import { createEffect, createEvent, createStore, sample, split } from 'effector';
import { NotificationManager } from 'react-notifications';
import {
  createHallInfoFx,
  deleteHallFx,
  fetchHallFx,
  fetchHallsFx,
  FormSubmitData,
  Hall,
  updateHallFx,
  updateHallInfoFx,
} from 'shared/api/reference/halls';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import PAGES from 'shared/constants/PAGES';
import { findTranslation } from 'shared/helpers/translations';
import { createToggler } from 'shared/lib/toggler';
import { $query } from '../../../model';

const toggler = createToggler();
const modalOpened = createEvent<{ id: string; type: string }>();
const modalClosed = createEvent();
const formSubmitted = createEvent<FormSubmitData>();
const deleteSubmitted = createEvent<{ id: string | number; title: string }>();
export const errorsModalClosed = createEvent();
const updatePlaceId = createEvent<FormSubmitData>();
const updateTranslations = createEvent<FormSubmitData>();
const $isSubmitting = createStore(false);

$isSubmitting.on(formSubmitted, () => true).on(toggler.close, () => false);

const $data = createStore<Hall | null>(null);
const $loading = createStore(true);
const $modalId = createStore<string | null>(null);
export const $deleteErrors = createStore<{ errors: string; name: string; page: string } | null>(null);

const successNotificationFx = createEffect(() => {
  NotificationManager.success('Успешно');
});

const failNotificationFx = createEffect(() => {
  NotificationManager.error('Ошибка');
});

export const updateHallModal = {
  toggler,
  formSubmitted,
  $data,
  $loading,
  $modalId,
  $isSubmitting,
  modalClosed,
  modalOpened,
  deleteSubmitted,
};

//load
sample({
  clock: modalOpened,
  target: fetchHallFx,
});

sample({
  clock: toggler.open,
  target: $modalId,
});

sample({
  clock: toggler.close,
  fn: () => true,
  target: $loading,
});

sample({
  clock: toggler.close,
  fn: () => null,
  target: $data,
});

sample({
  clock: fetchHallFx.done,
  filter: ({ params }) => params.type === 'update',
  fn: ({ result }) => {
    return result;
  },
  target: $data,
});

sample({
  clock: fetchHallFx.done,
  filter: ({ params }) => params.type === 'update',
  fn: () => false,
  target: $loading,
});

// update
split({
  source: formSubmitted,
  match: {
    ru: ({ lng }) => lng === LANGUAGES.RU,
    others: ({ lng }) => lng !== LANGUAGES.RU,
  },
  cases: {
    ru: [updatePlaceId, updateTranslations],
    others: updateTranslations,
  },
});

sample({
  source: {
    data: $data,
  },
  clock: updateTranslations,
  filter: ({ data }, { lng }) => {
    if (!data) return false;

    const translation = findTranslation(data.info, lng);

    return !translation;
  },
  fn: ({ data }, { lng, ...info }: FormSubmitData) => {
    const dataByLang = info[lng];

    return {
      id: data!.id,
      translation: {
        title: dataByLang.title,
        description: dataByLang.description,
        language_code: lng,
      },
    };
  },
  target: createHallInfoFx,
});

sample({
  source: {
    data: $data,
  },
  clock: formSubmitted,
  filter: ({ data }, { lng }) => {
    if (!data) return false;

    const translation = findTranslation(data.info, lng);

    return !!translation;
  },
  fn: ({ data }, { lng, ...info }: FormSubmitData) => {
    const dataByLang = info[lng];

    return {
      id: data!.id,
      translation: {
        language_code: lng,
        title: dataByLang.title,
        description: dataByLang.description,
      },
    };
  },
  target: updateHallInfoFx,
});

sample({
  source: {
    data: $data,
  },
  clock: updatePlaceId,
  fn: ({ data }, { ru }) => ({
    id: data!.id,
    place_id: ru.place_id.value,
  }),
  target: updateHallFx,
});

sample({
  clock: [updateHallFx, updateHallInfoFx, createHallInfoFx.done],
  target: toggler.close,
});
//delete
sample({
  clock: deleteSubmitted,
  target: deleteHallFx,
});

sample({
  clock: deleteHallFx.fail,
  fn: ({ error, params }) => {
    return {
      errors: error.message,
      name: params.title,
      page: PAGES.REFERENCE_HALLS,
    };
  },
  target: $deleteErrors,
});
sample({
  source: {
    query: $query,
  },
  clock: [updateHallInfoFx.done, deleteHallFx.done],
  fn: ({ query }) => query,
  target: fetchHallsFx,
});

sample({
  clock: deleteHallFx.done,
  target: toggler.close,
});

sample({
  clock: errorsModalClosed,
  fn: () => null,
  target: $deleteErrors,
});

// notifications
sample({
  clock: [updateHallInfoFx.done, createHallInfoFx.done, deleteHallFx.done],
  target: successNotificationFx,
});

sample({
  clock: [updateHallFx.fail, updateHallInfoFx.fail, createHallInfoFx.fail],
  target: failNotificationFx,
});
