import { createEvent, createStore, sample, createEffect } from 'effector';
import { NotificationManager } from 'react-notifications';
import * as locationsApi from 'shared/api/reference/locations';
import { FormSubmitData } from 'shared/api/types';
import PAGES from 'shared/constants/PAGES';
import { findTranslation } from 'shared/helpers/translations';
import { createToggler } from 'shared/lib/toggler';
import { $locationsQuery } from '../../../model';

export const toggler = createToggler();
export const formSubmitted = createEvent<object>();
export const modalOpened = createEvent<string>();
export const showedSuccesNotification = createEffect(() => {
  NotificationManager.success('Успешно');
});
export const showedErrorNotification = createEffect(() => {
  NotificationManager.error('Ошибка');
});
export const deleteLocationSubmitted = createEvent<number>();

export const errorsModalClosed = createEvent();
export const $location = createStore(null);
export const $modalId = createStore<string | null>(null);
export const $deleteLocationErrors = createStore(null);
export const $locationLoading = createStore(true);
export const $isSubmitting = createStore(false);

$isSubmitting.on(formSubmitted, () => true).on(toggler.close, () => false);

sample({
  clock: locationsApi.fetchLocationFx.done,
  fn: ({ result }) => {
    return result;
  },
  target: $location,
});

sample({
  clock: modalOpened,
  target: locationsApi.fetchLocationFx,
});

sample({
  clock: toggler.open,
  target: $modalId,
});

sample({
  clock: modalOpened,
  fn: () => true,
  target: $locationLoading,
});

sample({
  clock: locationsApi.fetchLocationFx.done,
  fn: () => false,
  target: $locationLoading,
});

// update

sample({
  source: {
    location: $location,
  },
  clock: formSubmitted,
  filter: ({ location }, { lng }) => {
    if (!location) return false;

    const translation = findTranslation(location.info, lng);

    return !translation;
  },
  fn: ({ location }, { lng, ...info }: FormSubmitData) => ({
    id: location!.id,
    translation: {
      ...info[lng],
      language_code: lng,
    },
  }),
  target: locationsApi.createLocationInfoFx,
});

sample({
  source: {
    location: $location,
  },
  clock: formSubmitted,
  filter: ({ location }, { lng }) => {
    if (!location) return false;

    const translation = findTranslation(location.info, lng);

    return !!translation;
  },
  fn: ({ location }, { lng, ...info }) => ({
    id: location!.id,
    translation: {
      ...info[lng],
      language_code: lng,
    },
  }),
  target: locationsApi.updateLocationInfoFx,
});

sample({
  source: {
    location: $location,
  },
  clock: formSubmitted,
  filter: ({ location }, { lng }) => {
    if (!location || location === null || lng !== 'ru') return false;

    const translation = findTranslation(location.info, lng);
    return Boolean(translation && translation.language_code === 'ru');
  },
  fn: ({ location }, { lng, ...info }) => ({
    id: location!.id,
    translation: {
      ...info[lng],
      language_code: lng,
    },
  }),
  target: locationsApi.updateLocationFx,
});

sample({
  clock: locationsApi.updateLocationInfoFx.done,
  target: showedSuccesNotification,
});

sample({
  clock: locationsApi.createLocationInfoFx.done,
  target: showedSuccesNotification,
});

sample({
  clock: locationsApi.updateLocationInfoFx.fail,
  target: showedErrorNotification,
});

sample({
  clock: locationsApi.createLocationInfoFx.fail,
  target: showedErrorNotification,
});

sample({
  clock: [
    locationsApi.createLocationInfoFx.done,
    locationsApi.updateLocationInfoFx.done,
    locationsApi.createLocationInfoFx.fail,
    locationsApi.updateLocationInfoFx.fail,
  ],
  target: toggler.close,
});

sample({
  source: { locationsQuery: $locationsQuery },
  clock: locationsApi.updateLocationFx.done,
  fn: ({ locationsQuery }) => locationsQuery,
  target: locationsApi.fetchLocationsFx,
});

//delete

sample({
  clock: deleteLocationSubmitted,
  target: locationsApi.deleteLocationFx,
});

sample({
  clock: locationsApi.deleteLocationFx.fail,
  fn: ({ error }) => {
    return {
      errors: error.message,
      name: $location?.getState()?.code,
      page: PAGES.REFERENCE_LOCATIONS,
    };
  },
  target: $deleteLocationErrors,
});

sample({
  clock: locationsApi.fetchLocationFx.done,
  fn: () => {
    return null;
  },
  target: $deleteLocationErrors,
});

sample({
  clock: locationsApi.deleteLocationFx.done,
  target: showedSuccesNotification,
});

sample({
  clock: locationsApi.deleteLocationFx.fail,
  target: showedErrorNotification,
});

sample({
  source: { locationsQuery: $locationsQuery },
  clock: locationsApi.deleteLocationFx.done,
  fn: ({ locationsQuery }) => locationsQuery,
  target: locationsApi.fetchLocationsFx,
});

sample({
  clock: locationsApi.deleteLocationFx.done,
  target: toggler.close,
});

sample({
  clock: errorsModalClosed,
  fn: () => null,
  target: $deleteLocationErrors,
});
