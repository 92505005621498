import arrayMutators from 'final-form-arrays';
import get from 'lodash/get';
import { i18n } from 'next-i18next';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { referencePersonsSources } from 'shared/api/reference/persons';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderReferencePersons } from 'shared/helpers/optionsRenderers';
import { required } from 'shared/helpers/validations';
import useEventData from 'shared/lib/useEventData';
import TrashIcon from '../../../../../static/icons/trash.svg';
import Button from '../../../Button';
import Form from '../../../Form';
import { FormDescription, Input, InputWithSelect, SmallFormDescription } from '../../../FormControls';
import Modal from '../../../Modal';
import Spinner from '../../../Spinner';
import Tooltip from '../../../Tooltip';
import EventsLayout from '../EventsLayout';
import Styled from './styles';

let finalForm = null;

interface StepProps {
  goNext: () => void;
  backButton: JSX.Element;
  modalData: any;
}

const SevenStep: React.FC<StepProps> = ({ goNext, modalData, backButton }) => {
  const { state, update } = useEventData({ modalData });
  const { isLoading, data, isSubmitting } = state;

  if (isLoading) {
    return <Spinner center />;
  }

  const handleChangeSelect = (value, params) => {
    const personPosition = value?.data?.info.find(
      (personInfo: { language_code: string }) => personInfo.language_code === i18n?.language,
    )?.position;

    const positionValue = value ? personPosition || value.data.position : '';

    finalForm.change(params.name.replace('id', 'position'), positionValue);
  };

  const initialValues = {
    ...data,
    ...(data.persons && {
      persons: data.persons.map((person) => {
        if (Number(person.id)) {
          return {
            id: {
              value: person.id || get(person, 'id.value'),
              label: person.name || get(person, 'id.label'),
            },
            position: person.position,
          };
        }

        return person;
      }),
    }),
  };

  const handleFormSubmit = (formValues: any) => {
    const { persons } = formValues;

    update({
      page4: {
        persons: persons ? persons.map((item) => ({ id: item.id.value, position: item.position })) : [],
      },
    }).then((response) => {
      if (!response.error) goNext();
    });
  };

  return (
    <EventsLayout.Wrapper>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, form }) => {
          finalForm = form;

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Container>
                <Modal.Section>
                  <EventsLayout.Row grid>
                    <FormDescription width={208}>
                      Персоналии
                      <SmallFormDescription>Добавьте персоналии к мероприятию</SmallFormDescription>
                    </FormDescription>
                    <EventsLayout.Column>
                      <FieldArray name="persons">
                        {({ fields }) => (
                          <>
                            {fields.map((name, index) => (
                              <div key={name}>
                                <Styled.Row data-selenium="form-event-person">
                                  <Field name={`${name}.id`} validate={required}>
                                    {({ input, meta }) => (
                                      <InputWithSelect
                                        isAsync
                                        label="Персоналия"
                                        route={referencePersonsSources}
                                        query={{ language_code: i18n?.language || LANGUAGES.RU }}
                                        optionsRenderer={renderReferencePersons}
                                        searchQueryName="search_string"
                                        meta={meta}
                                        {...input}
                                        onSelect={handleChangeSelect}
                                      />
                                    )}
                                  </Field>
                                  <Styled.Trash>
                                    <Tooltip text="Удалить">
                                      <Button
                                        type="button"
                                        onlyIcon
                                        transparent
                                        opacity={0.4}
                                        onClick={() => {
                                          fields.remove(index);
                                        }}
                                        data-selenium="form-event-button-delete-person"
                                      >
                                        <TrashIcon />
                                      </Button>
                                    </Tooltip>
                                  </Styled.Trash>
                                </Styled.Row>
                                <Styled.Row data-selenium="form-event-person-role">
                                  <Field name={`${name}.position`}>
                                    {({ input, meta }) => <Input label="Роль" {...input} meta={meta} />}
                                  </Field>
                                </Styled.Row>
                              </div>
                            ))}
                            <Styled.AddMore>
                              <Button
                                type="button"
                                plain
                                onClick={() => fields.push({ id: '', position: '' })}
                                data-selenium="form-event-button-add-person"
                              >
                                Добавить еще
                              </Button>
                            </Styled.AddMore>
                          </>
                        )}
                      </FieldArray>
                    </EventsLayout.Column>
                  </EventsLayout.Row>
                </Modal.Section>
              </Modal.Container>
              <Modal.Footer fullSize contentWidth="800px">
                {backButton}
                <Button
                  type="button"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  data-selenium="create-event-button-continue"
                >
                  Продолжить
                </Button>
              </Modal.Footer>
            </form>
          );
        }}
      />
    </EventsLayout.Wrapper>
  );
};

export default SevenStep;
