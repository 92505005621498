import config from 'config/config';
import { createEffect } from 'effector';
import { isEmpty } from 'lodash';
import { i18n } from 'next-i18next';
import api from '../../services/api';

const route = `${config.COMMON_API_URL}/admin/v1/locations`;

interface UpdateLocationFxProps {
  id: number;
  translation: { slug: string; name: string; country_id: { value: number }; code: string };
}
interface UpdateLocationInfoFxProps {
  id: number;
  translation: { language_code: number; name: string };
}

interface CreateLocationInfoFx {
  id: number;
  translation: { language_code: number; name: string };
}

export default {
  root: `${config.COMMON_API_URL}/admin/v1/locations`,
};

export const fetchLocationsFx = createEffect(async (query) => {
  const { country, search, ...rest } = query;
  const response = await api.get(`${config.COMMON_API_URL}/admin/v1/locations`, {
    params: {
      ...rest,
      ...(country && { country_id_in: country }),
      search_string: search,
      language_code: i18n?.language,
      limit: 250,
    },
  });

  return response.data;
});

export const fetchLocationFx = createEffect(async (location_id: number) => {
  const response = await api.get(`${route}/${location_id}`);

  return response.data;
});

export const updateLocationFx = createEffect(async ({ id, translation }: UpdateLocationFxProps) => {
  const response = await api.patch(`${route}/${id}`, {
    slug: translation.slug,
    code: translation.code,
    country_id: translation?.country_id?.value,
  });

  return response;
});

export const updateSubLocationFx = createEffect(
  async ({ citiesItems, dataRu, deletedLocations }: UpdateLocationFxProps) => {
    const promises = [];
    if (!isEmpty(deletedLocations)) {
      for (const deletedItem of deletedLocations) {
        promises.push(api.patch(`${route}/${deletedItem.id}`, { location_group_id: null }));
      }
      await Promise.all(promises);
    }

    for (const item of citiesItems) {
      promises.push(api.patch(`${route}/${item.id}`, { location_group_id: dataRu.id }));
    }
    const responses = await Promise.all(promises);

    return responses;
  },
);

export const updateLocationInfoFx = createEffect(async ({ id, translation }: UpdateLocationInfoFxProps) => {
  const response = await api.patch(`${route}/${id}/info/${translation?.language_code}`, {
    title: translation.name,
  });

  return response;
});

export const createLocationInfoFx = createEffect(async ({ id, translation }: CreateLocationInfoFx) => {
  const response = await api.post(`${route}/${id}/info`, {
    title: translation.name,
    language_code: translation?.language_code,
  });

  return response;
});

export const deleteLocationFx = createEffect(async (id: string) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});

export const createLocationFx = createEffect(async (data: any) => {
  const response = await api.post(`${route}`, {
    slug: data.dataRu.slug,
    code: data.dataRu.code,
    country_id: data?.dataRu.country_id?.value,
  });

  return response;
});

export const createLocationInfosFx = createEffect(
  async ({ id, translations }: { id: string; translations: [] }) => {
    const requests = translations.map(async (translation: object) => {
      const response = await api.post(`${route}/${id}/info`, translation);

      return response;
    });

    await Promise.all(requests);
  },
);
