import { useUnit } from 'effector-react';
import React from 'react';
import DataPreview from '../../../../../DataPreview';
import {
  $hallLayoutsFailed,
  $hallLayouts,
  $hallLayoutsLoading,
  hallLayoutSelected,
  $hallLayoutId,
  $selectedHallLayoutId,
} from '../../model';
import Styled from './styles';

interface HallLayoutsProps {
  isActive: boolean;
  selectedInnerItem?: number;
}

export const HallLayouts: React.FC<HallLayoutsProps> = ({ isActive }) => {
  const {
    hallLayouts,
    hallLayoutId,
    selectedHallLayoutId,
    hallLayoutSelected: onHallLayoutSelected,
    hallLayoutsLoading: isLoading,
    hallLayoutsFailed: isFail,
  } = useUnit({
    hallLayoutSelected,
    selectedHallLayoutId: $selectedHallLayoutId,
    hallLayoutId: $hallLayoutId,
    hallLayouts: $hallLayouts,
    hallLayoutsFailed: $hallLayoutsFailed,
    hallLayoutsLoading: $hallLayoutsLoading,
  });

  return (
    <Styled.InnerList isActive={isActive}>
      <DataPreview
        length={hallLayouts.length}
        state={{
          isLoading,
          isFail,
        }}
      >
        {hallLayouts.map((item: any) => (
          <Styled.InnerItem key={item.id}>
            <Styled.InnerButton
              onClick={(event) => {
                event.stopPropagation();
                onHallLayoutSelected({ id: item.id, name: item.name });
              }}
              active={selectedHallLayoutId === item.id}
              activeInnerItem={hallLayoutId === item.id}
              data-selenium={item.name}
            >
              {item.name}
            </Styled.InnerButton>
          </Styled.InnerItem>
        ))}
      </DataPreview>
    </Styled.InnerList>
  );
};
