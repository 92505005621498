import React, { useState, useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import { Router, withRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import useOnclickOutside from 'react-cool-onclickoutside';
import FormQueryParams from 'shared/lib/FormQueryParams';
import PreloadFilterData from '../PreloadFilterData';
import Form from '../Form';
import Button from '../Button';
import { Input } from '../FormControls';
import SearchIcon from '../../../static/icons/search.svg';
import CancelAltIcon from '../../../static/icons/cancel-alt.svg';
import SearchInputIcon from '../../../static/icons/search-input.svg';
import Styled from './styles';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';

let form;
let formValues;
let searchMeta;

interface SmallSearchProps {
  children?: any;
  filters?: string[];
  mobileFluid?: boolean;
  secondary?: boolean;
  isDesktop?: boolean;
  switchToTablet?: boolean;
  router: Router;
  clearRemoved?: boolean;
  disabled?: boolean;
  queryName?: string;
}

const SmallSearch = ({
  filters = [],
  children,
  mobileFluid,
  secondary,
  isDesktop = true,
  clearRemoved,
  router,
  switchToTablet,
  disabled,
  queryName = 'search',
}: SmallSearchProps) => {
  const pages = [
    '/landings/tags',
    '/landings/templates',
    '/reference/places',
    '/reference/persons',
    '/reference/halls',
    '/reference/event-categories',
    '/reference/qna',
    '/landings/template-sets',
    '/markup',
    '/filters',
  ];
  const pathname = router.asPath.includes('?')
    ? router.asPath.slice(0, router.asPath.indexOf('?'))
    : router.asPath;

  if (pages.includes(pathname)) {
    queryName = 'search_string';
  }

  const [isOpen, setIsOpen] = useState(!!router.query[queryName]);
  const searchRef = useRef();
  useEffect(() => {
    if (secondary) {
      if (!isDesktop || switchToTablet) {
        return setIsOpen(!!router.query[queryName]);
      }

      return setIsOpen(true);
    }

    return setIsOpen(!!router.query[queryName]);
  }, [isDesktop, switchToTablet]);

  useEffect(() => {
    if (!router.query[queryName] && searchMeta && !searchMeta.active) {
      setIsOpen(false);
      form.change(queryName, '');
    }
  }, [router.query]);

  // @ts-ignore
  useOnclickOutside(searchRef, () => {
    if (!formValues[queryName]) {
      setIsOpen(false);
    }
  });

  const openSearch = () => {
    setIsOpen(true);
  };

  const clearAndClose = () => {
    form.change(queryName, '');
    setIsOpen(false);
  };

  const closeSearch = () => {
    if (secondary) {
      if (!isDesktop || switchToTablet) {
        return clearAndClose();
      }

      return form.change(queryName, '');
    }

    return clearAndClose();
  };

  const clearButton = (
    <Styled.Close type="button" onClick={closeSearch}>
      <CancelAltIcon />
    </Styled.Close>
  );

  return (
    <>
      <Styled.Search secondary={secondary} switchToTablet={switchToTablet} ref={searchRef}>
        <Styled.Wrapper
          className={isOpen && 'small-search--active'}
          mobileFluid={mobileFluid}
          isOpen={isOpen}
          secondary={secondary}
        >
          <PreloadFilterData filters={[queryName, ...filters]}>
            {({ initialValues }) => (
              <Form
                withoutSubmit
                inRow
                initialValues={initialValues}
                render={({ form: handleForm, handleSubmit, values }) => {
                  form = handleForm;
                  formValues = values;

                  if (!values[queryName]) {
                    form.change(queryName, '');
                  }

                  return (
                    <form onSubmit={handleSubmit}>
                      <FormQueryParams clearRemoved={clearRemoved} withoutPagination />

                      {children}

                      <Styled.Container isOpen={isOpen}>
                        <Field key={isOpen ? 'opened' : 'closed'} name={queryName}>
                          {({ input, meta }) => {
                            searchMeta = meta;
                            return (
                              <Input
                                isSmall={secondary && (!isDesktop || switchToTablet)}
                                key={isOpen ? 'opened' : 'closed'}
                                autoFocus={isOpen && !secondary}
                                icon={<SearchInputIcon />}
                                {...input}
                                meta={meta}
                              />
                            );
                          }}
                        </Field>
                        {secondary ? (
                          <>{(!isDesktop || switchToTablet || !isEmpty(router.query)) && clearButton}</>
                        ) : (
                          clearButton
                        )}
                      </Styled.Container>
                    </form>
                  );
                }}
              />
            )}
          </PreloadFilterData>
        </Styled.Wrapper>
        <Styled.Button>
          {!isOpen && (
            <Button
              type="button"
              onlyIcon
              transparent
              onClick={openSearch}
              disabled={disabled}
              data-selenium="search-button"
            >
              <SearchIcon />
            </Button>
          )}
        </Styled.Button>
      </Styled.Search>
      {secondary && <Styled.Overlay isOpen={isOpen} />}
    </>
  );
};

export default withCheckIsMobile(withRouter(SmallSearch));
