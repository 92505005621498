import Button from 'components/Button';
import Modal from 'components/Modal';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import keys from 'lodash/keys';
import map from 'lodash/map';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { eventsSources } from 'entities/events';
import { isNextYear } from 'shared/helpers/date';
import {
  formatDateToDayPreview,
  formatDateToFullDatePreview,
  formatTimeByTime,
  formatDateMonthString,
} from 'shared/helpers/formatters/date';
import { renderAnnouncementEvents } from 'shared/helpers/optionsRenderers';
import { Select, RadioGroup, InputWithSelect, Checkbox } from '..';
import Placeholder from '../../Placeholder';
import { OptionWithEventInfo } from '../InputWithSelect';
import Styled from './styles';

type EventSelectorProps = typeof EventSelector.defaultProps & {
  disabled?: boolean;
  meta: any;
  input: any;
  query: any;
  withAllDatesSelector?: boolean;
  allDaysSelected?: boolean;
  optionsRenderer: (options: any) => any;
  onChange: (value: any) => void;
  t?: (value: string, options?: { [key: string]: any }) => string;
  menuPlacement?: string;
  label?: string;
};

class EventSelector extends Component<EventSelectorProps, any> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    disabled: false,
    withAllDatesSelector: false,
    withAllDatesSelectorName: 'all_dates_selected',
    allDaysSelected: false,
    query: {},
    onChange: () => {},
    optionsRenderer: renderAnnouncementEvents,
    menuPlacement: 'bottom',
    label: 'Мероприятие',
  };

  allDatesSelectedInput: any;

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      data: null,
      selectedDay: null,
      selectedTime: null,
      isLoading: true,
      allDaysSelected: props.allDaysSelected,
      t: props.t,
    };
  }

  componentDidMount() {
    const { query } = this.props;
    const { value, data } = this.props.input.value;
    const children = data?.children;

    if (value) {
      if (data && data.dates) {
        const selectedTime = Number(query.event);
        let state: any = {
          id: value,
          data: {
            label: data.title,
            value,
            data: { ...data, children },
          },
          selectedDay: find(children, { id: +query.event })?.date_start,
          isLoading: false,
          selectedTime,
        };

        if (children && children.length >= 5) {
          state = { ...state, data: { ...state.data, withManyChildrens: true } };
        }

        this.setState(state);
      }
    }

    return this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate(_, prevState) {
    const { id, data } = this.state;

    if (!data) {
      return this.props.input.onChange(null);
    }

    if (id !== prevState.id) {
      return this.props.input.onChange({ ...data, value: id });
    }

    return null;
  }

  handleOnChange = (data) => {
    this.props.onChange(data);
    if (!data) {
      return this.setState({
        id: null,
        data: null,
        selectedDay: null,
      });
    }

    const children = get(data, 'data.children');
    const newData = {
      ...data,
      data: {
        ...data.data,
        ...(children && children.length && { children: sortBy(children, 'date_start') }),
      },
    };

    const newDataChildren = get(newData, 'data.children');
    const { withAllDatesSelector } = this.props;
    const { allDaysSelected } = this.state;

    if (withAllDatesSelector && children && children.length) {
      return this.setState({
        data: newData,
        // @ts-ignore
        id: allDaysSelected ? data.value : head(newDataChildren).id,
        // @ts-ignore
        selectedDay: head(newDataChildren).date_start,
        // @ts-ignore
        selectedTime: head(newDataChildren).id,
      });
    }

    if (children && children.length < 5) {
      return this.setState({
        data: newData,
        id: data.value,
      });
    }

    if (children && children.length >= 5) {
      return this.setState({
        data: newData,
        // @ts-ignore
        id: head(newDataChildren).id,
        // @ts-ignore
        selectedDay: head(newDataChildren).date_start,
      });
    }

    return this.setState({
      data: newData,
      id: data.data.id,
    });
  };

  handleChangeAllDates = (event) => {
    const { checked } = event.target;
    const { data } = this.state;

    this.allDatesSelectedInput.onChange(event);

    this.setState(
      {
        id: Number(data.data.id),
        allDaysSelected: checked,
      },
      () => {
        if (checked) {
          return this.props.input.onChange({ ...data, value: Number(data.data.id) });
        }

        return this.handleOnChange({
          ...data,
          // @ts-ignore
          value: head(data.data.children).id,
        });
      },
    );
  };

  selectDay = ({ target: { value } }) => {
    const childrens = get(this.state, 'data.data.children');
    const days = groupBy(childrens, 'date_start');

    this.setState({
      selectedDay: value,
      id: head(days[value])?.id,
    });
  };

  selectTime = ({ target: { value } }) => {
    this.setState({
      id: Number(value),
      selectedTime: Number(value),
    });
  };

  render() {
    const {
      input,
      meta,
      disabled,
      query,
      withAllDatesSelector,
      withAllDatesSelectorName,
      menuPlacement,
      label,
    } = this.props;
    const { selectedDay, selectedTime, id, data, isLoading, allDaysSelected } = this.state;

    const children = get(this.state, 'data.data.children');
    const days = groupBy(children, 'date_start');
    const daysCount = keys(days).length;
    const dayTimes = days[selectedDay];

    if (isLoading) {
      return (
        <Styled.Container>
          <Placeholder />
        </Styled.Container>
      );
    }

    return (
      <>
        {' '}
        <Styled.Container>
          <InputWithSelect
            isAsync
            label={label}
            route={eventsSources}
            query={{
              date_type: 'active',
              ...query,
            }}
            {...omit(input, 'onChange')}
            searchQueryName="search_string"
            onChange={this.handleOnChange}
            value={
              data
                ? {
                    value: data.value,
                    label: data.label,
                    data: data.data,
                  }
                : ''
            }
            meta={meta}
            optionsRenderer={renderAnnouncementEvents}
            components={{ Option: OptionWithEventInfo }}
            isDisabled={disabled}
            menuPlacement={menuPlacement}
            dataSelenium="choice-event-ads"
          />

          {children?.length > 1 && (
            <>
              <Styled.Row>
                {daysCount < 5 ? (
                  <Styled.RowField>
                    <RadioGroup
                      onChange={this.selectDay}
                      value={selectedDay}
                      name="day"
                      buttons={map(days, (_, value) => ({
                        value,
                        name: formatDateToDayPreview(value),
                        id: value,
                        tooltip: isNextYear(value) ? formatDateToFullDatePreview(value) : null,
                      }))}
                      small
                      withoutOverflow
                      disabled={(!withAllDatesSelector && daysCount === 1) || allDaysSelected}
                    />
                  </Styled.RowField>
                ) : (
                  <Styled.RowField>
                    <Select
                      label="Дата"
                      onChange={this.selectDay}
                      value={selectedDay}
                      options={map(days, (_, index) => ({
                        value: index,
                        name: formatDateMonthString(index),
                        id: index,
                      }))}
                      disabled={disabled || allDaysSelected}
                    />
                  </Styled.RowField>
                )}

                {selectedDay && dayTimes.length < 5 && (
                  <Styled.RowField>
                    <RadioGroup
                      onChange={this.selectTime}
                      value={selectedTime}
                      name="times"
                      buttons={days[selectedDay].map((item) => ({
                        value: item.id,
                        name: formatTimeByTime(item.time_start),
                        id: item.id,
                      }))}
                      small
                      withoutOverflow
                      disabled={dayTimes.length === 1 || disabled || allDaysSelected}
                    />
                  </Styled.RowField>
                )}

                {selectedDay && dayTimes?.length >= 3 && (
                  <Styled.RowField>
                    <Select
                      label="Время"
                      onChange={this.selectTime}
                      value={Number(id)}
                      options={days[selectedDay].map((item) => ({
                        value: item.id,
                        name: formatTimeByTime(item.time_start),
                        id: item.id,
                      }))}
                      disabled={disabled || allDaysSelected}
                    />
                  </Styled.RowField>
                )}
              </Styled.Row>
              {withAllDatesSelector && (
                <Field name={withAllDatesSelectorName} type="checkbox">
                  {({ input: inputSelector, meta: metaSelector }) => {
                    this.allDatesSelectedInput = inputSelector;
                    return (
                      <Styled.AllDatesCheckbox>
                        <Checkbox
                          subText="Выбрать все даты"
                          variant="round"
                          meta={metaSelector}
                          {...inputSelector}
                          onChange={this.handleChangeAllDates}
                        />
                      </Styled.AllDatesCheckbox>
                    );
                  }}
                </Field>
              )}
            </>
          )}
        </Styled.Container>
        <Modal.Footer fullSize>
          <Button
            type="submit"
            data-selenium="ads-continue-button"
            disabled={dayTimes?.length >= 2 && !selectedTime}
          >
            Продолжить
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export default EventSelector;
