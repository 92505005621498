import React from 'react';
import { Field } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { FormRow, TimePicker } from '../../../../FormControls';
import Styled from './styled';
import { required } from 'shared/helpers/validations';
import Tooltip from '../../../../Tooltip';
import Button from '../../../../Button';
import TrashIcon from '../../../../../../static/icons/trash.svg';

interface RowProps {
  name: string;
  index: number;
  fields: FieldArrayRenderProps<any, any>['fields'];
}

const TimeRow: React.FC<RowProps> = ({ name, fields, index }) => (
  <FormRow small>
    <Styled.Times>
      <Styled.Time>
        <Field name={`${name}.time_start`} validate={required}>
          {({ input, meta }) => <TimePicker label="Время начала" input={input} meta={meta} />}
        </Field>
      </Styled.Time>
      <Styled.Delimiter />
      <Styled.Time>
        <Field name={`${name}.time_end`} validate={required}>
          {({ input, meta }) => <TimePicker label="Время конца" input={input} meta={meta} />}
        </Field>
      </Styled.Time>
      {index !== 0 && (
        <Styled.TimesButtons>
          <Tooltip text="Удалить">
            <Button type="button" onlyIcon transparent opacity={0.4} onClick={() => fields.remove(index)}>
              <TrashIcon />
            </Button>
          </Tooltip>
        </Styled.TimesButtons>
      )}
    </Styled.Times>
  </FormRow>
);

export default TimeRow;
