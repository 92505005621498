import Button from 'components/Button';
import { LangForm } from 'components/Form';
import {
  Input,
  InputWithSelect,
  Textarea,
  NumberInput,
  FormDescription,
  FormControl,
  Checkbox,
  FormRow,
  LangSwitcher,
} from 'components/FormControls';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
import React from 'react';
import { Field } from 'react-final-form';
import { WysiwigEditorPreview } from 'entities/wysiwyg-editor';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderContentGroups } from 'shared/helpers/optionsRenderers';
import {
  maxValue,
  required,
  composeValidators,
  maxNumberValue,
  landingDescriptionValidation,
} from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import landingsTagsSources from 'shared/sources/landings/landingsTags';
import { ModalFunctions } from '../../../../../interfaces/modal';
import CloseIcon from '../../../../../static/icons/close.svg';
import LandingsModalLayout from '../landingsModalLayout';

interface FormProps {
  title: string;
  closeModal: ModalFunctions['closeModal'];
  isMobile: boolean;
  isDesktop: boolean;
  onChangeLng?: (lng: string) => any;
  isEdit?: boolean;
  t: (value: string, params?: any) => string;
  initialValues?: any;
  children?: any;
}

const SEOForm: React.FC<FormProps> = ({
  children,
  title,
  closeModal,
  isMobile,
  onChangeLng,
  t,
  isEdit,
  isDesktop,
  ...rest
}) => {
  const requiredTag = (value, allTagsValue) => {
    if (!allTagsValue?.ru?.all_tags) {
      return required(value);
    }
    return undefined;
  };

  return (
    <LangForm
      {...rest}
      onChangeLng={onChangeLng}
      stashLanguages={!isEdit}
      render={({ handleSubmit, form, values, i18n, switcher }) => {
        const fieldAvailable = i18n.language === LANGUAGES.RU;

        return (
          <form onSubmit={handleSubmit}>
            <LandingsModalLayout.GlobalStyles />
            <Modal.FullSizeContainer>
              <StepModal.Header>
                <StepModal.WithLanguage>
                  <StepModal.Title>{title}</StepModal.Title>
                  {isDesktop && <LangSwitcher />}
                </StepModal.WithLanguage>
              </StepModal.Header>
              <Modal.FullSizeContent>
                <Modal.Container>
                  {!isDesktop && <Modal.Section>{switcher}</Modal.Section>}
                  {fieldAvailable && (
                    <>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>Название шаблона</FormDescription>
                          <FormControl column>
                            <Field
                              name={`${i18n.language}.name`}
                              validate={composeValidators(required, maxValue(255))}
                            >
                              {({ input, meta }) => (
                                <Input label="Название шаблона" autoFocus {...input} meta={meta} />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>

                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>Теги</FormDescription>
                          <FormControl column>
                            <FormRow>
                              <Field name={`${i18n.language}.content_groups`} validate={requiredTag}>
                                {({ input, meta }) => (
                                  <InputWithSelect
                                    isAsync
                                    isMulti
                                    isCreatable
                                    label="Теги"
                                    searchQueryName="search_string"
                                    optionsRenderer={renderContentGroups}
                                    route={landingsTagsSources}
                                    disabled={values?.ru?.all_tags}
                                    meta={meta}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </FormRow>
                            <Field name={`${i18n.language}.all_tags`} type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox subText="Все сайты" variant="round" meta={meta} {...input} />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>Ссылки</FormDescription>
                          <FormControl column>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.regexp`} validate={required}>
                                {({ input, meta }) => (
                                  <Input label="Регулярное выражение для ссылки" {...input} meta={meta} />
                                )}
                              </Field>
                            </LandingsModalLayout.Row>
                            <Field
                              name={`${i18n.language}.weight`}
                              validate={composeValidators(required, maxNumberValue(1000000))}
                            >
                              {({ input, meta }) => <NumberInput label="Вес" {...input} meta={meta} />}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                    </>
                  )}
                  <LandingsModalLayout.Row>
                    <Modal.Info>
                      <Modal.InfoItem>
                        Подсказка как обращаться с переменными и как их использовать.
                      </Modal.InfoItem>
                      <Modal.InfoItem>
                        {'{name} - Имя персонали, название новости, название мероприятия;'}
                      </Modal.InfoItem>
                      <Modal.InfoItem>{'{name_afisha} - Заголовок афиши мероприятия;'}</Modal.InfoItem>
                      <Modal.InfoItem>
                        {'{annotate} - Короткий текст новости, персонали, мероприятия;'}
                      </Modal.InfoItem>
                      <Modal.InfoItem>{'{date} - Дата мероприятия;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{category} - Категория мероприятия;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{place} - Площадка мероприятия;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{city} - Город мероприятия;'}</Modal.InfoItem>
                      <Modal.InfoItem>
                        {
                          // eslint-disable-next-line max-len
                          '{city-in} - Город мероприятия, отвечает на вопрос "где?" ("Москве", "Санкт-Петербурге" и тп);'
                        }
                      </Modal.InfoItem>
                      <Modal.InfoItem>{'{city2} - Выбранный пользователем город;'}</Modal.InfoItem>
                      <Modal.InfoItem>
                        {
                          // eslint-disable-next-line max-len
                          '{city2-in} - Выбранный пользователем город, отвечает на вопрос "где?" ("Москве", "Санкт-Петербурге" и тп);'
                        }
                      </Modal.InfoItem>
                      <Modal.InfoItem>{'{actual_address} - Фактический адрес компании;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{acquiring_name} - Наименование банка (Экваринга);'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{company_name} - Наименование компании;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{email} - Контактный email компании;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{inn} - ИНН компании;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{kpp} - КПП компании;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{legal_address} - Юридический адрес;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{phone} - Основной номер телефона;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{site_name} - Название сайта;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{domain} - урл сайта;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{year} - текущий год;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{new_year} - следующий год;'}</Modal.InfoItem>
                      <Modal.InfoItem>{'{last_year} - прошедший год;'}</Modal.InfoItem>
                    </Modal.Info>
                  </LandingsModalLayout.Row>

                  <Modal.Section>
                    <LandingsModalLayout.Row grid>
                      <FormDescription width={208}>Заголовки</FormDescription>
                      <FormControl column>
                        <LandingsModalLayout.Row>
                          <Field name={`${i18n.language}.title`} validate={maxValue(100)}>
                            {({ input, meta }) => (
                              <Input label="Тайтл" {...input} meta={meta} maxValue={100} />
                            )}
                          </Field>
                        </LandingsModalLayout.Row>
                        <LandingsModalLayout.Row>
                          <Field name={`${i18n.language}.h1`}>
                            {({ input, meta }) => <Input label="H1" {...input} meta={meta} />}
                          </Field>
                        </LandingsModalLayout.Row>
                        <LandingsModalLayout.Row>
                          <Field name={`${i18n.language}.h2`}>
                            {({ input, meta }) => <Input label="H2" {...input} meta={meta} />}
                          </Field>
                        </LandingsModalLayout.Row>
                        <LandingsModalLayout.Row>
                          <Field name={`${i18n.language}.description`} validate={maxValue(300)}>
                            {({ input, meta }) => (
                              <Textarea label="Описание" maxValue={300} {...input} meta={meta} />
                            )}
                          </Field>
                        </LandingsModalLayout.Row>
                        <Field name={`${i18n.language}.keywords`}>
                          {({ input, meta }) => <Input label="Ключевые слова" {...input} meta={meta} />}
                        </Field>
                      </FormControl>
                    </LandingsModalLayout.Row>
                  </Modal.Section>

                  <Field name={`${i18n.language}.seo_text`}>
                    {({ input, meta }) => (
                      <WysiwigEditorPreview
                        id="seo_1"
                        label="SEO - текст"
                        key={rest.initialValues?.description || i18n.language}
                        placeholder="SEO - текст"
                        inModal
                        input={input}
                        meta={meta}
                        withPreview={isEdit}
                      />
                    )}
                  </Field>
                </Modal.Container>

                {!children ? (
                  <Modal.Footer justify fullSize contentWidth="800px">
                    <Button type="button" transparent onlyIcon={isMobile} onClick={closeModal}>
                      {isMobile ? <CloseIcon /> : t('forms:cancel')}
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        form.change('action', 'create');
                      }}
                    >
                      {t('forms:create')}
                    </Button>
                  </Modal.Footer>
                ) : (
                  <Modal.Footer fullSize contentWidth="800px">
                    {children}
                  </Modal.Footer>
                )}
              </Modal.FullSizeContent>
            </Modal.FullSizeContainer>
          </form>
        );
      }}
    />
  );
};

export default withCheckIsMobile(SEOForm);
