import React from 'react';
import { withRouter } from 'next/router';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import Button from 'components/Button';
import SEOForm from './SEOForm';
import landingsSEOSources from 'shared/sources/landings/landingsSEO';
import { editTranslationData } from 'shared/helpers/form';
import withLandingData from '../withLandingData';
import { withTranslation } from '../../../../i18n';
import LOCALES from 'shared/constants/LOCALES';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { selectAccount } from '../../../../entities/account/reducer';

interface ModalProps {
  isMobile: boolean;
  closeModal: ModalFunctions['closeModal'];
  modalState: string;
  data: any;
  showDeleteModal: () => void;
  t: (value: string, params?: { [key: string]: string }) => string;
  onChangeLng: (lng: string) => any;
  updateList: () => void;
  onUpdate: (data: any) => any;
  create: (data: any) => any;
  update: (data: any) => any;
  lng: string;
}

const SEOModal: React.FC<ModalProps> = (props) => {
  const account = useSelector(selectAccount);
  const {
    data,
    onChangeLng,
    updateList,
    showDeleteModal,
    modalState,
    onUpdate,
    t,
    create,
    update,
    isMobile,
    lng,
  } = props;

  if (modalState === MODAL_STATES.CREATE) {
    return (
      <SEOForm
        key="create"
        title="Добавить шаблон"
        onSubmit={create}
        onSuccess={updateList}
        closeModal={props.closeModal}
        onChangeLng={onChangeLng}
        t={t}
        initialValues={{
          item_create: true,
          company_id: account.company.id,
        }}
      />
    );
  }

  const initialValues = {
    company_id: account.company.id,
    ...omit(data, ['published', 'company', 'date_create', 'date_edit', 'date_publish']),
    id: data.id,
    content_groups: data.content_groups
      ? data.content_groups.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : [],
  };

  const handleUpdate = (formData) => {
    const dataForCompare = formData[lng];

    if (isEqual(dataForCompare, initialValues)) {
      return {};
    }

    return update(formData);
  };

  return (
    <SEOForm
      key="edit"
      isEdit
      title="Редактировать шаблон"
      onSubmit={handleUpdate}
      onSuccess={onUpdate}
      initialValues={initialValues}
      onChangeLng={onChangeLng}
      t={t}
    >
      <Button dangerTransparent onlyIcon={isMobile} type="button" onClick={showDeleteModal}>
        {props.isMobile ? <TrashIcon /> : t('forms:delete')}
      </Button>
      <Button type="button" transparent onClick={props.closeModal}>
        {t('forms:cancel')}
      </Button>
      <Button type="submit">{t('forms:save')}</Button>
    </SEOForm>
  );
};

const mappedSubmitData = (item) => ({
  ...item,
  title: item.title || '',
  h1: item.h1 || '',
  h2: item.h2 || '',
  description: item.description || '',
  keywords: item.keywords || '',
  seo_text: item.seo_text || '',
  content_groups: item.content_groups ? item.content_groups.map((group) => group.value) : [],
});

export default withCheckIsMobile(
  // @ts-ignore
  withLandingData(withRouter(withTranslation([LOCALES.FORMS, LOCALES.LANDINGS])(SEOModal)), {
    translation: 'seo',
    sources: landingsSEOSources,
    mappedCreateData: (submitData) =>
      editTranslationData(omit(submitData, 'action'), (item) => mappedSubmitData(item)),
    mappedUpdateData: (submitData) =>
      editTranslationData(omit(submitData, 'action'), (item) => mappedSubmitData(item)),
  }),
);
