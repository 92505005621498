import styled, { css } from 'styled-components';
import { layoutScrollbar } from 'app/styles/scrollbar';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface CSSProps {
  isActive?: boolean;
  disabled?: boolean;
}

export default {
  Wrapper: styled.div`
    display: flex;
    flex-grow: 1;
    max-height: calc(100vh - 163px);

    @media (max-width: ${BREAKPOINTS.MD}) {
      max-height: 100vh;
    }
  `,

  Scheme: styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
  `,

  Sidebar: styled.div<CSSProps>`
    width: 350px;
    flex-shrink: 0;
    border-left: 1px solid #e1e1e1;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    ${layoutScrollbar}

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: absolute;
      left: 0;
      width: 100%;
      background: #fff;

      &:after {
        position: fixed;
        content: '';
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: ${({ isActive }) => (isActive ? '0.64' : '0')};
        z-index: ${({ isActive }) => (isActive ? '1' : '-1')};
      }

      ul {
        position: relative;
        z-index: 2;
        height: ${({ isActive }) => (isActive ? 'auto' : '0px')};
        overflow: ${({ isActive }) => (isActive ? 'auto' : 'hidden')};
        background: #fff;
      }
    }
  `,

  SidebarTitle: styled.div<CSSProps>`
    padding: 0 0 8px 16px;
    height: 56px;
    font-weight: bold;
    font-size: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: flex-end;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: relative;
      z-index: 5;
      height: auto;
      padding: 14px 42px 14px 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      box-shadow: ${({ isActive }) => isActive && '0px 2px 8px rgba(0, 0, 0, 0.12)'};

      &:after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        right: 16px;
        height: 100%;
        width: 12px;
        margin: 0 auto;
        background-image: url('/static/icons/dropdown-arrow.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transform: ${({ isActive }) => (isActive ? 'none' : 'rotate(180deg)')};
        opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
        transition: all 0.2s ease-in-out;
      }
    }
  `,

  AccordionWrapper: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      max-height: 40vh;
      overflow-y: auto;
    }
  `,

  Demo: styled.iframe`
    position: relative;
    border: 0;
    flex-grow: 1;
    overflow: hidden;
    border-bottom-left-radius: 8px;
  `,

  Buttons: styled.div<CSSProps>`
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 0 8px;

      @media (max-width: ${BREAKPOINTS.XS}) {
        padding: 0 16px;
      }
    }

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;

        & button {
          pointer-events: none;
        }
      `}

    @media (max-width: ${BREAKPOINTS.MD}) {
      top: 72px;
    }
  `,

  Arrow: styled.div<CSSProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    height: 100%;
    width: 12px;
    margin: 0 auto;
    background-image: url('/static/icons/dropdown-arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    transform: ${({ isActive }) => (isActive ? 'none' : 'rotate(180deg)')};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
    transition: all 0.2s ease-in-out;
  `,

  SpinnerContainer: styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  SaveChanges: styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    & > button {
      pointer-events: all;
      margin: 0 8px;
    }
  `,
};
