import Button from 'components/Button';
import { LangForm } from 'components/Form';
import {
  Textarea,
  FileCropperInput,
  InputWithSelect,
  DateTimePicker,
  FormDescription,
  SmallFormDescription,
  FormControl,
  FileArrayInput,
  LangSwitcher,
} from 'components/FormControls';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
import React from 'react';
import { Field } from 'react-final-form';
import { WysiwigEditorPreview } from 'entities/wysiwyg-editor';
import {
  STEPS_PREVIEW,
  ASPECT_RATIO_PREVIEW,
  STEPS_WORDS_PREVIEW,
  SIZES_PREVIEW,
  LANDINGS_PREVIEW_IMAGE_SIZES,
} from 'shared/constants/IMAGE_CONFIG';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderContentGroups } from 'shared/helpers/optionsRenderers';
import {
  composeValidators,
  required,
  maxValue,
  validDate,
  landingDescriptionValidation,
} from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import landingsTagsSources from 'shared/sources/landings/landingsTags';
import { ModalFunctions } from '../../../../../interfaces/modal';
import CloseIcon from '../../../../../static/icons/close.svg';
import LandingsModalLayout from '../landingsModalLayout';

interface FormProps {
  title: string;
  closeModal: ModalFunctions['closeModal'];
  isMobile: boolean;
  isDesktop: boolean;
  onChangeLng: any;
  onSubmit?: (data: any) => any;
  isEdit?: boolean;
  initialValues?: any;
  children?: any;
}

const ArticleForm: React.FC<FormProps> = ({
  children,
  title,
  closeModal,
  isMobile,
  isDesktop,
  onChangeLng,
  isEdit,
  ...rest
}) => (
  <Modal.FullSizeContainer>
    <StepModal.Header>
      <StepModal.WithLanguage>
        <StepModal.Title>{title}</StepModal.Title>
        {isDesktop && <LangSwitcher />}
      </StepModal.WithLanguage>
    </StepModal.Header>
    <Modal.FullSizeContent>
      <LangForm
        stretch
        onChangeLng={onChangeLng}
        stashLanguages={!isEdit}
        {...rest}
        render={({ handleSubmit, form, i18n, switcher }) => {
          const fieldAvailable = i18n.language === LANGUAGES.RU;

          return (
            <form onSubmit={handleSubmit}>
              <LandingsModalLayout.GlobalStyles />
              <Modal.Container>
                <>
                  {!isDesktop && <Modal.Section>{switcher}</Modal.Section>}
                  {fieldAvailable && (
                    <>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            Дата и время
                            <SmallFormDescription>
                              Дата и время новости не зависит от даты публикации
                            </SmallFormDescription>
                          </FormDescription>
                          <FormControl data-selenium="date_news">
                            <Field name={`${i18n.language}.date_news`} validate={validDate}>
                              {({ input, meta }) => (
                                <DateTimePicker
                                  label="Дата и время"
                                  name="date_news"
                                  handleForm={form}
                                  input={input}
                                  meta={meta}
                                  outdatedToggler
                                  inModal
                                  lng={i18n.language}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>

                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            Теги
                            <SmallFormDescription>Теги для привязки мероприятия к сайту</SmallFormDescription>
                          </FormDescription>
                          <FormControl>
                            <Field name={`${i18n.language}.content_groups`} validate={required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  isMulti
                                  isCreatable
                                  label="Теги"
                                  searchQueryName="search_string"
                                  optionsRenderer={renderContentGroups}
                                  route={landingsTagsSources}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                    </>
                  )}

                  <Modal.Section>
                    <LandingsModalLayout.Row grid>
                      <FormDescription width={208}>
                        Заголовок и анонс
                        <SmallFormDescription>Заголовок о мероприятии и краткий анонс</SmallFormDescription>
                      </FormDescription>
                      <FormControl column>
                        <LandingsModalLayout.Row>
                          <Field
                            name={`${i18n.language}.name`}
                            validate={composeValidators(required, maxValue(255))}
                          >
                            {({ input, meta }) => (
                              <Textarea label="Заголовок" maxValue={255} {...input} meta={meta} />
                            )}
                          </Field>
                        </LandingsModalLayout.Row>
                        <LandingsModalLayout.Row>
                          <Field
                            name={`${i18n.language}.annotate`}
                            validate={composeValidators(required, maxValue(255))}
                          >
                            {({ input, meta }) => (
                              <Textarea label="Анонс" maxValue={255} {...input} meta={meta} />
                            )}
                          </Field>
                        </LandingsModalLayout.Row>
                      </FormControl>
                    </LandingsModalLayout.Row>
                  </Modal.Section>
                  {fieldAvailable && (
                    <>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            Изображения
                            <SmallFormDescription>Главное изображение и превью</SmallFormDescription>
                          </FormDescription>
                          <FormControl column>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.cover`}>
                                {({ input, meta }) => (
                                  <FileCropperInput
                                    placeholder="Загрузить Обложку"
                                    // eslint-disable-next-line
                                    description="Рекомендуемый размер Главного изображения 1920х960px (16:9), не более 5MB"
                                    {...input}
                                    meta={meta}
                                  />
                                )}
                              </Field>
                            </LandingsModalLayout.Row>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.preview`}>
                                {({ input, meta }) => (
                                  <FileCropperInput
                                    placeholder="Загрузить Превью"
                                    description="Рекомендуемый размер Превью 480х320px, не более 2MB"
                                    {...input}
                                    meta={meta}
                                    stepsEnum={STEPS_PREVIEW}
                                    ratio={ASPECT_RATIO_PREVIEW}
                                    words={STEPS_WORDS_PREVIEW}
                                    sizes={SIZES_PREVIEW}
                                    imageSizes={LANDINGS_PREVIEW_IMAGE_SIZES}
                                  />
                                )}
                              </Field>
                            </LandingsModalLayout.Row>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            Галерея
                            <SmallFormDescription>Коллеция изображений новости</SmallFormDescription>
                          </FormDescription>
                          <FormControl column>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.gallery`}>
                                {({ input, meta }) => <FileArrayInput {...input} meta={meta} />}
                              </Field>
                            </LandingsModalLayout.Row>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                    </>
                  )}
                  <Modal.Editor>
                    <Field name={`${i18n.language}.text`} validate={landingDescriptionValidation}>
                      {({ input, meta }) => (
                        <WysiwigEditorPreview
                          id="article_1"
                          label="Описание"
                          key={rest.initialValues?.description || i18n.language}
                          inModal
                          input={input}
                          meta={meta}
                          withPreview={isEdit}
                          data={rest?.initialValues?.text}
                          useData
                        />
                      )}
                    </Field>
                  </Modal.Editor>
                </>
              </Modal.Container>

              {!children ? (
                <Modal.Footer justify fullSize contentWidth="800px">
                  <Button type="button" transparent onlyIcon={isMobile} onClick={closeModal}>
                    {isMobile ? <CloseIcon /> : 'Отмена'}
                  </Button>
                  <Button
                    onClick={() => {
                      form.change('action', 'create');
                    }}
                  >
                    Создать
                  </Button>
                </Modal.Footer>
              ) : (
                <Modal.Footer fullSize contentWidth="800px">
                  {children}
                </Modal.Footer>
              )}
            </form>
          );
        }}
      />
    </Modal.FullSizeContent>
  </Modal.FullSizeContainer>
);

export default withCheckIsMobile(ArticleForm);
