import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Button from '../Button';
import { i18n } from '../../i18n';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

const LangSwitcher = () => {
  const { asPath } = useRouter();
  const newLang = i18n.language === LANGUAGES.EN ? LANGUAGES.RU : LANGUAGES.EN;

  return (
    <Link href={asPath} locale={newLang}>
      <Button onlyIcon transparent type="button">
        {i18n.language.toUpperCase()}
      </Button>
    </Link>
  );
};

export default LangSwitcher;
