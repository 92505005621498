import React from 'react';
import Styled from './styles';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalCreate: React.FC<IHistoryItem> = ({ data, user }) => {
  const {
    announcement_event: { title, place, date_start: dateStart, time_start: timeStart },
  } = data;

  return (
    <Styled.Info>
      <Styled.InfoTitle>Создано объявление</Styled.InfoTitle>
      <AnnouncementHistoryModalName data={user} />
      <Styled.InfoText>{`${title} • ${dateStart}, ${timeStart} • ${place.name}`}</Styled.InfoText>
    </Styled.Info>
  );
};

export default AnnouncementHistoryModalCreate;
