import event from '../../pages/pbreports/event';
import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const companyId = getCookie(COOKIES.COMPANY);
export default {
  root: `${config.COMMON_API_URL}/admin/v1/documents`,

  async createDocument(eventId: number, documentId: number) {
    const response = await api.post(`${config.COMMON_API_URL}/admin/v1/events/${eventId}/documents`, {
      document_id: documentId,
    });

    return response.data;
  },

  getDocument(eventId: number) {
    const response = `${config.COMMON_API_URL}/admin/v1/events/${eventId} `;

    return response;
  },

  deleteDocument(eventId: number, document_id: number) {
    const response = `${config.COMMON_API_URL}/admin/v1/events/${eventId}/documents/${document_id} `;

    return response;
  },
};
