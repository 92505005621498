import React, { Component } from 'react';
import Styled from './styles';
import CancelAltIcon from '../../../static/icons/cancel-alt.svg';
import ArrowDownIcon from '../../../static/icons/arrow-down.svg';
import Button from '../Button';
import DotsIcon from '../../../static/Dropdown/dots.svg';

interface MobilePopupProps {
  data?: any;
  title?: string;
  dropdown?: boolean;
  visible?: boolean;
}

interface MobilePopupState {
  isOpen: boolean;
  withShadow: boolean;
}

class MobilePopup extends Component<MobilePopupProps, MobilePopupState> {
  static ListItem: any;

  static Icon: any;

  innerRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.visible,
      withShadow: false,
    };

    this.innerRef = React.createRef();
  }

  handleScroll = () => {
    const newValue = this.innerRef.current.scrollTop !== 0;
    if (newValue !== this.state.withShadow) {
      this.setState({
        withShadow: newValue,
      });
    }
  };

  togglePopup = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { data, title, dropdown } = this.props;
    const { isOpen, withShadow } = this.state;

    return (
      <Styled.Container>
        {dropdown ? (
          <Button transparent onlyIcon onClick={this.togglePopup}>
            <DotsIcon />
          </Button>
        ) : (
          <Styled.Toggler transparent gray onClick={this.togglePopup}>
            {title}
            <Styled.Icon>
              <ArrowDownIcon />
            </Styled.Icon>
          </Styled.Toggler>
        )}
        {isOpen && (
          <>
            <Styled.Backdrop onClick={this.togglePopup} />
            <Styled.Popup>
              {title && (
                <Styled.Header withShadow={withShadow}>
                  {title}
                  <Styled.Close onClick={this.togglePopup}>
                    <CancelAltIcon />
                  </Styled.Close>
                </Styled.Header>
              )}
              <Styled.Inner ref={this.innerRef} onScroll={this.handleScroll}>
                {data}
              </Styled.Inner>
            </Styled.Popup>
          </>
        )}
      </Styled.Container>
    );
  }
}

MobilePopup.ListItem = Styled.ListItem;
MobilePopup.Icon = Styled.Icon;

export default MobilePopup;
