import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const companyId = getCookie(COOKIES.COMPANY);
export default {
  root: `${config.LANDING_API_URL}/api/v1/seo`,
  rootv2: `${config.LANDING_API_URL}/api/v2/seo`,
  detail: (id) => `${config.LANDING_API_URL}/api/v1/seo/${id}`,

  async getTranslationData({ id, lng }) {
    const response = await api.get(`${config.LANDING_API_URL}/api/v1/seo/${id}/translation/${lng}`, {
      params: {
        company_id: companyId,
      },
    });

    return response;
  },
  async createTranslationData({ id, data }) {
    const response = await api.post(`${config.LANDING_API_URL}/api/v1/seo/${id}/translation`, {
      ...data,
      company_id: companyId,
    });

    return response;
  },
};
