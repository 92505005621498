import Button from 'components/Button';
import { LangForm } from 'components/Form';
import { Input, FormDescription, FormControl, LangSwitcher } from 'components/FormControls';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { WysiwigEditorPreview } from 'entities/wysiwyg-editor';
import LOCALES from 'shared/constants/LOCALES';
import PAGES from 'shared/constants/PAGES';
import { required, landingDescriptionValidation } from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import landingsTemplatesSources from 'shared/sources/landings/landingsTemplates';
import { ModalFunctions } from '../../../../interfaces/modal';
import TrashIcon from '../../../../static/icons/trash-alt.svg';
import { selectAccount } from '../../../entities/account/reducer';
import { withTranslation } from '../../../i18n';
import landingsModalLayout from '../../Pages/Landings/landingsModalLayout';
import withLandingData from '../../Pages/Landings/withLandingData';
import Styled from './styles';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  showDeleteModal: () => void;
  modalState?: string;
  lng: string;
  t: (value: string, params?: { [key: string]: any }) => string;
  isMobile?: boolean;
  isDesktop?: boolean;
  onChangeLng: any;
  updateList: (data: any) => void;
  onUpdate: (data: any) => void;
  create: (data: any) => void;
  update: (data: any) => void;
}

const LandingsTemplateModal: React.FC<ModalProps> = (props) => {
  const { data, onChangeLng, updateList, onUpdate, t, create, update, isMobile, isDesktop, lng } = props;
  const isEdit = props.data.id;
  const account = useSelector(selectAccount);

  const initialValues = {
    ...data,
    company_id: account.company.id,
  };

  const handleUpdate = (formData) => {
    const dataForCompare = formData[lng];

    if (isEqual(dataForCompare, initialValues)) {
      return {};
    }

    return update(formData);
  };

  return (
    <LangForm
      stashLanguages={!isEdit}
      onChangeLng={onChangeLng}
      onSubmit={isEdit ? handleUpdate : create}
      initialValues={initialValues}
      onSuccess={(submitData) => {
        if (isEdit) {
          return onUpdate(submitData);
        }
        return updateList(submitData);
      }}
      render={({ handleSubmit, i18n, switcher }) => (
        <form onSubmit={handleSubmit}>
          <Styled.GlobalStyles />
          <Modal.FullSizeContainer>
            <StepModal.Header>
              <StepModal.WithLanguage>
                <StepModal.Title>{`${isEdit ? 'Редактировать' : 'Добавить'}`} шаблон текста</StepModal.Title>
                {isDesktop && <LangSwitcher />}
              </StepModal.WithLanguage>
            </StepModal.Header>
            <Modal.FullSizeContent>
              <Modal.Container>
                {!isDesktop && <Modal.Section>{switcher}</Modal.Section>}
                <Modal.Section>
                  <landingsModalLayout.Row grid>
                    <FormDescription width={208}>Название</FormDescription>
                    <FormControl column>
                      <Field name={`${i18n.language}.name`} validate={required}>
                        {({ input, meta }) => <Input label="Название" {...input} meta={meta} />}
                      </Field>
                    </FormControl>
                  </landingsModalLayout.Row>
                </Modal.Section>
                <Styled.Fields>
                  <Modal.Info>
                    <Modal.InfoItem>
                      Подсказка как обращаться с переменными и как их использовать.
                    </Modal.InfoItem>
                    <Modal.InfoItem>{'{actual_address} - Фактический адрес компании'}</Modal.InfoItem>
                    <Modal.InfoItem>{'{acquiring_name} - Наименование банка (Экваринга)'}</Modal.InfoItem>
                    <Modal.InfoItem>{'{company_name} - Наименование компании'}</Modal.InfoItem>
                    <Modal.InfoItem>{'{email} - Контактный email компании'}</Modal.InfoItem>
                    <Modal.InfoItem>{'{inn} - ИНН компании'}</Modal.InfoItem>
                    <Modal.InfoItem>{'{kpp} - КПП компании'}</Modal.InfoItem>
                    <Modal.InfoItem>{'{legal_address} - Юридический адрес'}</Modal.InfoItem>
                    <Modal.InfoItem>{'{site_name} - URL сайта'}</Modal.InfoItem>
                  </Modal.Info>
                  <Field name={`${i18n.language}.text`} validate={landingDescriptionValidation}>
                    {({ input, meta }) => (
                      <WysiwigEditorPreview
                        id="template_1"
                        label="Описание"
                        key={data?.text || i18n.language}
                        input={input}
                        meta={meta}
                        withPreview={isEdit}
                        useData
                        data={data?.text}
                      />
                    )}
                  </Field>
                </Styled.Fields>
              </Modal.Container>
              <Modal.Footer fullSize contentWidth="800px">
                {isEdit && (
                  <Button type="button" dangerTransparent onClick={props.showDeleteModal}>
                    {isMobile ? <TrashIcon /> : t('forms:delete')}
                  </Button>
                )}
                {isEdit ? (
                  <Button type="button" transparent onClick={props.closeModal}>
                    {t('forms:cancel')}
                  </Button>
                ) : (
                  <Styled.LeftAlignedButton>
                    <Button type="button" transparent onClick={props.closeModal}>
                      {t('forms:cancel')}
                    </Button>
                  </Styled.LeftAlignedButton>
                )}
                <Button type="submit">{isEdit ? t('forms:save') : t('forms:create')}</Button>
              </Modal.Footer>
            </Modal.FullSizeContent>
          </Modal.FullSizeContainer>
        </form>
      )}
    />
  );
};

LandingsTemplateModal.defaultProps = {
  modalState: null,
  isMobile: false,
  isDesktop: true,
};

export default withCheckIsMobile(
  withLandingData(withTranslation([LOCALES.FORMS, LOCALES.LANDINGS])(LandingsTemplateModal), {
    page: PAGES.LANDING_TEMPLATE,
    translation: 'seo',
    sources: landingsTemplatesSources,
    mappedCreateData: (submitData) => submitData,
    mappedUpdateData: (submitData) => submitData,
  }),
);
