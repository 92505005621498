import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import React from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import { companySources } from 'entities/company';
import { addBundlesToEntity, bundlesSources, deleteBundlesFromEntity } from 'shared/api/bundles';
import { categoryRoutes } from 'shared/api/reference/categories';
import {
  MARKETPLACE_SIZES_PREVIEW,
  STEPS_EVENT_PREVIEW,
  MARKETPLACE_RATIO,
  STEPS_WORDS_EVENT_PREVIEW,
  MARKETPLACE_IMAGE_SIZES,
  MARKETPLACE_EVENT_PREVIEW_RATIO,
  MARKETPLACE_EVENT_PREVIEW_IMAGE_SIZES,
} from 'shared/constants/IMAGE_CONFIG';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import RESTRICTIONS from 'shared/constants/RESTRICTIONS';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { renderMembers, renderBundles, renderCategories } from 'shared/helpers/optionsRenderers';
import { getUserName } from 'shared/helpers/user';
import { required, composeValidators, maxValue } from 'shared/helpers/validations';
import useEventData, { mapSubmitData } from 'shared/lib/useEventData';
import { ModalFunctions } from '../../../../../interfaces/modal';
import CloseIcon from '../../../../../static/icons/close.svg';
import TrashIcon from '../../../../../static/icons/trash.svg';
import Button from '../../../Button';
import { LangForm } from '../../../Form';
import {
  InputWithSelect,
  Input,
  Select,
  FileCropperInput,
  FormDescription,
  SmallFormDescription,
  FormControl,
  Checkbox,
  FileInput,
  RadioGroup,
} from '../../../FormControls';
import Modal from '../../../Modal';
import Spinner from '../../../Spinner';
import StyledEventsForm from '../EventsForm/styles';
import EventsLayout from '../EventsLayout';

const getDifferenceBundles = (initialData: any, formData: any) => {
  const newBundlesIds: number[] = formData?.bundles?.map((i: { value: number }) => i.value);
  const initialBundlesIds: number[] = initialData.bundles?.map((i: { value: number }) => i.value);
  const initialBundles = new Set(initialBundlesIds);
  const newBundles = new Set(newBundlesIds);
  const bundlesForDelete = initialBundlesIds?.filter((x) => !newBundles.has(x));
  const bundlesForCreate = newBundlesIds?.filter((x) => !initialBundles.has(x));

  return {
    bundlesForCreate,
    bundlesForDelete,
  };
};

interface StepProps {
  goNext: () => void;
  isMobile?: boolean;
  isDesktop: boolean;
  closeModal: ModalFunctions['closeModal'];
  showDeleteEventModal: (callback: any, id?: number) => void;
  modalData: any;
}

const FirstStep: React.FC<StepProps> = ({
  modalData,
  goNext,
  closeModal,
  isMobile,
  isDesktop,
  showDeleteEventModal,
}) => {
  const { event, isEdit, state, t, changeLanguage, create, updateTranslations, changeRoute } = useEventData({
    modalData,
  });

  const { data, isLoading, isSubmitting } = state;

  const submit = (action) => {
    if (action === SUBMIT_ACTIONS.CONTINUE) {
      goNext();
    }
  };

  const deleteEvent = () => {
    showDeleteEventModal(() => {
      modalData.forceCloseModal();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      changeRoute();
    }, event);
  };

  const handleSubmitForm = async (formData: any) => {
    if (!isEdit) {
      await create(mapSubmitData(formData.ru)).then(async (action) => {
        if (get(action, 'error.response.data.error.event_category_id')) {
          NotificationManager.error('Создавать мероприятие на родительскую категорию нельзя', 'Ошибка');
          return;
        }

        const { id } = action.payload.data;

        const { bundlesForCreate, bundlesForDelete } = getDifferenceBundles(data, formData.ru);

        if (bundlesForCreate?.length > 0) {
          await addBundlesToEntity({ id, bundles: bundlesForCreate });
        }

        if (bundlesForDelete?.length > 0) {
          await deleteBundlesFromEntity({ id, bundles: bundlesForDelete });
        }

        if (formData.submit_action === SUBMIT_ACTIONS.CONTINUE) {
          await changeRoute(action.payload.data.id);
        }

        await updateTranslations(
          mapValues(omit(formData, ['ru', 'lng', 'submit_action']), (item) => ({ ...item, id })),
        );

        submit(formData.submit_action);
      });

      return;
    }

    await updateTranslations({
      ...formData,
      ...(formData.ru && { ru: { id: event, page1: mapSubmitData(formData.ru) } }),
    });

    if (formData.ru) {
      const { bundlesForCreate, bundlesForDelete } = getDifferenceBundles(data, formData.ru);

      if (bundlesForCreate.length > 0) {
        await addBundlesToEntity({ id: formData.ru.id, bundles: bundlesForCreate });
      }

      if (bundlesForDelete.length > 0) {
        await deleteBundlesFromEntity({ id: formData.ru.id, bundles: bundlesForDelete });
      }
    }

    submit(formData.submit_action);
  };

  if (isLoading) {
    return <Spinner center />;
  }

  return (
    <EventsLayout.Wrapper>
      <LangForm
        stashLanguages={!isEdit}
        onSubmit={handleSubmitForm}
        onChangeLng={changeLanguage}
        initialValues={{
          is_global: 'false',
          ...(isEdit && {
            ...state.data,
            is_global: (data.is_global && `${state.data.is_global}`) || 'false',
            id: data.id || data.item_id,
            ...(data.event_category && {
              event_category: {
                ...data.event_category,
                label: data.event_category.label || data.event_category.name,
              },
            }),
            ...(data.parent && {
              parent: {
                ...data.parent,
                label: data.parent.label || data.parent.title,
              },
            }),
            ...(!isEmpty(data.manager) && {
              manager_id: {
                value: data.manager.id,
                label: getUserName(data.manager),
              },
            }),
          }),
        }}
        render={({ handleSubmit, form, values, switcher, i18n }) => {
          const fieldAvailable = i18n.language === LANGUAGES.RU;

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Container>
                {!isDesktop && <StyledEventsForm.Row>{switcher}</StyledEventsForm.Row>}
                <Modal.Section>
                  <EventsLayout.Row grid>
                    <FormDescription width={208}>
                      Мероприятия
                      <SmallFormDescription>Основная информация</SmallFormDescription>
                    </FormDescription>
                    <EventsLayout.Column>
                      {fieldAvailable && (
                        <>
                          <FormControl data-selenium="form-event-category">
                            <Field name={`${i18n.language}.event_category`} validate={required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  label={t('events:form.first_step.category')}
                                  route={categoryRoutes}
                                  query={{ language_code: LANGUAGES.RU }}
                                  optionsRenderer={renderCategories}
                                  searchQueryName="search_string"
                                  {...input}
                                  meta={meta}
                                />
                              )}
                            </Field>
                          </FormControl>
                          <FormControl data-selenium="form-event-global">
                            <Field name={`${i18n.language}.is_global`}>
                              {({ input, meta }) => (
                                <RadioGroup
                                  {...input}
                                  meta={meta}
                                  buttons={[
                                    { id: 0, value: 'false', name: 'Локальное' },
                                    { id: 1, value: 'true', name: 'Глобальное' },
                                  ]}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </>
                      )}
                      <FormControl data-selenium="form-event-name">
                        <Field
                          name={`${i18n.language}.title`}
                          validate={composeValidators(required, maxValue(250))}
                        >
                          {({ input, meta }) => (
                            <Input label={t('events:form.first_step.name')} {...input} meta={meta} />
                          )}
                        </Field>
                      </FormControl>
                      {fieldAvailable && (
                        <>
                          <FormControl width="176px">
                            <Field name={`${i18n.language}.restriction`} validate={required}>
                              {({ input, meta }) => (
                                <Select
                                  options={RESTRICTIONS}
                                  label={t('events:form.first_step.restriction')}
                                  {...input}
                                  meta={meta}
                                  withEmpty
                                />
                              )}
                            </Field>
                          </FormControl>
                          <FormControl>
                            <Field name={`${i18n.language}.is_fan_id`} type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Билеты на это мероприятия продаются только с FAN ID
                                </Checkbox>
                              )}
                            </Field>
                          </FormControl>
                          <FormControl>
                            <Field name={`${i18n.language}.bundles`}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  isMulti
                                  label="Подборки"
                                  route={bundlesSources}
                                  optionsRenderer={renderBundles}
                                  meta={meta}
                                  searchQueryName="search_string"
                                  query={{
                                    language_code: LANGUAGES.RU,
                                  }}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </>
                      )}
                    </EventsLayout.Column>
                  </EventsLayout.Row>
                </Modal.Section>

                {fieldAvailable && (
                  <Modal.Section>
                    <EventsLayout.Row grid>
                      <FormDescription width={208}>Ответственный менеджер</FormDescription>
                      <EventsLayout.Column>
                        <FormControl>
                          <Field name={`${i18n.language}.manager_id`}>
                            {({ input, meta }) => (
                              <InputWithSelect
                                isAsync
                                label="Выбрать"
                                route={companySources}
                                routeName="managersAssign"
                                optionsRenderer={renderMembers}
                                meta={meta}
                                {...input}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </EventsLayout.Column>
                    </EventsLayout.Row>
                  </Modal.Section>
                )}

                <Modal.Section>
                  <EventsLayout.Row grid>
                    <FormDescription width={208}>Подсказки</FormDescription>
                    <EventsLayout.Column>
                      {fieldAvailable && (
                        <>
                          <FormControl>
                            <Field name={`${i18n.language}.is_pushkin`} type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Пушкинская карта
                                </Checkbox>
                              )}
                            </Field>
                          </FormControl>
                          <FormControl>
                            <Field name={`${i18n.language}.is_show_hint`} type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Показать подсказку
                                </Checkbox>
                              )}
                            </Field>
                          </FormControl>
                        </>
                      )}
                      <FormControl>
                        <Field
                          name={`${i18n.language}.hint_text`}
                          key={values[i18n.language]?.is_show_hint}
                          validate={maxValue(160)}
                        >
                          {({ input, meta }) => (
                            <Input
                              label="Текст подсказки"
                              {...input}
                              meta={meta}
                              disabled={fieldAvailable && !values[i18n.language]?.is_show_hint}
                            />
                          )}
                        </Field>
                      </FormControl>
                    </EventsLayout.Column>
                  </EventsLayout.Row>
                </Modal.Section>

                {fieldAvailable && (
                  <Modal.Section>
                    <EventsLayout.Row grid>
                      <FormDescription width={208}>Изображения</FormDescription>
                      <EventsLayout.Column>
                        <FormControl data-selenium="form-event-cover">
                          <Field name={`${i18n.language}.cover`}>
                            {({ input, meta }) => (
                              <FileCropperInput
                                description={t('forms:max_size', { count: 5 })}
                                placeholder={t('events:form.first_step.cover')}
                                maxSize={5}
                                ratio={MARKETPLACE_RATIO}
                                imageSizes={MARKETPLACE_IMAGE_SIZES}
                                {...input}
                                meta={meta}
                              />
                            )}
                          </Field>
                        </FormControl>
                        <FormControl data-selenium="form-event-preview">
                          <Field name={`${i18n.language}.preview`}>
                            {({ input, meta }) => (
                              <FileCropperInput
                                description={t('forms:max_size', { count: 2 })}
                                placeholder={t('events:form.first_step.preview')}
                                maxSize={2}
                                sizes={MARKETPLACE_SIZES_PREVIEW}
                                ratio={MARKETPLACE_EVENT_PREVIEW_RATIO}
                                imageSizes={MARKETPLACE_EVENT_PREVIEW_IMAGE_SIZES}
                                stepsEnum={STEPS_EVENT_PREVIEW}
                                words={STEPS_WORDS_EVENT_PREVIEW}
                                {...input}
                                meta={meta}
                              />
                            )}
                          </Field>
                        </FormControl>
                        <FormControl data-selenium="form-ticket-cover">
                          <Field name={`${i18n.language}.ticket_cover`}>
                            {({ input, meta }) => (
                              <FileInput
                                key={input.value}
                                placeholder="+"
                                label={t('events:form.first_step.ticket_cover')}
                                withPreview
                                {...input}
                                meta={meta}
                                maxSize={1}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </EventsLayout.Column>
                    </EventsLayout.Row>
                  </Modal.Section>
                )}
              </Modal.Container>
              <Modal.Footer fullSize justify contentWidth="800px">
                {isEdit ? (
                  <Button
                    onClick={deleteEvent}
                    type="button"
                    dangerTransparent
                    onlyIcon={isMobile}
                    data-selenium="form-event-button-delete"
                  >
                    {isMobile ? <TrashIcon /> : t('forms:delete')}
                  </Button>
                ) : (
                  <Button
                    onlyIcon={isMobile}
                    transparent
                    type="button"
                    onClick={closeModal}
                    data-selenium="cancel-event-button"
                  >
                    {isMobile ? <CloseIcon /> : t('forms:cancel')}
                  </Button>
                )}
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    form.change(`submit_action`, SUBMIT_ACTIONS.CONTINUE);
                  }}
                  data-selenium="create-continue-button"
                >
                  {t('forms:continue')}
                </Button>
              </Modal.Footer>
            </form>
          );
        }}
      />
    </EventsLayout.Wrapper>
  );
};

export default FirstStep;
