import EventsPlacesFilters from 'components/EventsPlacesFilters';
import React from 'react';
import { formatEventDateForSelect } from 'shared/helpers/events';
import useEventData from 'shared/lib/useEventData';
import Spinner from '../../../Spinner';

interface StepProps {
  modalData: any;
  goBack: () => void;
  goNext: () => void;
}

const EventsFormFourStep: React.FC<StepProps> = ({ modalData, goNext, goBack }) => {
  const dateFinish = true;
  const {
    state: { data, isLoading },
  } = useEventData({ modalData }, dateFinish);

  if (isLoading) {
    return <Spinner />;
  }

  let eventDates = data.eventDates?.filter((d: any) => !d.outdated);

  if (!eventDates?.length && data.date_id) {
    eventDates = [
      formatEventDateForSelect({
        date_finish: data.date_finish,
        date_id: data.date_id,
        date_start: data.date_start,
        id: data.date_id,
        time_finish: data.time_finish,
        time_start: data.time_start,
      }),
    ];
  }

  return (
    <EventsPlacesFilters
      eventSourceId={data.event_source_id}
      eventDates={eventDates}
      goBack={goBack}
      goNext={goNext}
    />
  );
};

export default EventsFormFourStep;
