import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import sortBy from 'lodash/sortBy';
import { i18n } from 'next-i18next';
import { PaginatedBundles } from '../api/bundles';
import { LANGUAGES } from '../constants/LANGUAGES';
import PAYMENTS from '../constants/PAYMENTS';
import SELECT_ACTIONS from '../constants/SELECT_ACTIONS';
import { getData } from './data';
import { formatFullDate } from './formatters/date';
import { getAdaptedFilterEvent, getAdaptedMarketEvent } from './formatters/event';
import { getUserName } from './user';

const renderMembers = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: getUserName(item),
    data: item,
  }));

const renderPlaygrounds = (results) =>
  results.data.map
    ? results.data.map((item) => ({
        value: item.id,
        label: item.label,
        data: item,
      }))
    : [];

const renderSortedSectors = (results) =>
  results.data.map
    ? sortBy(
        results.data.map((item) => ({
          value: item.id,
          label: item.label,
          data: item,
        })),
        'label',
      )
    : [];

const renderSortedRowsNumbers = (results) => {
  if (!results.data.map) return [];

  const data = sortBy(
    results.data.map((item) => {
      const rowNumber = Number(item.label);
      const label = isNaN(rowNumber) ? item.label : rowNumber;

      return {
        label,
        value: item.id,
        data: item,
      };
    }),
    'label',
  );

  return data;
};

const renderWidgetPlaygrounds = (results) =>
  results.data.map((item) => ({
    value: item.id,
    name: item.label,
    data: item,
  }));

const renderFilterPlaygrounds = (results) =>
  results.data
    ? results.data.map((item) => ({
        value: item.label,
        label: item.label,
        data: item,
      }))
    : [];

const renderEvents = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: item.title,
    data: item,
  }));

const renderTags = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: item.name,
    data: item,
  }));

const renderFilters = (response) =>
  response.data.data.results.map((item) => ({
    value: item.id,
    name: item.title,
    data: item,
  }));

const renderParsers = (results) =>
  results.data.data.results.map((item) => ({
    value: item.prefix,
    name: item.title,
  }));

const renderBrokers = (results) =>
  results.data.data.results.map((item) => ({
    value: item.agent_id,
    name: item.company,
    data: item,
  }));

const renderPayments = (result) =>
  result.data.data.results
    .filter((item) => item.payment_type === PAYMENTS.ACQUIRING)
    .map((item) => ({
      value: item.id,
      label: item.name,
      data: item,
    }));

const renderFilteredEventWithChildrens = (result) =>
  result.data.data.results.reduce((acc, item) => {
    if (item.children) {
      item.children.forEach((child) => {
        acc.push({
          value: child.id,
          label: item.title,
          data: item,
          dataChild: child,
        });
      });
    } else if (item.date_start && item.date_finish) {
      acc.push({
        value: item.id,
        label: item.title,
        data: item,
      });
    }

    return acc;
  }, []);

const renderEventsForSelector = (result) =>
  result.data.data.results.reduce((acc, item) => {
    if (item.children) {
      if (item.children.length < 5) {
        item.children.forEach((child) => {
          acc.push({
            value: child.id,
            label: item.title,
            data: item,
            dataChild: child,
          });
        });
      } else if (item.children.length >= 5) {
        acc.push({
          value: item.id,
          label: item.title,
          data: item,
          withManyChildrens: true,
          withParentIndicator: true,
        });
      }

      return acc;
    }

    acc.push({
      value: item.id,
      label: item.title,
      data: item,
    });

    return acc;
  }, []);

const renderEventsForWidgetSelector = (result) =>
  result.data.data.results.reduce((acc, item) => {
    if (item.children) {
      if (item.children.length === 1) {
        item.children.forEach((child) => {
          acc.push({
            value: child.id,
            label: item.title,
            data: item,
            dataChild: child,
          });
        });

        return acc;
      }

      acc.push({
        value: item.id,
        label: item.title,
        data: item,
        withManyChildrens: true,
        withParentIndicator: true,
      });

      return acc;
    }

    acc.push({
      value: item.id,
      label: item.title,
      data: item,
    });

    return acc;
  }, []);

const renderEventsForLandingEventSelector = (result) => {
  return result.data.results.reduce((acc, item) => {
    const adaptedItem = getAdaptedFilterEvent(item);

    if (adaptedItem?.children > 1) {
      acc.push({
        value: adaptedItem.id,
        label: adaptedItem.title,
        data: adaptedItem,
        withManyChildrens: true,
        withParentIndicator: true,
        withSingleChildren: true,
      });

      return acc;
    }

    acc.push({
      value: adaptedItem.id,
      label: adaptedItem.title,
      data: adaptedItem,
    });

    return acc;
  }, []);
};

const renderEventsWithoutChildrens = (result, id) =>
  result.data.data.results.reduce((acc, item) => {
    if (!item.children && !item.is_periodical && item.id !== id) {
      acc.push({
        value: item.id,
        label: item.title,
        data: item,
      });
    }

    return acc;
  }, []);

const renderSites = (results) =>
  results.data.data.results.map((item) => ({
    value: item.name,
    label: item.name,
    data: item,
  }));

const renderEventsForMultiSelect = (results) =>
  results.data.results.reduce((acc, item) => {
    const adaptedItem = getAdaptedFilterEvent(item);

    const { id, title, children } = adaptedItem;

    if (children.length) {
      acc.push({
        value: id,
        label: title,
        data: adaptedItem,
        withChildrens: true,
        withParentIndicator: true,
      });
    }

    return acc;
  }, []);

const renderActiveEventsForMultiSelect = (results) =>
  results.data.results.reduce((acc, item) => {
    const adaptedItem = getAdaptedFilterEvent(item, true);

    const { id, title, children } = adaptedItem;

    if (children.length) {
      acc.push({
        value: id,
        label: title,
        data: adaptedItem,
        withChildrens: true,
        withParentIndicator: true,
      });
    }

    return acc;
  }, []);

const renderBasicEventsForMultiSelect = (results) =>
  results.data.results.reduce((acc, item) => {
    const adaptedItem = getAdaptedFilterEvent(item);

    const { title, children } = adaptedItem;

    if (children.length) {
      acc.push({
        value: item.id,
        label: title,
        data: adaptedItem,
        withChildrens: true,
        withParentIndicator: true,
      });
    }

    return acc;
  }, []);

const renderMarketEventsForMultiSelect = (results) =>
  results.data.results.reduce((acc, item) => {
    const adaptedItem = getAdaptedMarketEvent(item);

    const { title, children } = adaptedItem;

    if (children.length) {
      acc.push({
        value: item.id,
        label: title,
        data: adaptedItem,
        withChildrens: true,
        withParentIndicator: true,
      });
    }

    return acc;
  }, []);

const renderRejectReasons = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: item.message,
  }));

const renderLandingSites = (results) =>
  results.data.data.results.map((item) => ({
    value: item.domain,
    label: item.domain,
  }));

const renderNewLandingSites = (response) => {
  const { results } = getData(response);
  return results.map((item) => {
    const domain = item.domain || `https://${item.tech_name}.${process.env.LANDING_DOMAIN}`;
    return {
      value: domain,
      label: domain,
      data: {
        site_url: domain,
        tech_name: item.tech_name,
      },
    };
  });
};

const renderSources = (results) =>
  results.data.data.results.map((item) => ({
    value: item.prefix,
    label: item.title,
  }));

const renderAtol = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: item.company_name,
  }));

const renderEmails = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: item.login,
  }));

const renderLanguagesWithSelectAll = (results) => [
  { label: 'Снять все / Выбрать все', value: SELECT_ACTIONS.SELECT_ALL },
  ...results.data.languages.map((lang) => ({
    label: lang.label,
    value: lang.code,
  })),
];

const renderLanguages = (results) => [
  ...results.data.languages.map((lang) => ({
    label: lang.label,
    value: lang.code,
  })),
];

const renderCurrencyForMultiSelect = (results) => [
  { label: 'Снять все / Выбрать все', value: SELECT_ACTIONS.SELECT_ALL },
  ...results.data.data.results.map((currency) => ({
    label: `${currency.name}, ${currency.char_code}`,
    value: currency.char_code,
    withoutIcon: true,
  })),
];

const renderCurrency = (results) =>
  results.data.data.results.map((currency) => ({
    label: `${currency.name}, ${currency.char_code}`,
    value: currency.id,
    withoutIcon: true,
  }));

const renderEventCategory = (results) =>
  results.data.data.results
    .filter((category) => category.parent)
    .map((category) => ({
      label: category.name,
      value: category.id,
      data: category,
    }));

const renderLegalInfo = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: item.company_name,
  }));

const renderContentGroups = (results) => {
  return results.data.results.map((item) => ({
    value: item.id,
    label: item.name ? item.name : item.title,
  }));
};

const renderWidgets = (results) =>
  results.data.data.results.map((item) => {
    const date =
      item.event.date_start && item.event.time_start
        ? formatFullDate(`${item.event.date_start} ${item.event.time_start}`)
        : '';

    return {
      value: item.id,
      label: `${item.event.title} ${date}`,
      description: item.description,
    };
  });

const renderLandingsTemplates = (response) => {
  const { results } = getData(response);

  return results.map((item) => ({
    value: item.id,
    label: item.name || item.title,
  }));
};

const renderPhones = (results) =>
  !isEmpty(results.data)
    ? results.data.map((item) => ({
        value: item.phone,
        label: item.phone,
      }))
    : [];

const renderCustomEvents = (results) =>
  !isEmpty(results.data)
    ? results.data.data.results.map((item) => ({
        value: item.id,
        label: item.title,
      }))
    : [];

const renderLandingPhones = (results, companyID) =>
  !isEmpty(results.data)
    ? results.data.data.results
        .filter((item) => item.legal_info.id === companyID)
        .map((item) => ({
          value: item.id,
          label: item.phone,
        }))
    : [];

const renderPhonesCity = (results) =>
  results.data.data.results.map((item) => ({
    value: item.city_name,
    label: item.city_name,
  }));

const renderLandingAllPhones = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: item.phone,
  }));

const renderClients = (results, clientID) =>
  results.data.data.results
    .filter((item) => clientID !== item.id)
    .map((item) => ({
      value: item.id,
      label: item.name,
      data: item,
    }));

const renderSubdomains = (results) =>
  results.data.data.results.map((item) => ({
    value: item.id,
    label: item.name,
  }));

const renderDocuments = (results) => {
  return results.data.results.map((item) => ({
    value: item.id,
    label: item.title,
    data: item,
  }));
};

const renderLocations = (response) => {
  const filteredResults = response.data.results.filter((item) =>
    item.info.some((infoItem) => infoItem.language_code === LANGUAGES.RU),
  );

  return filteredResults.map((i) => ({
    label: i.info.find((locationInfo) => locationInfo.language_code === LANGUAGES.RU)?.title,
    value: i.id,
    data: i,
  }));
};

const renderCountries = (results) => {
  return results.data.results.map((item) => ({
    value: item.id,
    label: item.code,
    data: item,
  }));
};

const renderHalls = (result) => {
  return result.data.results.map((i) => ({
    label: i.info.find((placeInfo: { language_code: string }) => placeInfo.language_code === LANGUAGES.RU)
      ?.title,
    value: i.id,
    data: i,
  }));
};

const renderBundles = (result) => {
  const data: PaginatedBundles = result.data;

  return data.results.map((bundle) => {
    const info = bundle.info.find((i) => i.language_code === LANGUAGES.RU);

    return {
      label: info?.title,
      value: bundle.id,
    };
  });
};

const renderPlaceTypes = (results) => {
  return results.data.results.map((item) => {
    const info = item.info.find((i) => i.language_code === i18n?.language || LANGUAGES.RU);

    return {
      value: item.id,
      label: info?.title,
      data: item,
    };
  });
};

const renderReferencePersons = (results, initvalues) => {
  const filterPersonsData = results.data.results.filter((person) => {
    return person.id !== initvalues?.id;
  });

  return filterPersonsData.map((item) => ({
    value: item.id,
    label: item.title,
    data: item,
  }));
};

const renderReferencePlaces = (results, initvalues) => {
  const filterPlacesData = results.data.results.filter((place) => {
    return place.id !== initvalues?.id;
  });

  return filterPlacesData.map((item) => ({
    value: item.id,
    label: item.title,
    data: item,
    id: item.id,
  }));
};

const renderEventRestrictions = (result) => {
  const filterByAge = result.data.results.sort((a, b) => {
    const ageA = parseInt(a.title.replace('+', ''), 10);
    const ageB = parseInt(b.title.replace('+', ''), 10);
    return ageA - ageB;
  });

  return filterByAge.map((i) => ({
    label: i.title,
    code: i.code,
    id: i.id,
  }));
};

export const renderCategories = (result) => {
  return result.data.results.map((i) => ({
    label: i.info.find((info) => info.language_code === LANGUAGES.RU)?.title || i.title || i.name,
    value: i.id,
    data: i,
  }));
};

export const renderAnnouncementEvents = (result) =>
  result.data.results.reduce((acc, item) => {
    const event = getAdaptedFilterEvent(item);

    if (event?.children) {
      if (event.children.length < 5) {
        event.children.forEach((child) => {
          acc.push({
            value: child.id,
            label: item.title,
            data: event,
            dataChild: child,
          });
        });
      } else if (event.children.length >= 5) {
        acc.push({
          value: item.id,
          label: item.title,
          data: event,
          withManyChildrens: true,
          withParentIndicator: true,
        });
      }

      return acc;
    }

    acc.push({
      value: item.id,
      label: item.title,
      data: item,
    });

    return acc;
  }, []);

export const renderBasicEvent = (result) => {
  return result.data.results.map((item) => {
    const event = getAdaptedFilterEvent(item);
    return {
      label: event.title,
      value: event.id,
      data: event,
    };
  });
};

export {
  renderEventRestrictions,
  renderReferencePersons,
  renderReferencePlaces,
  renderPlaceTypes,
  renderBundles,
  renderHalls,
  renderLocations,
  renderCountries,
  renderDocuments,
  renderMembers,
  renderPlaygrounds,
  renderFilterPlaygrounds,
  renderEvents,
  renderFilters,
  renderParsers,
  renderBrokers,
  renderPayments,
  renderFilteredEventWithChildrens,
  renderEventsForSelector,
  renderEventsWithoutChildrens,
  renderWidgetPlaygrounds,
  renderSites,
  renderEventsForMultiSelect,
  renderActiveEventsForMultiSelect,
  renderBasicEventsForMultiSelect,
  renderMarketEventsForMultiSelect,
  renderRejectReasons,
  renderLandingSites,
  renderEventsForWidgetSelector,
  renderSources,
  renderAtol,
  renderEmails,
  renderLegalInfo,
  renderWidgets,
  renderEventsForLandingEventSelector,
  renderPhones,
  renderTags,
  renderCustomEvents,
  renderLandingPhones,
  renderPhonesCity,
  renderLanguages,
  renderLanguagesWithSelectAll,
  renderCurrencyForMultiSelect,
  renderCurrency,
  renderLandingAllPhones,
  renderClients,
  renderContentGroups,
  renderLandingsTemplates,
  renderNewLandingSites,
  renderSubdomains,
  renderSortedSectors,
  renderSortedRowsNumbers,
  renderEventCategory,
};
