import { useUnit } from 'effector-react';
import React from 'react';
import { $hallId, hallSelected } from '../../model';
import { HallLayouts } from './hall-layouts';
import Styled from './styles';

interface AccordionHallsProps {
  id: number;
  isActive: boolean;
}

export const Hall: React.FC<React.PropsWithChildren<AccordionHallsProps>> = ({ children, id }) => {
  const { hallSelected: onHallSelected, hallId } = useUnit({
    hallSelected,
    hallId: $hallId,
  });
  const selectHall = () => {
    onHallSelected(id);
  };

  return (
    <li>
      <Styled.Button onClick={selectHall} data-selenium={children}>
        {children}
      </Styled.Button>
      <HallLayouts isActive={hallId === id} />
    </li>
  );
};
