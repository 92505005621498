import ColumnGrid from 'components/ColumnGrid';
import DataPreview from 'components/DataPreview';
import permissions from 'config/permissions';
import map from 'lodash/map';
import React from 'react';
import { ReferenceItem } from 'widgets/reference/item';
import { Country } from 'shared/api/types';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { findTranslation } from 'shared/helpers/translations';
import { useToggler } from 'shared/lib/toggler';
import Can from '../../../../../containers/Can';
import { toggler } from '../update-modal/model';
import Styled from './styles';

interface ListProps {
  data: Country[];
  state: {
    loading: boolean;
  };
}

export const CountriesList: React.FC<ListProps> = (props) => {
  const { state, data } = props;
  const { open: openUpdateModal } = useToggler(toggler);

  return (
    <Styled.Wrapper>
      <DataPreview length={Object.keys(data).length} state={state}>
        <ColumnGrid.List>
          {map(data, (countriesList: Country[], title: string) => (
            <ColumnGrid.Item key={title}>
              <ColumnGrid.Title>
                <span>{title}</span>
              </ColumnGrid.Title>
              <ColumnGrid.Ul items={countriesList.length}>
                {countriesList.map((country: Country) => {
                  const info = findTranslation(country.info, LANGUAGES.RU);

                  return (
                    <Can
                      key={country.id}
                      permission={permissions.reference}
                      fallback={() => <ReferenceItem>{info?.title}</ReferenceItem>}
                    >
                      <ReferenceItem id={country.id} onClick={openUpdateModal}>
                        {country.code}
                      </ReferenceItem>
                    </Can>
                  );
                })}
              </ColumnGrid.Ul>
            </ColumnGrid.Item>
          ))}
        </ColumnGrid.List>
      </DataPreview>
    </Styled.Wrapper>
  );
};
