import React, { Fragment } from 'react';
import { NotificationManager } from 'react-notifications';
import copyToClipboard from 'copy-to-clipboard';
import moment from 'moment';
import get from 'lodash/get';
import { Field } from 'react-final-form';
import { composeValidators, validDate } from 'shared/helpers/validations';
import { DateTimePicker, Checkbox } from '../../../FormControls';
import Tooltip from '../../../Tooltip';
import TrashIcon from '../../../../../static/icons/trash.svg';
import LinkIcon from '../../../../../static/icons/chain.svg';
import ParsersEventInfo from '../../../ParsersEventInfo';
import StyledEventsForm from '../EventsForm/styles';
import Styled from './styles';
import Button from '../../../Button';

const copyEventId = (_, id) => {
  copyToClipboard(id);
  NotificationManager.success(`ID - ${id}`, 'Успешно');
};

const EventDateRow = ({
  index,
  form,
  isEdit,
  canDelete,
  formValues,
  initialValues,
  childrenId,
  showDeleteEventModal,
  loadData,
  fields,
  formChange,
  style,
}: any) => {
  const name = `children[${index}]`;

  const isDisabled = (date) => {
    const currentDate = get(initialValues, `children[${index}].${date}`);

    if (moment(currentDate).isBefore(moment().subtract(1, 'minutes'))) return true;
    return false;
  };
  const validateEventDates = (startDate, endDate) => {
    if (startDate && endDate && moment(startDate).isAfter(moment(endDate))) {
      return 'Дата начала мероприятия не может быть позднее даты его окончания.';
    }
  };

  const isPremiereFieldName = `children[${index}].is_premiere`;
  const isFullHouseFieldName = `children[${index}].is_full_house`;
  const isStarCastFieldName = `children[${index}].is_star_cast`;
  const topFieldName = `children[${index}].top`;
  const isRejectedFieldName = `children[${index}].is_rejected`;
  const isRescheduledFieldName = `children[${index}].is_rescheduled`;
  const hasOpenDateFieldName = `children[${index}].has_open_date`;

  const eventId = get(formValues, `children[${index}].id`);
  const dateTimeStart = get(formValues, `children[${index}].date_time_start`);

  const disabledDate = (current) => {
    if (current.isBefore(dateTimeStart)) {
      return true;
    }

    return false;
  };

  const copyButton = (
    <Button type="button" onlyIcon transparent opacity={0.4} onClick={copyEventId} data={eventId}>
      <LinkIcon />
    </Button>
  );

  return (
    <Fragment key={fields.length || name}>
      <div style={style}>
        <Styled.Row key={fields.length || name}>
          <StyledEventsForm.Column
            padding="0 16px"
            mobilePaddingTop="24px"
            data-selenium={`event-date-start-${index}`}
            paddingBottom
          >
            <Field name={`${name}.date_time_start`} validate={validDate}>
              {({ input, meta }) => (
                <DateTimePicker
                  key={fields.length}
                  name="date_time_start"
                  fieldSyncName={formValues.add_hours && `${name}.date_time_finish`}
                  input={input}
                  meta={meta}
                  handleForm={form}
                  disabled={isDisabled('date_time_start')}
                  minuteStep={5}
                  highlighted={eventId === childrenId}
                  inModal
                  mobileClose
                />
              )}
            </Field>
          </StyledEventsForm.Column>
          <StyledEventsForm.Column
            padding="0 16px"
            mobilePaddingTop="0"
            data-selenium={`event-date-finish-${index}`}
            paddingBottom
          >
            <Field
              name={`${name}.date_time_finish`}
              validate={composeValidators(validDate, (value, allValues) => {
                const startDate = get(allValues, `${name}.date_time_start`);

                return validateEventDates(startDate, value);
              })}
            >
              {({ input, meta }) => (
                <DateTimePicker
                  key={fields.length}
                  name="date_time_finish"
                  handleForm={form}
                  input={input}
                  meta={meta}
                  disabled={isDisabled('date_time_finish')}
                  minuteStep={5}
                  highlighted={eventId === childrenId}
                  inModal
                  disabledDate={disabledDate}
                  mobileClose
                />
              )}
            </Field>

            <Styled.FieldSideButtons>
              {canDelete(dateTimeStart, eventId) && (
                <Tooltip text="Удалить">
                  <Button
                    type="button"
                    onlyIcon
                    transparent
                    opacity={0.4}
                    onClick={() => {
                      if (isEdit && eventId) {
                        showDeleteEventModal(loadData, eventId);
                      } else {
                        fields.remove(index);
                      }
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </Tooltip>
              )}
              {eventId && (
                <>
                  <StyledEventsForm.ParsersInfo>
                    <ParsersEventInfo eventId={eventId} />
                  </StyledEventsForm.ParsersInfo>
                  <StyledEventsForm.MobileContainer>
                    <Tooltip text={`Скопировать ID-${eventId} в буфер обмена`} placement="left">
                      {copyButton}
                    </Tooltip>
                  </StyledEventsForm.MobileContainer>
                  <StyledEventsForm.TabletAndDesktopContainer>
                    <Tooltip text={`Скопировать ID-${eventId} в буфер обмена`}>{copyButton}</Tooltip>
                  </StyledEventsForm.TabletAndDesktopContainer>
                </>
              )}
            </Styled.FieldSideButtons>
          </StyledEventsForm.Column>
        </Styled.Row>
        <StyledEventsForm.Row flexContainer>
          <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
            <Styled.CheckboxWrapper>
              <Field name={topFieldName} type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    rtl
                    variant="round"
                    meta={meta}
                    {...input}
                    onChange={(event) => {
                      const { checked } = event.target;

                      formChange(topFieldName, checked);
                    }}
                  >
                    Топ:
                  </Checkbox>
                )}
              </Field>
            </Styled.CheckboxWrapper>
          </StyledEventsForm.Column>
          <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
            <Styled.CheckboxWrapper>
              <Field name={isPremiereFieldName} type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    rtl
                    variant="round"
                    meta={meta}
                    {...input}
                    onChange={(event) => {
                      const { checked } = event.target;

                      formChange(isPremiereFieldName, checked);
                    }}
                  >
                    Премьера:
                  </Checkbox>
                )}
              </Field>
            </Styled.CheckboxWrapper>
          </StyledEventsForm.Column>
          <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
            <Styled.CheckboxWrapper>
              <Field name={isFullHouseFieldName} type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    rtl
                    variant="round"
                    meta={meta}
                    {...input}
                    onChange={(event) => {
                      const { checked } = event.target;

                      formChange(isFullHouseFieldName, checked);
                    }}
                  >
                    Аншлаг:
                  </Checkbox>
                )}
              </Field>
            </Styled.CheckboxWrapper>
          </StyledEventsForm.Column>
          <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
            <Styled.CheckboxWrapper>
              <Field name={isStarCastFieldName} type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    rtl
                    variant="round"
                    meta={meta}
                    {...input}
                    onChange={(event) => {
                      const { checked } = event.target;

                      formChange(isStarCastFieldName, checked);
                    }}
                  >
                    Звездный состав:
                  </Checkbox>
                )}
              </Field>
            </Styled.CheckboxWrapper>
          </StyledEventsForm.Column>
        </StyledEventsForm.Row>
        <StyledEventsForm.Row flexContainer>
          <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
            <Styled.CheckboxWrapper>
              <Field name={isRejectedFieldName} type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    rtl
                    variant="round"
                    meta={meta}
                    {...input}
                    onChange={(event) => {
                      const { checked } = event.target;

                      formChange(isRejectedFieldName, checked);
                    }}
                  >
                    Отменено:
                  </Checkbox>
                )}
              </Field>
            </Styled.CheckboxWrapper>
          </StyledEventsForm.Column>
          <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
            <Styled.CheckboxWrapper>
              <Field name={isRescheduledFieldName} type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    rtl
                    variant="round"
                    meta={meta}
                    {...input}
                    onChange={(event) => {
                      const { checked } = event.target;

                      formChange(isRescheduledFieldName, checked);
                    }}
                  >
                    Перенесено:
                  </Checkbox>
                )}
              </Field>
            </Styled.CheckboxWrapper>
          </StyledEventsForm.Column>
          <StyledEventsForm.Column width="auto" padding="0 16px" mobileAlign="flex-start">
            <Styled.CheckboxWrapper>
              <Field name={hasOpenDateFieldName} type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    rtl
                    variant="round"
                    meta={meta}
                    {...input}
                    onChange={(event) => {
                      const { checked } = event.target;

                      formChange(hasOpenDateFieldName, checked);
                    }}
                  >
                    Перенос на открытую дату:
                  </Checkbox>
                )}
              </Field>
            </Styled.CheckboxWrapper>
          </StyledEventsForm.Column>
        </StyledEventsForm.Row>
      </div>
    </Fragment>
  );
};

export default EventDateRow;
