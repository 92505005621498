import Button from 'components/Button';
import ConfirmModal from 'components/Modals/ConfirmModal';
import flow from 'lodash/flow';
import { withRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { openModal } from 'entities/modal';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import LOCALES from 'shared/constants/LOCALES';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import MODALS from 'shared/constants/MODALS';
import { saveTranslationData, editTranslationData } from 'shared/helpers/form';
import useListItem from 'shared/lib/useListItem';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import actualSources from 'shared/sources/actual';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { withTranslation } from '../../../../i18n';
import ActualForm from './form';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  openModal: ModalFunctions['openModal'];
  modalState: string;
  modalData: {
    id: string;
    updateData: (data: any) => any;
  };
  isMobile: boolean;
  t: (value: string, params?: any) => string;
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

interface ModalState {
  data: any;
  t: ModalProps['t'];
  state: {
    isLoading: boolean;
    isFail: boolean;
  };
}

const ActualModal: React.FC<ModalProps> = (props) => {
  const [state, setState] = useState<ModalState>({
    data: null,
    t: props.t,
    state: {
      isLoading: Boolean(props.modalData.id),
      isFail: false,
    },
  });
  const { emitListReload, get, remove } = useListItem({ source: actualSources });
  const { t } = props;

  const loadData = (lng?: string) => {
    const {
      modalData: { id },
    } = props;

    if (id) {
      get(id, lng)
        .then((item) => {
          setState((prevState) => ({
            ...prevState,
            data: {
              id,
              ...item,
              ...(item.domains && { domains: item.domains.map((i) => ({ label: i.name, value: i.id })) }),
            },
            state: {
              isLoading: false,
              isFail: false,
            },
          }));
        })
        .catch(() => {
          setState((prevState) => ({
            ...prevState,
            state: {
              ...prevState.state,
              isFail: true,
            },
          }));
        });

      props.openModal(
        MODALS.REFERENCE_ACTUAL,
        {
          ...props.modalData,
          modalData: {
            mobilePadding: '0',
            fullSize: window.innerWidth < BREAKPOINTS.MD_NUMBER,
          },
        },
        MODAL_STATES.EDIT,
      );
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const onChangeLng = (lng) => {
    loadData(lng);
  };

  const createLink = (data) =>
    saveTranslationData({
      data: editTranslationData(data, (item) => ({
        ...item,
        domains: item.domains ? item.domains.map((l) => ({ id: l.value })) : [],
      })),
      create: actualSources.root,
      update: actualSources.detail,
    }).then((translationData) => {
      if (!translationData.error) {
        emitListReload();
      }

      return translationData;
    });

  const editLink = (data) =>
    saveTranslationData({
      data: editTranslationData(data, (item) => ({
        ...item,
        domains: item.domains ? item.domains.map((l) => ({ id: l.value })) : [],
      })),
      update: actualSources.detail,
    }).then((translationData) => {
      emitListReload();

      return translationData;
    });

  const openEditForm = () => {
    props.openModal(MODALS.REFERENCE_ACTUAL, props.modalData, MODAL_STATES.EDIT);
  };

  const handleDelete = () => {
    const { id } = props.modalData;
    return remove({ id }).then((response) => {
      props.forceCloseModal();

      if (response.error) {
        return NotificationManager.error(t('references:actual:errors:description'));
      }
      emitListReload();

      return response;
    });
  };

  const openDeleteModal = () => {
    props.openModal(
      MODALS.REFERENCE_ACTUAL,
      {
        ...props.modalData,
        modalData: {
          fullSize: false,
          mobilePadding: null,
        },
        CloseButton: null,
      },
      MODAL_STATES.DELETE,
    );
  };

  const { modalState, isMobile, closeModal, forceCloseModal } = props;

  switch (modalState) {
    case MODAL_STATES.CREATE: {
      return (
        <ActualForm
          onSubmit={createLink}
          onSuccess={forceCloseModal}
          key="create"
          title="references:actual.create_title"
          isMobile={isMobile}
          state={state.state}
          stashLanguages
          t={t}
        >
          <Button transparent onClick={closeModal} type="button">
            {t('forms:cancel')}
          </Button>
          <Button>{t('forms:add')}</Button>
        </ActualForm>
      );
    }

    case MODAL_STATES.DELETE:
      return (
        <ConfirmModal
          data={{
            title: t('references:actual.delete_title'),
            text: t('references:actual.delete_text'),
            onSubmit: handleDelete,
            onReject: openEditForm,
          }}
          t={t}
        />
      );

    default: {
      const { data } = state;

      return (
        <ActualForm
          key="edit"
          title="references:actual.edit_title"
          onSubmit={editLink}
          onSuccess={forceCloseModal}
          initialValues={data || {}}
          isMobile={isMobile}
          onChangeLng={onChangeLng}
          state={state.state}
          t={t}
        >
          <Button danger onClick={openDeleteModal} type="button">
            {t('forms:delete')}
          </Button>
          <Button transparent onClick={closeModal} type="button">
            {t('forms:cancel')}
          </Button>
          <Button>{t('forms:save')}</Button>
        </ActualForm>
      );
    }
  }
};

const mapStateToProps = (state) => ({
  modalState: state.modal.state,
  modalData: state.modal.data,
  lastPage: state.currency.lastPage,
  data: state.currency.detailData,
  state: state.currency.detailState,
});

const mapDispatchToProps = {
  openModal,
};

const withHoC = flow([
  withRouter,
  withCheckIsMobile,
  withTranslation([LOCALES.FORMS, LOCALES.REFERENCES]),
  connect(mapStateToProps, mapDispatchToProps),
]);

export default withHoC(ActualModal);
