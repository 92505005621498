import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface ContentProps {
  contentWidth?: string;
}

export default {
  Container: styled.div`
    margin: 48px -40px -32px -40px;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    padding: 0 16px;
    background-color: #fff;
    border-top: 1px solid #e1e1e1;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: 48px -32px -32px;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      margin: 48px -16px -32px;
    }
  `,

  LeftAlignedButton: styled.div`
    display: flex;
    margin-right: auto;
  `,

  Content: styled.div<ContentProps>`
    margin: 0 auto;
    width: 100%;
    height: 72px;
    min-height: 72px;
    max-width: ${({ contentWidth }) => contentWidth};
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > button:not(:first-child) {
      margin-left: 8px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      height: 56px;
      min-height: 56px;
    }

    @media (max-width: ${BREAKPOINTS.XXS}) {
      & button {
        padding: 0 16px;
      }
    }
  `,
};
