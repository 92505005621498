import React, { Component } from 'react';
import reduce from 'lodash/reduce';
import { Form } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import Spinner from '../Spinner';
import Styled from './styles';

interface FormComponentProps {
  error?: string;
  className?: string;
  onSuccess?: (firstValue?: any, secondValue?: any) => void;
  onSubmit?: (data: any) => any;
  fluid?: boolean;
  withoutSpinner?: boolean;
  withoutSubmit?: boolean;
  inRow?: boolean;
  stretch?: boolean;
  initialValues?: any;
  render: any;
  validate?: any;
  isLoading?: boolean;
  mutators?: any;
  subscription?: any;
  t?: (key: string) => string;
  translationPrefix?: string;
  withoutNotification?: boolean;
}

interface State {
  isLoading: boolean;
}

class FormComponent extends Component<FormComponentProps, State> {
  mounted: boolean;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getTranslatedErrorMessage = (errors: string[]) => {
    const { t, translationPrefix } = this.props;
    if (t) {
      return errors.map((i) => t(`${translationPrefix || ''}${i}`)).join(' ');
    }

    return errors.join(' ');
  };

  handleSubmit = async (data) => {
    const { withoutSubmit, onSubmit, onSuccess } = this.props;

    if (withoutSubmit) return null;

    if (this.mounted) this.setState({ isLoading: true });

    const results = await onSubmit(data);

    if (this.mounted) this.setState({ isLoading: false });

    if (results && results.error && results.error.response && results.error.response.data) {
      const { error } = results.error.response.data;

      if (!Array.isArray(error)) {
        const e = reduce(
          error,
          (acc, errors, field) => {
            acc[field] = this.getTranslatedErrorMessage(errors);
            return acc;
          },
          {},
        );

        return e;
      }

      if (error) {
        NotificationManager.error(this.getTranslatedErrorMessage(error), 'Ошибка');
      }
    }

    if (onSuccess && results && !results.error) {
      onSuccess();
    }

    if (results && !results.error && !this.props.withoutNotification) {
      NotificationManager.success('Успешно');
    }

    return {};
  };

  render() {
    const { fluid, withoutSpinner, className, inRow, stretch, ...rest } = this.props;
    const { isLoading } = this.state;

    return (
      <Styled.FormWrapper inRow={inRow} fluid={fluid} className={className} stretch={stretch}>
        {isLoading && !withoutSpinner && (
          <Styled.SpinnerContainer>
            <Spinner />
          </Styled.SpinnerContainer>
        )}
        <Form {...rest} onSubmit={this.handleSubmit} />
      </Styled.FormWrapper>
    );
  }
}

export default FormComponent;
