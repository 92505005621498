import { InputWithSelect } from 'components/FormControls';
import SmallSearch from 'components/SmallSearch';
import { i18n } from 'next-i18next';
import React from 'react';
import { Field } from 'react-final-form';
import countrySources from 'shared/api/reference/country';
import locationSources from 'shared/api/reference/locations';
import { referencePlaceSources } from 'shared/api/reference/places';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import REFERENCE_TABS from 'shared/constants/REFERENCE_TABS';
import { renderCountries, renderReferencePlaces, renderLocations } from 'shared/helpers/optionsRenderers';
import { ReferenceLayout } from '../../layouts/reference-layout';
import Styled from './styles';

const ReferenceSearch = ({ referenceName }: { referenceName: string }) => (
  <ReferenceLayout.SearchForm>
    <SmallSearch clearRemoved key={referenceName} filters={['place', 'country']}>
      <Styled.Selects>
        {referenceName === REFERENCE_TABS.LOCATIONS && (
          <Styled.SearchSelect>
            <Field name="country">
              {({ input, meta }) => (
                <InputWithSelect
                  isAsync
                  label="Страна"
                  route={countrySources}
                  searchQueryName="search_string"
                  optionsRenderer={renderCountries}
                  {...input}
                  meta={meta}
                />
              )}
            </Field>
          </Styled.SearchSelect>
        )}
        {referenceName === REFERENCE_TABS.PLACES && (
          <Styled.SearchSelect>
            <Field name="location">
              {({ input, meta }) => (
                <InputWithSelect
                  isAsync
                  label="Город"
                  route={locationSources}
                  {...input}
                  meta={meta}
                  query={{ language_code: i18n?.language || LANGUAGES.RU }}
                  optionsRenderer={renderLocations}
                  searchQueryName="search_string"
                />
              )}
            </Field>
          </Styled.SearchSelect>
        )}
        {referenceName === REFERENCE_TABS.HALLS && (
          <Styled.SearchSelect>
            <Field name="place">
              {({ input, meta }) => (
                <InputWithSelect
                  isAsync
                  label="Площадка"
                  route={referencePlaceSources}
                  query={{ language_code: i18n?.language || LANGUAGES.RU }}
                  optionsRenderer={renderReferencePlaces}
                  searchQueryName="search_string"
                  {...input}
                  meta={meta}
                />
              )}
            </Field>
          </Styled.SearchSelect>
        )}
      </Styled.Selects>
    </SmallSearch>
  </ReferenceLayout.SearchForm>
);

export default ReferenceSearch;
