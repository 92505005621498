import AntdDatePicker from 'antd/lib/date-picker';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/time-picker/style/index.css';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/input/style/index.css';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import IDS from 'shared/constants/IDS';
import { generateDatesArray } from 'shared/helpers/date';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import CloseIcon from '../../../../../../static/icons/close.svg';
import Error from '../../../../FormControls/Error';
import Label from '../../../../FormControls/LabelNew';
import Portal from '../../../../Portal';
import Styled from './styles';
import TimeSelector from './TimeSelector';

interface DatepickerProps {
  values: any;
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  label?: string;
  disabled?: boolean;
  isMobile?: boolean;
  inModal?: boolean;
  fixedError?: boolean;
}

const ExceptDatepicker: React.FC<DatepickerProps> = ({
  label,
  disabled,
  values,
  input,
  meta,
  isMobile,
  inModal,
  fixedError,
}) => {
  const [pickerContainer, setPickerContainer] = useState(null);
  const [dates, setDates] = useState([]);
  const [isOpen, changePickerState] = useState(false);

  const { date_start: dateStart, date_end: dateEnd, times } = values;

  const handleExcludeDate = (date) => {
    const dateTimes = dates.filter((o) => moment(o.startDate).isSame(date, 'day'));
    if (dateTimes.length > 1) {
      return;
    }
    const hasDate = input.value.filter((o) => moment(o).isSame(date, 'day')).length > 0;
    const excludedDate = dateTimes[0].startDate;
    input.onChange(
      hasDate ? input.value.filter((o) => !moment(o).isSame(date, 'day')) : [...input.value, excludedDate],
    );
  };

  const handleExcludeTime = (date) => {
    const hasDate = input.value.filter((o) => moment(o).isSame(date, 'hour')).length > 0;
    input.onChange(
      hasDate ? input.value.filter((o) => !moment(o).isSame(date, 'hour')) : [...input.value, date],
    );
  };

  useEffect(() => {
    if (!dateStart || !times[0].time_start || !dateEnd || !times[0].time_end) return;

    setDates(generateDatesArray(dateStart, dateEnd, times));
  }, [dateStart, dateEnd, times]);

  useEffect(() => {
    const filteredInputValue = input.value.filter((inputDate) =>
      dates.some((dateObj) => moment(inputDate).isSame(dateObj.startDate, 'day')),
    );

    input.onChange(filteredInputValue);
  }, [dates.length]);

  const toggleDatePicker = () => {
    changePickerState(!isOpen);
  };

  const hideDatePicker = () => {
    changePickerState(false);
  };

  const handleOnOpenChange = () => {
    changePickerState(true);
  };

  useEffect(() => {
    const pickerContainerEl = document.getElementById(IDS.POPUP_CONTAINER);
    const scrollContainer = document.getElementById(inModal ? IDS.MODAL_CONTAINER : IDS.APP_LAYOUT);

    setPickerContainer(pickerContainerEl);

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', hideDatePicker);
    }
  }, []);

  return (
    <>
      {isOpen && (
        <Portal>
          <Styled.Overlay onClick={hideDatePicker} />
        </Portal>
      )}
      <Styled.GlobalStyles />
      <Styled.Container meta={meta}>
        {label && (
          <Label withIcon meta={meta} value={input.value} disabled={disabled}>
            {label}
          </Label>
        )}
        <AntdDatePicker
          className="date-picker-container datepicker-info"
          dropdownClassName="date-picker except-picker"
          open={isOpen}
          showTime={false}
          showToday={false}
          onChange={handleExcludeDate}
          value={input.value.length > 0 && moment()}
          placeholder=""
          disabledDate={(current) => {
            const dateInRange = dates.filter((o) => moment(o.startDate).isSame(current, 'day')).length > 0;
            return !dateInRange;
          }}
          dateRender={(current) => {
            const dateInRange = dates.filter((o) => moment(o.startDate).isSame(current, 'day')).length > 0;
            const isDisabledDate = input.value.filter((o) => moment(o).isSame(current, 'day')).length > 0;
            const dateTimes = dates.filter((o) => moment(o.startDate).isSame(current, 'day'));

            const excludedTimes = dateTimes.filter(
              ({ startDate }) =>
                input.value.filter((excluded) => moment(excluded).isSame(startDate, 'hour')).length > 0,
            );

            const className = classNames('ant-calendar-date', {
              'except-calendar-date-selected': !isDisabledDate && dateInRange,
              'except-calendar-date-partial-disabled':
                dateInRange &&
                isDisabledDate &&
                excludedTimes.length > 0 &&
                dateTimes.length !== excludedTimes.length,
              'except-calendar-date-disabled':
                isDisabledDate && (dateTimes.length === excludedTimes.length || !excludedTimes.length),
            });

            return (
              <TimeSelector
                dateTimes={dateTimes}
                excludedTimes={excludedTimes}
                handleExcludeTime={handleExcludeTime}
              >
                <div className={className}>{moment(current).format('D')}</div>
              </TimeSelector>
            );
          }}
          renderExtraFooter={() => (
            <>
              {isMobile && (
                <Styled.MobileClose type="button" onClick={toggleDatePicker}>
                  <CloseIcon />
                </Styled.MobileClose>
              )}
            </>
          )}
          locale={locale}
          format={`Исключено дат: ${input.value.length}`}
          disabled={disabled}
          onOpenChange={handleOnOpenChange}
          getCalendarContainer={isMobile ? () => pickerContainer : null}
        />
        <Error meta={meta} fixedError={fixedError} />
      </Styled.Container>
    </>
  );
};

export default withCheckIsMobile(ExceptDatepicker);
