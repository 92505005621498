import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import last from 'lodash/last';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { i18n } from 'next-i18next';
import { TranslatedInfo } from '../api/types';
import api from '../services/api';
import { getAdaptedFilterEvent } from './formatters/event';

const IDS_MAP: { [key: string]: string } = {
  source: 'prefix',
};

const getDataForMultiSelect = (data: any, idPath = 'id') =>
  reduce(
    data,
    (acc: any, curr) => {
      const { filter } = curr;
      if (filter.isEventFilter) {
        filter.value?.date_id?.split(',').forEach((date: string) => {
          const child = curr.children.find((i: any) => i.id === Number(date));

          if (child) {
            acc.push({
              value: child.id,
              label:
                curr.name ||
                curr.title ||
                curr.company_name ||
                curr.login ||
                curr?.info?.find((info: TranslatedInfo) => info.language_code === i18n?.language)?.title,
              data: {
                ...curr,
                date_start: child.date_start,
                date_finish: child.date_finish,
                time_start: child.time_start,
                time_finish: child.time_finish,
                parent: {
                  id: curr.id,
                },
              },
              isChildren: curr.is_periodical && !curr.children,
              isPreload: true,
            });
          }
        });
      }

      acc.push({
        value: curr[idPath],
        label:
          curr.name ||
          curr.title ||
          curr.company_name ||
          curr.login ||
          curr?.info?.find((info: TranslatedInfo) => info.language_code === i18n?.language)?.title,
        data: curr,
        isChildren: curr.is_periodical && !curr.children,
        isPreload: true,
      });

      return acc;
    },
    [],
  );

const loadFiltersData = (filtersData: any) =>
  new Promise((resolve) => {
    const promises = map(filtersData, (filter) => {
      if (filter.filter === 'phone' && filter.value) {
        return {
          filter,
          name: filter.value,
          id: filter.value,
        };
      }

      if ((filter.filter === 'sector__in' || filter.filter === 'category__in') && filter.value) {
        return filter.value.split(',').map((name: string) => ({
          id: name,
          name,
          filter,
        }));
      }

      if (filter.filter === 'site__in' && filter.value) {
        return filter.value.split(',').map((name: string) => ({
          name,
          id: name,
          value: name,
          filter,
        }));
      }

      if (filter.route && !filter.route[filter.detailRoute || 'detail']) {
        return null;
      }

      const loadDetail = (detailValue: any) => {
        const url = filter.route[filter.detailRoute || 'detail'](detailValue);

        return api
          .get(url, {
            params: { ...filter.detailQuery },
          })
          .then((response) => ({
            ...(filter.isEventFilter
              ? getAdaptedFilterEvent(response.data, filter.showOnlyActiveEvents)
              : response.data),
            filter,
          }))
          .catch(() => ({
            filter,
            value: undefined,
            label: undefined,
          }));
      };

      if (filter.value && filter.isAsync) {
        if (filter.isEventFilter) {
          return map(filter?.value[filter.filter]?.split(','), (multiValue) => loadDetail(multiValue));
        }

        if (filter.isMulti) {
          return map(filter.value.split(','), (multiValue) => loadDetail(multiValue));
        }

        return loadDetail(filter.value);
      }

      if (!filter.value && filter.isAsync) {
        return null;
      }

      return {
        filter,
      };
    });

    Promise.all(compact(flatten(promises)))
      .then((results) => {
        const initialValues = reduce(
          groupBy(results, 'filter.filter'),
          (acc: any, curr, index) => {
            const {
              id,
              name,
              title,
              filter: {
                isAsync,
                isMulti,
                value,
                isShortUnderline,
                isRangeDateTimePicker,
                isLandingsRangeDateTimePicker,
                options,
              },
            } = head(curr);

            if (isLandingsRangeDateTimePicker) {
              acc.date_gte = head(value);
              acc.date_lte = last(value);
            }

            if (isRangeDateTimePicker) {
              const underline = isShortUnderline ? '_' : '__';
              acc[`${index}${underline}gte`] = head(value);
              acc[`${index}${underline}lte`] = last(value);
            }

            if (!isAsync && !isMulti) {
              acc[index] = value;
            }

            if (!isAsync && !isMulti && options) {
              const match = options.find((option: any) => option.value === value || option.value === +value);
              acc[index] = match;
            }

            if (!isAsync && isMulti) {
              acc[index] =
                value &&
                map(value.split(','), (item) => {
                  const formattedValue = Number(item);

                  return {
                    value: Number.isNaN(formattedValue) ? item : formattedValue,
                  };
                });
            }

            if (isAsync && !isMulti) {
              acc[index] = {
                value: id,
                label:
                  name ||
                  title ||
                  head(curr)?.info?.find((info: TranslatedInfo) => info.language_code === i18n?.language)
                    ?.title,
              };
            }

            if (isAsync && isMulti) {
              const getIdPath = () => {
                return IDS_MAP[index] || 'id';
              };

              acc[index] = getDataForMultiSelect(curr, getIdPath());
            }

            return acc;
          },
          {},
        );

        resolve(initialValues);
      })
      .catch((err) => new Error(err));
  });

export default loadFiltersData;

export { getDataForMultiSelect };
