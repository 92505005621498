import arrayMutators from 'final-form-arrays';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { WysiwigEditorPreview } from 'entities/wysiwyg-editor';
import { TRIGGER_TYPES, TIMER_VALUES, REPEAT_VALUES, TRIGGERS } from 'shared/constants/LANDINGs_TRIGGERS';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { landingDescriptionValidation, required } from 'shared/helpers/validations';
import api from 'shared/services/api';
import landingTriggersSouces from 'shared/sources/landings/landingsTriggers';
import Button from '../../../Button';
import Form from '../../../Form';
import { ColorPicker, NumberInput, InputWithSelect, Textarea } from '../../../FormControls';
import Spinner from '../../../Spinner';
import ConstructorFooter from './ConstructorFooter';
import Styled from './styles';
import { isString } from 'lodash';

interface StepProps {
  updateData: any;
  goBack: () => void;
  onSubmit: (data: any) => Promise<any>;
  data: any;
  lng: string;
}

const NotificationsStep: React.FC<StepProps> = ({ updateData, data, onSubmit, goBack, lng }) => {
  const [state, setState] = useState({
    color: '',
    rounding_corners: 0,
    triggers: [],
    isLoading: true,
  });

  const loadData = (lang?) => {
    api
      .get(landingTriggersSouces.root(data.id || data.tech_name, lang))
      .then((response) => {
        const triggers = response.data;
        setState({
          isLoading: false,
          color: triggers.length ? triggers[0].color : null,
          rounding_corners: triggers.length ? triggers[0].rounding_corners : 0,
          triggers: triggers.map((item) => ({
            ...item,
            trigger_type: TRIGGER_TYPES.find((type) => type.value === item.trigger_type),
            value: TIMER_VALUES.find((type) => type.value === item.value),
            repeat: REPEAT_VALUES.find((type) => type.value === item.repeat),
          })),
        });
      })
      .catch((e) => new Error(e));
  };

  useEffect(() => {
    loadData(lng);
  }, [lng]);

  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };
  const handleSubmitData = async (submitData: any) => {
    const { triggers, color, rounding_corners: roundingCorners } = submitData;

    const submitTriggers = triggers.reduce(
      (acc, item) => {
        const submitItem = {
          ...item,
          trigger_type: item.trigger_type.value,
          value: item.value.value,
          repeat: item.repeat?.value,
          color,
          landing: data.tech_name,
          lang: lng,
          rounding_corners: roundingCorners,
          id: item.id || generateUniqueId(),
        };

        if (submitItem.id && !isString(submitItem.id)) {
          acc.update.push(submitItem);
        } else {
          acc.create.push(submitItem);
        }
        return acc;
      },
      {
        create: [],
        update: [],
      },
    );

    const promises = [
      ...submitTriggers.create.map((t) => api.post(landingTriggersSouces.list, t)),
      ...submitTriggers.update.map((t) => api.patch(landingTriggersSouces.detail(t.id), t)),
    ];

    await Promise.all(promises);

    if (submitData.submit_action === SUBMIT_ACTIONS.CREATE) {
      return onSubmit({ ...data, submit_action: SUBMIT_ACTIONS.CREATE }).then(loadData);
    }

    return onSubmit({ ...data, submit_action: SUBMIT_ACTIONS.CONTINUE });
  };

  return state.isLoading ? (
    <Spinner center />
  ) : (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Настройка оповещений</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Form
          onSubmit={handleSubmitData}
          initialValues={{
            ...state,
            ...data,
          }}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            form,
            form: {
              mutators: { push },
            },
            values: formValues,
          }) => {
            const handlePush = () => {
              const newId = generateUniqueId();
              push('triggers', { id: newId });
            };

            return (
              <form onSubmit={handleSubmit}>
                <Styled.FormRow withBorder>
                  <Styled.FormDescription>
                    Стиль оповещений
                    <Styled.FormSmallDescription>Цвет обводки, углы</Styled.FormSmallDescription>
                  </Styled.FormDescription>
                  <Styled.FormControls>
                    <Styled.FormControl grid>
                      <Styled.Column>
                        <Field name="color" validate={required}>
                          {({ input, meta }) => <ColorPicker label="Цвет" input={input} meta={meta} />}
                        </Field>
                      </Styled.Column>
                      <Styled.Column>
                        <Styled.SmallInput>
                          <Field name="rounding_corners" validate={required}>
                            {({ input, meta }) => (
                              <NumberInput
                                label="Скругление углов"
                                value={input.value}
                                max={16}
                                {...input}
                                meta={meta}
                              />
                            )}
                          </Field>
                        </Styled.SmallInput>
                      </Styled.Column>
                    </Styled.FormControl>
                  </Styled.FormControls>
                </Styled.FormRow>

                <FieldArray name="triggers">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const handleDeleteItem = async () => {
                        if (formValues.triggers[index]?.id) {
                          await api
                            .delete(landingTriggersSouces.detail(formValues.triggers[index].id))
                            .catch((e) => new Error(e));
                        }
                        fields.remove(index);
                      };
                      return (
                        <Styled.NotificationContainer>
                          {formValues.triggers[index] && (
                            <Styled.Notification
                              rounding={formValues.rounding_corners}
                              color={formValues.color}
                            >
                              <Styled.NotificationClose type="button">&times;</Styled.NotificationClose>

                              {formValues.triggers[index].text && (
                                // eslint-disable-next-line react/no-danger
                                <div dangerouslySetInnerHTML={{ __html: formValues.triggers[index].text }} />
                              )}
                            </Styled.Notification>
                          )}
                          <Field type="hidden" name="id" component="input" />
                          <Styled.FormRow>
                            <Styled.FormDescription>Текст оповещения</Styled.FormDescription>
                            <Styled.FormControls>
                              <Styled.FormControl>
                                <Field name={`${name}.text`} validate={landingDescriptionValidation}>
                                  {({ input, meta }) => (
                                    <WysiwigEditorPreview
                                      useData
                                      id={`notification_${formValues.triggers[index]?.id || ''}`}
                                      placeholder="Текст…"
                                      input={input}
                                      meta={meta}
                                      withPreview
                                    />
                                  )}
                                </Field>
                              </Styled.FormControl>
                            </Styled.FormControls>
                          </Styled.FormRow>
                          <Styled.FormRow>
                            <Styled.FormDescription>
                              Страница появления
                              <Styled.FormSmallDescription>Ссылка на страницу</Styled.FormSmallDescription>
                            </Styled.FormDescription>
                            <Styled.FormControls>
                              <Styled.FormControl>
                                <Field name={`${name}.link`} validate={required}>
                                  {({ input, meta }) => (
                                    <Textarea label="Ссылки типа /cart" {...input} meta={meta} />
                                  )}
                                </Field>
                              </Styled.FormControl>
                            </Styled.FormControls>
                          </Styled.FormRow>
                          <Styled.FormRow withBorder>
                            <Styled.FormDescription>
                              Триггер появления
                              <Styled.FormSmallDescription>
                                Действие, при котором появляется оповещение
                              </Styled.FormSmallDescription>
                            </Styled.FormDescription>
                            <Styled.FormControls>
                              <Styled.FormControl>
                                <Field name={`${name}.trigger_type`} validate={required}>
                                  {({ input, meta }) => (
                                    <InputWithSelect
                                      isClearable={false}
                                      label="Выберите вариант"
                                      options={TRIGGER_TYPES}
                                      meta={meta}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </Styled.FormControl>
                              {formValues?.triggers[index]?.trigger_type?.value === TRIGGERS.TIMER && (
                                <Styled.FormControl grid withOffset>
                                  <Styled.Column>
                                    <Field name={`${name}.value`} validate={required}>
                                      {({ input, meta }) => (
                                        <InputWithSelect
                                          isClearable={false}
                                          label="Выберите вариант"
                                          options={TIMER_VALUES}
                                          meta={meta}
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                  </Styled.Column>
                                  <Styled.Column>
                                    <Field name={`${name}.repeat`} validate={required}>
                                      {({ input, meta }) => (
                                        <InputWithSelect
                                          isClearable={false}
                                          label="Выберите вариант"
                                          options={REPEAT_VALUES}
                                          meta={meta}
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                  </Styled.Column>
                                </Styled.FormControl>
                              )}
                              <Styled.FormControl>
                                <Styled.AddNotification>
                                  <Button dangerTransparent onClick={handleDeleteItem} type="button">
                                    Удалить оповещение
                                  </Button>
                                </Styled.AddNotification>
                              </Styled.FormControl>
                            </Styled.FormControls>
                          </Styled.FormRow>
                        </Styled.NotificationContainer>
                      );
                    })
                  }
                </FieldArray>
                <Styled.AddNotification>
                  <Button transparentBlue onClick={handlePush} type="button">
                    Настроить еще оповещение
                  </Button>
                </Styled.AddNotification>

                {/*
                // @ts-ignore */}
                <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
              </form>
            );
          }}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default NotificationsStep;
